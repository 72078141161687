import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { useRef } from "react";
import CategoryHelper from "../../helper/CategoryHelper";

function CategoryList({ categories, visible, setVisible }) {
  const dt = useRef();
  return (
    <Dialog visible={visible} onHide={() => setVisible(false)}
    style={{width:'500px'}}
    >
      <DataTable
        ref={dt}
        value={categories}
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 20, 25, 50]}
        emptyMessage='Kategori bulunamadı.'>
        <Column field='id' header='Kategori Kodu' sortable></Column>
        <Column
          body={CategoryHelper.categoryNameTemplate}
          header='Kategori Adı'
          sortable></Column>
      </DataTable>
    </Dialog>
  );
}

export default CategoryList;
