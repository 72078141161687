import { InputText } from 'primereact/inputtext';
import { TabPanel, TabView } from 'primereact/tabview';
import { Button } from "primereact/button";
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onChangeSelectedClientLocalization, setClientLocalizations, setDiffClientLocalizations, setSelectedClientLocalization } from '../storage/localization/localizationSlice';
import { UpdateClientLocalization } from '../service/inventoryService';
import NotificationHelper from '../helper/NotificationHelper';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import GemGastroSpinner from '../components/Core/GemGastroSpinner';
import { Dialog } from 'primereact/dialog';
function ClientLocalizationManager() {
    const clientLocalizations = useSelector(x => x.localization.clientLocalizations);
    const selectedClientLocalization = useSelector(x => x.localization.selectedClientLocalization);
    const loading = useSelector(x => x.localization.loading);
    const localizations = useSelector(x => x.localization.localizations);
    const [componentLoading, setComponentLoading] = useState(false);
    const [tabActiveIndex, setTabActiveIndex] = useState(0)
    const [visible, setVisible] = useState(false);
    const dt = useRef();
    const dispatch = useDispatch();
    const onChange = (value) => {
        const model = selectedClientLocalization;
        model.value = value;
        setSelectedClientLocalization(model);
    }

    const updateClientLocalizations = () => {
        setComponentLoading(true);
        UpdateClientLocalization(selectedClientLocalization).then((response) => {
            if (response?.["is-success"]) {
                dispatch(setClientLocalizations(response.data));
                NotificationHelper.Success("İşlem Başarılı.");
                setVisible(false);
            } else {
                NotificationHelper.Error(response.userMessage);
            }
        }).catch(()=>{
            NotificationHelper.Error("Bir hata ile karşılaşıldı.")
        }).finally(()=>{
            setComponentLoading(false);
        })
    }

    return (
        loading || componentLoading ? <GemGastroSpinner isLogin={false} /> :
            <div className='w-50 m-auto'>
                <TabView activeIndex={tabActiveIndex}
                    onTabChange={(e) => setTabActiveIndex(e.index)}>
                    {
                        localizations.map((localization) => {
                            return <TabPanel header={localization.language}>
                                <DataTable
                                    ref={dt}
                                    value={
                                        clientLocalizations[localization.language]
                                    }
                                    dataKey='id'
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    scrollable
                                    header={"Web Sitesi Dil Ayarları"}
                                    loading={loading}
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                >
                                    <Column
                                        field='key'
                                        header='Parametre Adı'
                                        filter
                                        sortable
                                        filterPlaceholder='Ara'></Column>
                                    <Column
                                        header='Değeri'
                                        field="value"
                                        sortable
                                        filter
                                        filterPlaceholder='Ara'></Column>
                                    <Column body={(rowData) => <div className="w-100 d-flex justify-content-around align-items-center">
                                        <Button
                                            icon='pi pi-pencil'
                                            className='p-button-rounded p-button-success p-m-0 p-mr-1'
                                            onClick={() => { setVisible(true); dispatch(setSelectedClientLocalization(rowData)) }}
                                        />
                                    </div>}></Column>
                                </DataTable>
                            </TabPanel>
                        })
                    }
                </TabView >
                <Dialog
                    header={"Dil Değerini Düzenle"}
                    visible={visible}
                    onHide={() => setVisible(false)}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "50vw" }}
                >
                    <div className='p-field'>
                        <label>Parametre Adı : {selectedClientLocalization.key}</label>
                        <InputText
                            name={selectedClientLocalization.key}
                            value={selectedClientLocalization.value}
                            onChange={(e) => { dispatch(onChangeSelectedClientLocalization(e.target.value)) }
                            }
                        />
                    </div>
                    <button className="btn btn-success" onClick={() => updateClientLocalizations()}> Kaydet</button>
                </Dialog>
            </div>
    )
}

export default ClientLocalizationManager