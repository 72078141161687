import RequestHelper from "../helper/RequestHelper";

export async function DeleteCargos(data) {
  const response = await RequestHelper.post(data, "cargo", "DeleteCargos");
  const r = response.data;
  return r;
}
export async function CreateCargo(data) {
  const response = await RequestHelper.post(data, "cargo", "CreateCargo");
  const r = response.data;
  return r;
}
export async function UpdateCargo(data) {
  const response = await RequestHelper.post(data, "cargo", "UpdateCargo");
  const r = response.data;
  return r;
}
