import React, { Component } from "react";
import { connect } from "react-redux";
import OrderHelper from "../../helper/OrderHelper";
import ProductHelper from "../../helper/ProductHelper";
import GetAllOrdersButton from "./GetAllOrdersButton";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/components/column/Column";
import UpdateOrder from "./UpdateOrder";
import { setLoading, setSelectedOrder } from "../../storage/order/orderSlice";

export class OrderGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalFilter: null,
      gridData: [],
    };
  }
  componentDidMount() {
    this.setState({ gridData: this.props.orders });
  }

  priceBodyTemplate = (rowData) => {
    return (
      <span className='d-flex ml-3'>
        {ProductHelper.formatCurrency(rowData.totalPrice)}
      </span>
    );
  };
  statusBodyTemplate = (rowData) => {
    const foundedStatus = OrderHelper.allStatusOptions.find(
      (element) => rowData.status.toString() == element.value
    );
    return (
      <span className={`order-badge order`}>{foundedStatus?.text || ""}</span>
    );
  };
  openUpdateOrderDialog = (order) => {
    this.props.setSelectedOrder(order);
    this.props.setUpdateOrderVisible(true);
  };
  actionBodyTemplate = (rowData) => {
    return (
      <div className="w-100 d-flex justify-content-around align-items-center">
        <Button
          icon='pi pi-pencil'
          className='p-button-rounded p-button-success p-m-0 p-mr-1'
          onClick={() => this.openUpdateOrderDialog(rowData)}
        />
        <Button
          icon='pi pi-info'
          className='p-button-rounded p-button-warning'
          onClick={() => this.orderLogOpen(rowData)}
        />
        <Button
          icon='pi pi-credit-card'
          className='p-button-rounded p-button-info'
          onClick={() => this.orderPaymentLogOpen(rowData)}
        />
      </div>
    );
  };
  orderLogOpen = () => {
    this.props.setOrderHistoryLogVisible(true);
  };
  orderPaymentLogOpen = () => {
    this.props.setOrderPaymentHistoryLogVisible(true);
  };
  
  header = (
    <div className='table-header p-d-flex'>
      <h5 className='p-m-0'>Sipariş Listesi</h5>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onInput={(e) => this.setState({ globalFilter: e.target.value })}
          placeholder='Ara'
        />
        <GetAllOrdersButton
          setComponentLoading={(p) => this.props.setLoading(p)}
        />
      </span>
    </div>
  );
  onDateChange = (e) => {
    const gridData = this.props.orders.filter((x) => x.createdDate == e.value);
    this.setState({ gridData }, () => {
      this.setState({ selectedDate: e.value });
    });
  };
  onStatusChange = (e) => {
    if (e.value == undefined) {
      this.setState({ gridData: this.props.orders });
      return false;
    }
    const gridData = this.props.orders.filter((x) => x.status == e.value);

    this.setState({ gridData }, () => {
      this.setState({ selectedStatus: e.value });
    });
  };
  dateFilter = () => {
    const { selectedDate } = this.state;
    return (
      <Calendar
        value={selectedDate}
        onChange={this.onDateChange}
        dateFormat='yy-mm-dd'
        className='p-column-filter'
        placeholder='Ara'
      />
    );
  };
  statusFilter = () => {
    const { selectedStatus } = this.state;
    <Dropdown
      value={selectedStatus}
      options={OrderHelper.allStatusOptions}
      optionLabel='text'
      optionValue='value'
      onChange={this.onStatusChange}
      placeholder='Seçiniz'
      className='p-column-filter'
      showClear
    />;
  };
  render() {
    const { filterFunction, selectedOrder, setSelectedOrder } = this.props;
    const { gridData, selectedStatus, globalFilter } = this.state;
    return (
      <>
        <DataTable
          // ref={dt}
          value={
            filterFunction
              ? this.props.orders.filter((x) => filterFunction(x))
              : this.props.orders
          }
          selection={selectedOrder}
          onSelectionChange={(e) => setSelectedOrder(e.value)}
          dataKey='id'
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
          currentPageReportTemplate='Showing {first} to {last} of {totalRecords} orders'
          emptyMessage='Sipariş Bulunamadı.'
          globalFilter={globalFilter}
          sortMode='multiple'
          scrollable
          header={this.header}
          loading={this.props.loading}>
          <Column
            selectionMode='single'
            headerStyle={{ width: "3rem" }}></Column>
          <Column
            field='orderCode'
            header='Sipariş Kodu'
            sortable
            filterPlaceholder='Ara'></Column>
          <Column
            header='Müşteri Adı'
            sortable
            body={(rowData) => {
              const customer = this.props.customers.find(
                (x) => x.id == rowData.customerId
              );
              return <span>{customer?.name || ""}</span>;
            }}
            filterPlaceholder='Ara'></Column>
          <Column
            header='Müşteri Soyadı'
            sortable
            body={(rowData) => {
              const customer = this.props.customers.find(
                (x) => x.id == rowData.customerId
              );
              return <span>{customer?.surname || ""}</span>;
            }}
            filterPlaceholder='Ara'></Column>
          <Column
            field='paymentType'
            header='Ödeme Yöntemi'
            sortable
            filterPlaceholder='Ara'></Column>
          <Column
            field='paymentStatus'
            header='Ödeme Yapıldı'
            body={(rowData) => (
              <input type='checkbox' value={rowData.paymentStatus} checked={rowData.paymentStatus} disabled />
            )}></Column>
          <Column
            field='totalPrice'
            header='Sipariş Tutarı'
            body={this.priceBodyTemplate}
            sortable></Column>
          <Column
            field='status'
            header='Sipariş Durumu'
            body={this.statusBodyTemplate}
            sortable></Column>
          <Column
            field='createdDate'
            header='Sipariş Tarihi'
            sortable
            body={OrderHelper.formatDateTime}
            filterElement={this.dateFilter}
            filterFunction={OrderHelper.filterDate}></Column>
          <Column body={this.actionBodyTemplate}></Column>
        </DataTable>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: state.order.orders,
  customers: state.customer.customers,
  inventoryLoading: state.inventory.loading,
  loading: state.order.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedOrder: (order) => dispatch(setSelectedOrder(order)),
    setLoading: (val) => dispatch(setLoading(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderGrid);
