import React, { Component } from "react";
import { connect } from "react-redux";
import OrderGrid from "../components/Order/OrderGrid";
import OrderHistoryLog from "../components/Order/OrderHistoryLog";
import UpdateOrder from "../components/Order/UpdateOrder";
import OrderHelper from "../helper/OrderHelper";
export class Return extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStatus: null,
      selectedDate: null,
      updateOrderVisible: false,
      orderHistoryLogVisible: false,
    };
  }

  render() {
    return (
      <div>
        <OrderGrid
          filterFunction={(x) =>
            x.status == 10 || x.status == 11 || x.status == 14 || x.status == 15
          }
          setUpdateOrderVisible={(p) =>
            this.setState({ updateOrderVisible: p })
          }
          setOrderHistoryLogVisible={(p) =>
            this.setState({ orderHistoryLogVisible: p })
          }
        />
        {this.state.updateOrderVisible && (
          <UpdateOrder
            visible={this.state.updateOrderVisible}
            setVisible={(p) => this.setState({ updateOrderVisible: p })}
            statusOptions={OrderHelper.filterStatus(
              [8, 12, 10, 11, 13, 9, 2, 4, 5, 7, 14]
              // Object.keys(this.props.canbeChangeOrderStatus).map(
              //   (x) => this.props.canbeChangeOrderStatus[x]
              // )
            )}
          />
        )}
        {this.state.orderHistoryLogVisible && (
          <OrderHistoryLog
            visible={this.state.orderHistoryLogVisible}
            setVisible={(p) => this.setState({ orderHistoryLogVisible: p })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  canbeChangeOrderStatus: state.inventory.canbeChangeOrderStatus,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Return);
