import { createSlice } from "@reduxjs/toolkit";

export const cargoSlice = createSlice({
  name: "cargo",
  initialState: {
    cargos: [],
    selectedCargo: {},
    loading: false,
  },
  reducers: {
    setCargos: (state, action) => {
      state.cargos = action.payload;
    },
    setSelectedCargo: (state, action) => {
      state.selectedCargo = action.payload;
    },
    onChangeSelectedCargo: (state, action) => {
      const changedValue = action.payload;
      let model = state.selectedCargo;
      if (!model) model = {};
      model[changedValue.name] = changedValue.value;
      state.selectedCargo = model;
    },
    deleteCargos: (state, action) => {
      const CargoIds = action.payload;
      let model = state.cargos;
      state.cargos = model.filter((x) => !CargoIds.includes(x.id));
    },

    updateCargo: (state, action) => {
      const updatedCargo = state.selectedCargo;
      state.cargos = state.cargos.map((i) => {
        if (i.id == updatedCargo.id) return updatedCargo;
        return i;
      });
    },
    createCargo: (state, action) => {
      const createdCargo = state.selectedCargo;
      let models = state.cargos;

      models.unshift(createdCargo);
      state.cargos = models;
    },
    setLoading:(state,action) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCargos,
  setSelectedCargo,
  createCargo,
  updateCargo,
  deleteCargos,
  onChangeSelectedCargo,
  setCargoDetail,
  setLoading
} = cargoSlice.actions;

export default cargoSlice.reducer;
