import RequestHelper from "../helper/RequestHelper";

export async function DeleteCategories(data) {
  const response = await RequestHelper.post(data, "category", "DeleteCategories");
  const r = response.data;
  return r;
}
export async function CreateCategory(data) {
  const response = await RequestHelper.post(data, "category", "CreateCategory");
  const r = response.data;
  return r;
}
export async function UpdateCategory(data) {
  const response = await RequestHelper.post(data, "category", "UpdateCategory");
  const r = response.data;
  return r;
}
