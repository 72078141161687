import RequestHelper from "../helper/RequestHelper";

export async function GetSecurityCode(data) {
  const response = await RequestHelper.post(data, "User", "GetSecurityCode");
  const r = response.data;
  return r;
}
export async function SignIn(data) {
    const response = await RequestHelper.post(data, "User", "SignIn");
    const r = response.data;
    return r;
  }
  export async function CheckToken(data) {
    const response = await RequestHelper.post(data, "User", "CheckToken");
    const r = response.data;
    return r;
  }