import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onChangeSelectedCategoryLocalization } from "../../storage/category/categorySlice";

function CreateOrUpdateCategoryLocalization({ visible, setVisible }) {
  const selectedCategory = useSelector((state) => state.category.selectedCategory);
  const localizations = useSelector(
    (state) => state.localization.localizations
  );
  const dispatch = useDispatch();
  const onInputChange = (name, value, localizationId) => {
    dispatch(
      onChangeSelectedCategoryLocalization({ name, value, localizationId })
    );
  };
 
  return (
    <Dialog
      visible={visible}
      style={{ width: "450px" }}
      header={`${
        selectedCategory?.categoryLocalizations !== null
          ? `Kategori Bilgileri Güncelleme`
          : `Kategori Bilgileri Oluşturma`
      }`}
      modal
      onHide={() => setVisible(false)}>
      {localizations?.map((localization, i) => {
        const categoryLocalization = selectedCategory?.categoryLocalizations?.find(
          (x) => x.localizationId == localization.id
        );
        return (
          <>
            <div className='p-field'>
              <span>
                {i + 1}.Dil - {localization.language} -
              </span>
            </div>
            <Divider />
            <div
              className='p-field w-100'
              style={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor='categoryName'>Adı</label>
              <InputText
                name='categoryName'
                value={categoryLocalization?.categoryName}
                onChange={(e) =>
                  onInputChange(e.target.name, e.target.value, localization.id)
                }
                required
                autoFocus
              />
            </div>
            <div
              className='p-field'
              style={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor='description'>Açıklama</label>
              <InputText
                name='description'
                value={categoryLocalization?.description}
                onChange={(e) =>
                  onInputChange(e.target.name, e.target.value, localization.id)
                }
                required
                autoFocus
              />
            </div>
          </>
        );
      })}
    </Dialog>
  );
}

export default CreateOrUpdateCategoryLocalization;
