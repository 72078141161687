import React, { Component } from "react";
import { connect } from "react-redux";
import GemGastroSpinner from "../Core/GemGastroSpinner";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Skeleton } from "primereact/skeleton";
import { MultiSelect } from "primereact/multiselect";

import ProductHelper from "../../helper/ProductHelper";
import classNames from "classnames";
import {
  createProduct,
  onChangeSelectedProduct,
  setProductDetail,
  setSelectedProduct,
  updateProduct,
} from "../../storage/product/productSlice";
import Confirm from "../Core/Confirm";
import CreateOrUpdateProductLocalization from "./CreateOrUpdateProductLocalization";
import Image from "../Image";
import { CreateProduct, UpdateProduct } from "../../service/productService";
import NotificationHelper from "../../helper/NotificationHelper";
import CreateOrUpdateProductTechnical from "./CreateOrUpdateProductTechnical";
//props
//editModel=
//setEditMod
//visible={t
//setVisible
//
export class CreateOrUpdateProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      componentLoading: false,
      openProductTechnicalDialog: false,
      openImageDialog: false,
      openDiscountDialog: false,
      openProductLocalizationDialog: false,
      lazyLoading: false,
      accessories: [],
    };
  }
  componentDidMount() {
    this.setState({
      accessories: ProductHelper.foundAccessoriesProductCode(
        this.props.selectedProduct?.subProduct || [],
        this.props.products || []
      ),
    });
  }

  saveProduct = () => {
    const updateProductOnClient = this.props.updateProduct;
    const createProductOnClient = this.props.createProduct;
    const product = this.props.selectedProduct;
    this.setState({ componentLoading: true }, () => {
      //update
      if (this.props.selectedProduct && this.props.selectedProduct?.id) {
        UpdateProduct(this.props.selectedProduct)
          .then((response) => {
            if (response?.["is-success"] && response.data) {
              updateProductOnClient(product);
              NotificationHelper.Success("Güncelleme işlemi başarılı");
            } else {
              NotificationHelper.Error(response?.userMessage);
            }
          })
          .catch((err) => {
            NotificationHelper.Error("Beklenmedik bir hata oluştu.");
          })
          .finally(() => this.setState({ componentLoading: false }));
      } else if (
        this.props.selectedProduct &&
        !this.props.selectedProduct?.id
      ) {
        CreateProduct(this.props.selectedProduct)
          .then((response) => {
            if (response?.["is-success"] && response.data) {
              createProductOnClient(response.data);
              NotificationHelper.Success("Ürün oluşturma işlemi başarılı");
            } else {
              NotificationHelper.Error(response?.userMessage);
            }
          })
          .catch((err) => {
            NotificationHelper.Error("Beklenmedik bir hata oluştu.");
          })
          .finally(() => this.setState({ componentLoading: false }));
      }
    });
  };

  reloadProductImage = () => {
    const id = this.props.selectedProduct.id;
  }

  productDialogFooter = (
    <React.Fragment>
      <Button
        label='İptal'
        icon='pi pi-times'
        className='p-button-text'
        onClick={() => this.props.setVisible(false)}
      />
      <Button
        label='Kaydet'
        icon='pi pi-check'
        className='p-button-text'
        onClick={this.saveProduct}
      />
    </React.Fragment>
  );

  onInputChange = (name, value) => {
    let model = {
      name,
      value,
    };
    this.props.onChangeSelectedProduct(model);
  };
  panelFooterTemplate = () => {
    const length = this.state.accessories?.length;
    return (
      <div className='p-py-2 p-px-3 ml-3'>
        <b className='mr-1'>{length}</b>tane Aksesuar seçildi.
      </div>
    );
  };
  deleteProduct = () => { };
  onLazyLoad(event) {
    this.setState({ lazyLoading: true });

    if (this.loadLazyTimeout) {
      clearTimeout(this.loadLazyTimeout);
    }

    //imitate delay of a backend call
    this.loadLazyTimeout = setTimeout(() => {
      const { first, last } = event;
      const lazyItems = [...this.state.lazyItems];

      for (let i = first; i < last; i++) {
        lazyItems[i] = { label: `Item #${i}`, value: i };
      }

      this.setState({
        lazyItems,
        lazyLoading: false,
      });
    }, Math.random() * 1000 + 250);
  }
  render() {
    const { componentLoading } = this.state;
    const { setVisible, visible } = this.props;
    return (
      <>
        <Dialog
          visible={visible}
          style={{ width: "450px" }}
          header={`${this.props.selectedProduct?.id
            ? `Ürün Güncelleme`
            : `Ürün Oluşturma`
            }`}
          modal
          className='p-fluid'
          footer={this.productDialogFooter}
          onHide={() => setVisible(false)}>
          {!componentLoading ? (
            <>
              {this.props.selectedProduct &&
                this.props.selectedProduct.productImage?.length ? (
                <div className='p-field d-flex justify-content-center'>
                  <img
                    src={ProductHelper.getProductImageUrlById(
                      this.props.selectedProduct.productImage || []
                    )}
                    className='product-image d-flex justify-content-center'
                    style={{ width: "100px" }}
                  />
                </div>
              ) : (
                ""
              )}
              <div className='p-field'>
                <label htmlFor='productCode'>Ürün Kodu</label>
                <InputText
                  name='productCode'
                  value={this.props.selectedProduct?.productCode}
                  onChange={(e) =>
                    this.onInputChange(e.target.name, e.target.value)
                  }
                  required
                  autoFocus
                />
              </div>
              <div className='p-field'>
                <Button
                  label={`${this.props.selectedProduct?.productLocalizations &&
                    this.props.selectedProduct.productLocalizations.length
                    ? "Ürün Bilgilerini Güncelleme"
                    : "Ürün Bilgilerini Gir"
                    }`}
                  icon='pi pi-external-link'
                  onClick={() =>
                    this.setState({ openProductLocalizationDialog: true })
                  }
                />
              </div>
              <div className='p-field'>
                <Button
                  label={`${this.props.selectedProduct?.productTechnicalInfos?.length
                    ? "Ürün Teknik Bilgisi Güncelleme"
                    : "Ürün Teknik Bilgisi Ekle"
                    }`}
                  icon='pi pi-external-link'
                  onClick={() =>
                    this.setState({ openProductTechnicalDialog: true })
                  }
                />
              </div>
              <div className='p-field'>
                <div className='p-formgrid'>
                  <div className='p-field'>
                    <label htmlFor='category1'>Kategoriler</label>
                    <Dropdown
                      value={
                        this.props.categories.find(
                          (x) => x.id == this.props.selectedProduct?.categoryId
                        )?.categoryLocalizations?.[0]
                      }
                      options={this.props.categories.map(
                        (category) => category.categoryLocalizations[0]
                      )}
                      optionLabel='categoryName'
                      name='categoryName'
                      filter
                      filterBy='categoryName'
                      onChange={(e) =>
                        this.onInputChange(
                          "categoryId",
                          e.target.value.categoryId
                        )
                      }
                    />
                  </div>
                  {this.props.selectedProduct?.id && (
                    <div className='p-field'>
                      <label htmlFor='category1'>Aksesuarlar</label>
                      <MultiSelect
                        // optionLabel='productCode'
                        value={this.state.accessories}
                        options={this.props.products.map(
                          (product) => product.productCode
                        )}
                        onChange={(e) => {
                          const selectedAccessories = e.value;
                          this.setState(
                            { accessories: selectedAccessories },
                            () => {
                              this.onInputChange(
                                "subProduct",
                                ProductHelper.prepareAccessories(
                                  this.state.accessories,
                                  this.props.products || [],
                                  this.props.selectedProduct?.id
                                )
                              );
                            }
                          );
                        }}
                        filter
                        className='multiselect-custom'
                        panelFooterTemplate={this.panelFooterTemplate}
                        placeholder='Aksesuar seçiniz'
                      />
                    </div>
                  )}
                  <div className='p-field'>
                    <label htmlFor='category1'>Marka</label>
                    <Dropdown
                      value={this.props.suppliers.find(
                        (x) => x.id == this.props.selectedProduct?.supplierId
                      )}
                      options={this.props.suppliers}
                      optionLabel='name'
                      filter
                      filterBy='name'
                      name='supplierId'
                      onChange={(e) =>
                        this.onInputChange("supplierId", e.target.value.id)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className='p-formgrid'>
                <div className='p-field'>
                  <label htmlFor='quantity'>Stok Miktarı</label>
                  <InputNumber
                    id='quantity'
                    value={this.props.selectedProduct?.unitStock}
                    onValueChange={(e) =>
                      this.onInputChange("unitStock", e.target.value)
                    }
                    integeronly
                  />
                </div>
                <div
                  className='p-field'
                  style={{ display: "flex", flexDirection: "column" }}>
                  <label htmlFor='unitPrice'>Fiyat</label>
                  <InputNumber
                    id='unitPriceGe'
                    name='unitPrice'
                    value={this.props.selectedProduct?.unitPrice}

                    onValueChange={(e) =>
                      this.onInputChange("unitPrice", e.target.value)
                    }
                    mode='currency'
                    currency='EUR'
                    locale='de-DE'
                  />
                </div>
                <div
                  className='p-field'
                  style={{ display: "flex", flexDirection: "column" }}>
                  <label htmlFor='unitPrice'>İndirimli Fiyat</label>
                  <InputNumber
                    id='unitPriceGe'
                    name='promoUnitPrice'
                    value={this.props.selectedProduct?.promoUnitPrice}
                    // onChange={(e) =>
                    //   onInputChange(e.target.name, e.target.value, localization.id)
                    // }
                    onValueChange={(e) =>
                      this.onInputChange(
                        "promoUnitPrice",
                        e.target.value
                      )
                    }
                    mode='currency'
                    currency='EUR'
                    locale='de-DE'
                  />
                </div>
                <div
                  className='p-field'
                  style={{ display: "flex", flexDirection: "column" }}>
                  <label htmlFor='unitPrice'>Kargo Fiyatı</label>
                  <InputNumber
                    id='unitPriceGe'
                    name='promoUnitPrice'
                    value={this.props.selectedProduct?.cargoPrice}
                    // onChange={(e) =>
                    //   onInputChange(e.target.name, e.target.value, localization.id)
                    // }
                    onValueChange={(e) =>
                      this.onInputChange(
                        "cargoPrice",
                        e.target.value
                      )
                    }
                    mode='currency'
                    currency='EUR'
                    locale='de-DE'
                  />
                </div>
                {this.props.selectedProduct?.id && (
                  <div className='p-field p-col my-2'>
                    <Button
                      label='Ürün Fotoğrafı Ekle'
                      icon='pi pi-upload'
                      onClick={() => this.setState({ openImageDialog: true })}
                    />
                  </div>
                )}
              </div>

              {this.state.openProductLocalizationDialog && (
                <CreateOrUpdateProductLocalization
                  visible={this.state.openProductLocalizationDialog}
                  setVisible={(p) =>
                    this.setState({ openProductLocalizationDialog: p })
                  }
                />
              )}
              {this.state.openProductTechnicalDialog && (
                <CreateOrUpdateProductTechnical
                  visible={this.state.openProductTechnicalDialog}
                  setVisible={(p) =>
                    this.setState({ openProductTechnicalDialog: p })
                  }
                />
              )}
              {this.state.openImageDialog && this.props.selectedProduct?.id && (
                <Image
                  visible={this.state.openImageDialog}
                  setVisible={(p) => this.setState({ openImageDialog: p })}
                  entityId={this.props.selectedProduct.id}
                  fieldName={"ProductId"}
                />
              )}
            </>
          ) : (
            <GemGastroSpinner />
          )}
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.product.products,
  categories: state.category.categories,
  suppliers: state.supplier.suppliers,
  selectedProduct: state.product.selectedProduct,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedProduct: (product) => dispatch(setSelectedProduct(product)),
    setProductDetail: (lookup) => dispatch(setProductDetail(lookup)),
    onChangeSelectedProduct: (changedValue) =>
      dispatch(onChangeSelectedProduct(changedValue)),
    updateProduct: (product) => dispatch(updateProduct(product)),
    createProduct: (product) => dispatch(createProduct(product)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOrUpdateProduct);
