import React, { useRef, useState, useEffect } from "react";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { GetAboutUsImages } from "../service/inventoryService";
import NotificationHelper from "../helper/NotificationHelper";
import { CreateAboutUsImage, DeleteImage } from "../service/imageService";

function AboutUsImagesSettings() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    getImages();
  }, []);

  const getImages = () => {
    GetAboutUsImages()
      .then((response) => {
        if (response["is-success"]) {
          setImages(response.data);
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi"
          );
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir hata meydana geldi");
      });
  };

  //image useState
  const [files, setFiles] = useState([]);
  const [deleteImageDialog, setDeleteImageDialog] = useState(false);
  const [imageCreateDialog, setImageCreateDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [componentLoading, setComponentLoading] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const onUpload = (e) => {
    setFiles(e.files);
    NotificationHelper.Success("Dosyalar alındı lütfen kaydetmeyi unutmayın");
  };

  const onRemove = (e) => {
    setFiles(files.filter((x) => x != e.file));
  };
  const onClear = () => {
    setFiles([]);
  };
  const onUploadUpdateSave = async () => {
    setComponentLoading(true);
    const newImage = files;
    const data = new FormData();
    newImage.map((img, i) => {
      data.append("data.Files", img);
    });

    CreateAboutUsImage(data)
      .then((response) => {
        if (response["is-success"] && response.data) {
          NotificationHelper.Success("Yeni fotoğraf eklendi");
          getImages();
          setImageCreateDialog(false);
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi"
          );
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir sorun oldu fotoğraf yüklenemedi");
        setComponentLoading(false);
      });
  };
  const confirmImageSlider = (rowData) => {
    setFiles(rowData);
    setDeleteImageDialog(true);
  };
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label='Yeni Fotoğraf Ekle'
          icon='pi pi-plus'
          className='p-button-success p-mr-2'
          onClick={() => setImageCreateDialog(true)}
        />{" "}
        <Button
          label='Yenile'
          icon='pi pi-refresh'
          className='p-button mx-3'
          onClick={() => getImages()}
        />
      </React.Fragment>
    );
  };
  const hideImageDeleteDialog = () => {
    setDeleteImageDialog(false);
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon='pi pi-trash'
          className='p-button-rounded p-button-warning'
          onClick={() => confirmImageSlider(rowData)}
        />
      </React.Fragment>
    );
  };
  const deleteImageSave = async () => {
    setComponentLoading(true);

    var arr = [];
    if (selectedImage?.id > 0) {
      arr.push(selectedImage?.id);
      DeleteImage(arr)
        .then((response) => {
          if (response["is-success"]) {
            NotificationHelper.Success("Fotoğraf başarıyla silindi");
            getImages();
            setComponentLoading(false);
          } else {
            NotificationHelper.Error(
              response.userMessage || "Bir hata meydana geldi"
            );
          }
        })
        .catch(() => {
          NotificationHelper.Error("Bir hata meydana geldi");
          setComponentLoading(false);
        });

      setDeleteImageDialog(false);
    } else {
      NotificationHelper.Error("Lütfen önce fotoğraf seçiniz.");
    }
  };
  const deleteImageDialogFooter = () => {
    return (
      <React.Fragment>
        <Button
          label='Sil'
          icon='pi pi-trash'
          className='p-button-rounded p-button-danger'
          onClick={() => deleteImageSave()}
        />
      </React.Fragment>
    );
  };
  const header = (
    <div className='table-header'>
      <h5 className='p-m-0'>Hakkımızda(Uber Uns)'ta Gözükecek Fotoğraflar</h5>
    </div>
  );
  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={`${process.env.REACT_APP_API_IMG}/${rowData.url}`}
        className='product-image'
        style={{ width: "110px" }}
      />
    );
  };
  const createImageDialogFooter = (
    <React.Fragment>
      <Button
        label='Kaydet'
        icon='pi pi-check'
        className='p-button-text'
        onClick={() => onUploadUpdateSave()}
      />
    </React.Fragment>
  );
  return (
    <div>
      <Toast ref={toast}></Toast>
      <Toolbar className='p-mb-4' left={leftToolbarTemplate}></Toolbar>
      <DataTable
        ref={dt}
        value={images}
        selection={selectedImage}
        onSelectionChange={(e) => setSelectedImage(e.value)}
        dataKey='id'
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
        currentPageReportTemplate='Showing {first} to {last} of {totalRecords} products'
        header={header}>
        <Column selectionMode='single' headerStyle={{ width: "3rem" }}></Column>
        <Column field='id' header='Resim No' sortable></Column>
        <Column field='url' header='Resim' body={imageBodyTemplate}></Column>
        <Column body={actionBodyTemplate}></Column>
      </DataTable>
      <Dialog
        visible={deleteImageDialog}
        style={{ width: "450px" }}
        header='Confirm'
        modal
        footer={deleteImageDialogFooter}
        onHide={hideImageDeleteDialog}>
        <div className='confirmation-content'>
          <i
            className='pi pi-exclamation-triangle p-mr-3'
            style={{ fontSize: "2rem" }}
            onClick={() => setDeleteImageDialog(false)}
          />
          <span>Fotoğraf silinecek emin misiniz ?</span>
        </div>
      </Dialog>
      <Dialog
        header={"Uber Uns Fotoğrafı Ekle"}
        visible={imageCreateDialog}
        onHide={() => setImageCreateDialog(false)}
        breakpoints={{ "960px": "75vw" }}
        footer={createImageDialogFooter}
        style={{ width: "50vw" }}>
        <FileUpload
          name='productImages'
          url='./upload.php'
          customUpload
          onRemove={(e) => onRemove(e)}
          onClear={() => onClear()}
          uploadHandler={(e) => onUpload(e)}
          accept='image/*'
          emptyTemplate={<p className='p-m-0'>Fotoğrafı sürükle bırak</p>}
        />
      </Dialog>
    </div>
  );
}

export default AboutUsImagesSettings;
