import React, { Component } from "react";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Ripple } from "primereact/ripple";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import CustomerHelper from "../helper/CustomerHelper";
import ProductHelper from "../helper/ProductHelper";
// import { GetCustomerDetail } from "../service/customerService";
import Customer from "../components/Customer";
import { Toolbar } from "primereact/toolbar";
import { Divider } from "primereact/divider";

import {
  onChangeSelectedCustomers,
  setCustomerDetail,
  setCustomers,
  setLoading,
  setSelectedCustomer,
  setSelectedCustomers,
} from "../storage/customer/customerSlice";
import Confirm from "../components/Core/Confirm";
import { AdminGetAllCustomers } from "../service/inventoryService";
import NotificationHelper from "../helper/NotificationHelper";
import GetAllCustomers from "../components/Customer/GetAllCustomers";
import { Dialog } from "primereact/dialog";
import OrderHelper from "../helper/OrderHelper";
export class CustomerManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCustomers: null,
      first1: 0,
      globalFilter: null,
      currentPage: 1,
      pageInputTooltip: "Press 'Enter' key to go to this page.",
      rows: 10,
      dt: React.createRef(),
      editModel: null,
      openConfirmDialog: false,
      confirmDialogMessage:
        "Seçili müşterileri silmek istediğinize emin misiniz?",
      selectedTransferCustomer: null,
      openCustomerDetail: false,
    };
  }
  componentDidMount() {
    if (!this.props.customers?.length) {
      setLoading(true);
      AdminGetAllCustomers()
        .then((response) => {
          if (response["is-success"]) {
            setCustomers(response.data);
            this.setState({ pureData: response.data, data: response.data });
          } else {
            NotificationHelper.Error(
              response.userMessage || "Bir hata meydana geldi."
            );
          }
        })
        .finally(() => setLoading(false));
    } else {
      this.setState({ pureData: this.props.customers, data: this.props.customers });
    }
  }
  openDetailCustomer = (customer) => {
    this.setState({ selectedCustomers: customer }, () => {
      this.setState({ openCustomerDetail: true });
    });
  };

  actionBodyTemplate = (customer) => {
    return (
      <React.Fragment>
        <Button
          icon='pi pi-info'
          className='p-button-rounded p-button-info p-mr-2 mr-4'
          onClick={() => this.openDetailCustomer(customer)}
        />
      </React.Fragment>
    );
  };
  onPageInputKeyDown = (event, options) => {
    if (event.key === "Enter") {
      const page = parseInt(this.state.currentPage);
      if (page < 0 || page > options.totalPages) {
        this.setState({
          pageInputTooltip: `Value must be between 1 and ${options.totalPages}.`,
        });
      } else {
        const first = this.state.currentPage ? options.rows * (page - 1) : 0;

        this.setState({ first1: first });

        this.setState({
          pageInputTooltip: "Press 'Enter' key to go to this page.",
        });
      }
    }
  };
  paginatorTemplate = {
    layout:
      "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport",
    PrevPageLink: (options) => {
      return (
        <button
          type='button'
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}>
          <span className='p-p-3'>Önceki</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type='button'
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}>
          <span className='p-p-3'>Sonraki</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { "p-disabled": true });

        return (
          <span className={className} style={{ userSelect: "none" }}>
            ...
          </span>
        );
      }

      return (
        <button
          type='button'
          className={options.className}
          onClick={options.onClick}>
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 30, value: 30 },
        { label: 40, value: 40 },
        { label: 50, value: 50 },
      ];

      return (
        <Dropdown
          value={options.value}
          options={dropdownOptions}
          onChange={options.onChange}
          appendTo={document.body}
        />
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          className='p-mx-3'
          style={{ color: "var(--text-color)", userSelect: "none" }}>
          Go to{" "}
          <InputText
            size='2'
            className='p-ml-1'
            value={this.state.currentPage}
            tooltip={this.state.pageInputTooltip}
            onKeyDown={(e) => this.onPageInputKeyDown(e, options)}
            onChange={this.onPageInputChange}
          />
        </span>
      );
    },
  };
  onCustomPage1 = (event) => {
    this.setState({ first1: event.first });
    this.setState({ rows: event.rows });
    this.setState({ currentPage: event.page + 1 });
  };
  gridHeader = (
    <div className='table-header'>
      <h5 className='p-m-1'>Müşteri Adı</h5>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onChange={(e) => {
            if (this.state.pureData?.length) {
              let newData = this.state.pureData.filter(x => x.name.toLowerCase().includes(e.target.value.toLowerCase()) || x.surname.toLowerCase().includes(e.target.value.toLowerCase()))
              this.setState({ data: newData });
            }
          }}
          placeholder='Search...'
        />
      </span>
    </div>
  );

  leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className='container'>
          <div className='row'>
            <div className='col-12 d-flex '>
              <div>
                {/* <Button
                  label='Müşteri Ekle'
                  icon='pi pi-plus'
                  className='p-button-success p-mr-2'
                  onClick={() => {
                    this.props.setSelectedCustomer({});
                  }}
                /> */}
                <GetAllCustomers
                  setComponentLoading={(p) => this.props.setLoading(p)}
                  setPureData={(p) => this.setState({ pureData: p, data: p })}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className='datatable-crud-demo'>
        <Toolbar
          className='p-mb-4 w-100'
          left={this.leftToolbarTemplate}></Toolbar>
        <DataTable
          ref={this.state.dt}
          value={this.state.data}
          dataKey='id'
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 20, 25, 50]}
          paginatorTemplate={this.paginatorTemplate}
          first={this.state.first1}
          onPage={this.onCustomPage1}
          globalFilter={this.state.globalFilter}
          emptyMessage='Müşteri bulunamadı.'
          header={this.gridHeader}
          loading={this.props.loading}>
          <Column
            headerStyle={{ width: "3rem" }}></Column>
          <Column field='id' header='Müşteri Kodu' sortable></Column>
          <Column field='name' header='Müşteri Adı' sortable></Column>
          <Column field='surname' header='Müşteri Soyadı' sortable></Column>
          <Column field='phone' header='Telefon Numarası' sortable></Column>
          <Column
            field='createdDate'
            header='Oluştulma Tarihi'
            sortable
            body={OrderHelper.formatDateTime}></Column>
          <Column
            field='emailAdress.emailAdress'
            header='E-mail Adresi'
            sortable></Column>
          <Column body={(p) => this.actionBodyTemplate(p)}></Column>
        </DataTable>

        <Dialog
          style={{ width: "650px" }}
          header={`Müşteri Bilgileri`}
          modal
          className='p-fluid'
          visible={this.state.openCustomerDetail}
          onHide={() => this.setState({ openCustomerDetail: false })}>
          {this.state.openCustomerDetail && this.state.selectedCustomers.id && (
            <Customer customerId={this.state.selectedCustomers.id} />
          )}
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customers: state.customer.customers,
    selectedCustomers: state.customer.selectedCustomers,
    localizations: state.localization.localizations,
    loading: state.customer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedCustomer: (customer) => dispatch(setSelectedCustomer(customer)),
    setCustomers: (customers) => dispatch(setCustomers(customers)),
    setLoading: (val) => dispatch(setLoading(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerManagement);
