import CookieHelper from "./CookieHelper";
import axios from "axios";
import NotificationHelper from "./NotificationHelper";
import { setVisibleLoginConfirm } from '../storage/inventory/inventorySlice';
export default class RequestHelper {

  static get localization() {
    return "de";
  }
  static get token() {
    return CookieHelper.getCookie(CookieHelper.userCookieName);
  }
  static get timeout() {
    return 7000000;
  }
  static get baseURL() {
    return `${process.env.REACT_APP_ENVIRONMENT == "Test"
      ? process.env.REACT_APP_API_URL_LOCAL
      : process.env.REACT_APP_API_URL
      }`;
  }

  static get client() {
    const instance = axios.create({
      baseURL: this.baseURL,
      timeout: this.timeout,
      headers: { Authorization: `Bearer ${this.token}` },
    });
    instance.interceptors.response.use((response) => {
      if (response.status == 200) {
        const apiResponse = response.data;
        if (apiResponse.status == 6) {
          NotificationHelper.Error(apiResponse.userMessage);
        }
      }

      return response;
    })
    return instance;
  }
  static get multipartClient() {
    const instance = axios.create({
      baseURL: this.baseURL,
      timeout: this.timeout,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${this.token}`,
      },
    });

    return instance;
  }
  static prepareRequest(data) {
    return {
      data,
      language: this.localization,
      // token: this.token,
    };
  }

  static post(data, controller, action) {
    return new Promise((resolve, reject) => {
      this.client
        .post(`${controller}/${action}`, this.prepareRequest(data))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static async get(controller, action) {
    return new Promise((resolve, reject) => {
      this.client
        .get(`${controller}/${action}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  static async multipart(data, controller, action) {
    return new Promise((resolve, reject) => {
      this.multipartClient
        .post(`${controller}/${action}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
