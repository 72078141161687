import { Column } from "primereact/components/column/Column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NotificationHelper from "../../helper/NotificationHelper";
import OrderHelper from "../../helper/OrderHelper";
import { GetPaymentHistoryLogsByOrder } from "../../service/orderService";

function OrderPaymentHistoryLog({ visible, setVisible }) {
  const [orderPaymentHistoryLogs, setOrderPaymentHistoryLogs] = useState([]);

  const order = useSelector((state) => state.order.selectedOrder);
  const [orderId, setOrderId] = useState(order.id);

  useEffect(() => {
    getOrderPaymentHistoryLogs();
  }, [orderId]);

  const getOrderPaymentHistoryLogs = () => {
    GetPaymentHistoryLogsByOrder(orderId)
      .then((response) => {
        if (response["is-success"]) {
          console.log(response.data);
          setOrderPaymentHistoryLogs(response.data);
        } else {
          NotificationHelper.Error(response.userMessage);
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir hata ile karşılaşıldı");
      });
  };

  const paymentTypeBodyTemplate = (rowData) => {
    const foundedStatus = OrderHelper.PaymentTypes.find(
      (element) => element.value == rowData.paymentType
    );
    return (
      <span className={`order-badge order`}>{foundedStatus?.key || ""}</span>
    );
  };
  const processTypeBodyTemplate = (rowData) => {
    const foundedStatus = OrderHelper.ProccessTypes.find(
      (element) => element.value == rowData.processType
    );
    return (
      <span className={`order-badge order`}>{foundedStatus?.key || ""}</span>
    );
  };

  const paymentStatusBodyTemplate = (rowData) => {
    const foundedStatus = OrderHelper.PaymentStatus.find(
      (element) => element.value == rowData.paymentStatus
    );
    return (
      <span className={`order-badge order`}>{foundedStatus?.key || ""}</span>
    );
  };

  const providerTypeBodyTemplate = (rowData) => {
    const foundedStatus = OrderHelper.ProviderTypes.find(
      (element) => element.value == rowData.providerType
    );
    return (
      <span className={`order-badge order`}>{foundedStatus?.key || ""}</span>
    );
  };
  
  return (
    <Dialog
      visible={visible}
      style={{ width: "1300px" }}
      header={"Sipariş Ödeme Kayıtları"}
      modal
      className='p-fluid'
      onHide={() => setVisible(false)}>
      <div className='card'>
        <DataTable
          // ref={dt}
          value={orderPaymentHistoryLogs}
          dataKey='id'
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
          currentPageReportTemplate='Showing {first} to {last} of {totalRecords} order logs'
          emptyMessage='Siparişin Herhangi bir ödeme kaydı bulunamadı.'
          header={""}
          //sortMode="multiple"
          scrollable>
          {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
          <Column field='providerType' header='Ödeme Aracı' body={providerTypeBodyTemplate} sortable></Column>
          <Column
            field='paymentType'
            header='Ödeme Tipi'
            body={paymentTypeBodyTemplate}
            sortable></Column>
          <Column
            field='processType'
            header='İşlem Tipi'
            body={processTypeBodyTemplate}
            sortable></Column>
          <Column
            field='paymentStatus'
            header='İşlem Tipi Durumu'
            body={paymentStatusBodyTemplate}
            sortable></Column>
          <Column field='description' header='Açıklama' sortable></Column>
          <Column field='txId' header='Payone İşlem Id' sortable></Column>
          <Column
            field='createdDate'
            header='İşlem Tarihi'
            sortable
            body={OrderHelper.formatDateTime}></Column>
          <Column
            field='uniqueValue'
            header='İşlem İçin Eşşiz Bir Değer'
            sortable></Column>
        </DataTable>
      </div>
    </Dialog>
  );
}

export default OrderPaymentHistoryLog;
