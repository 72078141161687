import { Button } from "primereact/button";
import React from "react";
import { useDispatch } from "react-redux";
import NotificationHelper from "../../helper/NotificationHelper";
import { AdminGetAllMainCategories } from "../../service/inventoryService";
import { setMainCategories } from "../../storage/mainCategory/mainCategorySlice";

function GetAllMainCategories({setComponentLoading}) {
  const dispatch = useDispatch();
  const reload = () => {
    setComponentLoading(true);
    AdminGetAllMainCategories().then((response) => {
      if (response["is-success"]) {
        dispatch(setMainCategories(response.data));
      } else {
        NotificationHelper.Error(
          response.userMessage || "Bir hata meydana geldi."
        );
      }
    }).finally(()=>setComponentLoading(false));
  };
  return (
    <Button
      label='Yenile'
      icon='pi pi-refresh'
      className='p-button mx-3'
      onClick={()=>reload()}
    />
  );
}

export default GetAllMainCategories;
