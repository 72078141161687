import { Button } from "primereact/button";
import React from "react";
import { useDispatch } from "react-redux";
import NotificationHelper from "../../helper/NotificationHelper";
import { AdminGetAllProduct } from "../../service/inventoryService";
import { setProducts } from "../../storage/product/productSlice";

function GetAllProducts({ setComponentLoading, setPureData }) {
  const dispatch = useDispatch();
  const reload = () => {
    setComponentLoading(true);
    AdminGetAllProduct()
      .then((response) => {
        if (response["is-success"]) {
          dispatch(setProducts(response.data));
          setPureData(response.data);
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi."
          );
        }
      })
      .finally(() => setComponentLoading(false));
  };
  return (
    <Button
      label='Yenile'
      icon='pi pi-refresh'
      className='p-button mx-3'
      onClick={() => reload()}
    />
  );
}

export default GetAllProducts;
