import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import GemGastroSpinner from "../Core/GemGastroSpinner";
import ProductCategoryTransfer from "../Product/ProductCategoryTransfer";
import ProductSelectGrid from "../Product/ProductSelectGrid";

function CategoryProducts({ visible, setVisible, categoryId }) {
  const products = useSelector((state) => state.product.products);
  const category = useSelector((state) => state.category.selectedCategory);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [componentLoading, setComponentLoading] = useState(false);
  const header = () => {
    return (
      <ProductCategoryTransfer
        selectedProducts={selectedProducts}
        setSelectedProducts={(p) => setSelectedProducts(p)}
        setComponentLoading={(p) => setComponentLoading(p)}
      />
    );
  };
  return (
    <Dialog
      style={{ width: "1200px" }}
      visible={visible}
      onHide={() => setVisible(false)}
      header={header}>
      {componentLoading ? (
        <GemGastroSpinner isLogin={false} />
      ) : (
        <>
          <h5 className='w-100'>
            {category.categoryLocalizations[0].categoryName} 'in Ürünleri
          </h5>
          <ProductSelectGrid
            products={products.filter((x) => x.categoryId == categoryId)}
            selectedProducts={selectedProducts}
            setSelectedProducts={(p) => setSelectedProducts(p)}
          />
        </>
      )}
    </Dialog>
  );
}

export default CategoryProducts;
