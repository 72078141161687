import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React from "react";
import ProductHelper from "./ProductHelper";

export default class CargoHelper {
  static findById(cargoId, cargos) {
    return cargos.find((x) => x.id == cargoId);
  }
  static cargoNameTemplate = (cargo) => {
    return (
      <span>
        {(cargo?.length &&
          cargo?.fullName) ||
          ""}
      </span>
    );
  };
  static actionBodyTemplate = (cargo, editFunc) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2 mr-4"
          onClick={() => editFunc(cargo)}
        />
        {/* <Button
					icon="pi pi-trash"
					className="p-button-rounded p-button-warning"
					onClick={() => confirmDeleteProduct(cargo)}
				/> */}
      </React.Fragment>
    );
  };
}
