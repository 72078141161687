import { Button } from "primereact/button";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import NotificationHelper from "../../helper/NotificationHelper";
import { DeleteCargos } from "../../service/cargoService";
import Confirm from "../Core/Confirm";

function DeleteCargo({ selectedCargos, setSelectedCargos }) {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [confirmDialogMessage, setConfirmDialogMessage] = useState(
    `Seçili kargoları silmek istediğinize emin misiniz?`
  );

  const dispatch = useDispatch();

  const deleteCargos = () => {
    const cargoIds = selectedCargos.map((p) => p.id);
    DeleteCargos(cargoIds)
      .then((response) => {
        if (response["is-success"]) {
          dispatch(deleteCargos(cargoIds));
          NotificationHelper.Success(
            "Silme işlemi başarılı bir şekilde sonuçlandı"
          );
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi."
          );
          
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir hata meydana geldi.");
      });
  };

  return (
    <>
      <Confirm
        visible={openConfirmDialog}
        setVisible={(p) => setOpenConfirmDialog(p)}
        callBack={deleteCargos}
        confirmMessage={confirmDialogMessage}
      />
      <Button
        label='Kargo Sil'
        icon='pi pi-trash'
        className='p-button-danger mx-3'
        onClick={() => setOpenConfirmDialog(true)}
        disabled={!selectedCargos || !selectedCargos.length}
      />
    </>
  );
}

export default DeleteCargo;
