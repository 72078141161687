import { useState } from "react";
import GemGastroSpinner from "../Core/GemGastroSpinner";
import { useHistory } from "react-router-dom";
import StringHelper from "../../helper/StringHelper";
import { GetSecurityCode, SignIn } from "../../service/userService";
import NotificationHelper from "../../helper/NotificationHelper";
import CookieHelper from "../../helper/CookieHelper";
function LoginForm({ type }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [securityCode, setSecurityCode] = useState("");
  const [visibleSecurityCode, setVisibleSecurityCode] = useState(true);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const request = StringHelper.isNullOrWhiteSpace(securityCode)
      ? { Email: username, password }
      : { Email: username, password, securityCode };

    if (visibleSecurityCode) {
      GetSecurityCode(request)
        .then((response) => {
          if (response["is-success"]) {
            setVisibleSecurityCode(false);
            NotificationHelper.Success(
              "Güvenlik kodu mail adresinize gönderildi."
            );
          } else {
            NotificationHelper.Error(response.userMessage);
          }
        })
        .catch((err) => {
          NotificationHelper.Error("Bir hata meydana geldi");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      SignIn(request)
        .then((response) => {
          const data = response.data;
          if (response["is-success"]) {
            // setVisibleSecurityCode(true);
            const token = data.token;
            // NotificationHelper.Success(
            //   "İşlem başarılı yönlendiriliyorsunuz..."
            // );
            CookieHelper.setCookie(CookieHelper.userCookieName, token, 30);
            history.push("/");
          } else {
            NotificationHelper.Error(response.userMessage);
          }
        })
        .catch((err) => {
          NotificationHelper.Error("Bir hata meydana geldi");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "username") setUsername(value);
    if (name === "password") setPassword(value);
    if (name === "securityCode") setSecurityCode(value);
  };

  return (
    <>
      {loading ? (
        <GemGastroSpinner isLogin={true} />
      ) : (
        <form className='w-100 p-4 border shadow' onSubmit={handleSubmit}>
          <div className='form-group'>
            <label htmlFor='username'>
              Kullanıcı E-mail Adresi <span className='text-danger'>*</span>
            </label>
            <input
              name='username'
              className='form-control'
              value={username}
              onChange={handleChange}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='password' className='form-control-label'>
              Şifreniz <span className='text-danger'>*</span>
            </label>
            <input
              name='password'
              type='password'
              className='form-control'
              value={password}
              onChange={handleChange}
            />
          </div>
          {!visibleSecurityCode && (
            <div className='form-group'>
              <label htmlFor='password' className='form-control-label'>
                Güvenlik kodu <span className='text-danger'>*</span>
              </label>
              <input
                name='securityCode'
                className='form-control'
                onChange={handleChange}
              />
            </div>
          )}
          <button
            type='submit'
            className='btn btn-block bg-dark text-white my-2'>
            Giriş
          </button>
          <small className='text-danger lead'>{error}</small>
        </form>
      )}
    </>
  );
}

export default LoginForm;
