import axios from "axios";
import React, { useEffect, useState } from "react";
import GemGastroSpinner from "../components/Core/GemGastroSpinner";
import NotificationHelper from "../helper/NotificationHelper";
import {
  CreateOrUpdateCompanyInformation,
  GetCompanyInformation,
} from "../service/inventoryService";

function CompanyInformation() {
  const [address, setAddRess] = useState("");
  const [mail, setMail] = useState("");
  const [tel, setTel] = useState("");
  const [fax, setFax] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getCompanyInformation();
  }, []);
  const getCompanyInformation = () => {
    setLoading(true);
    GetCompanyInformation()
      .then((response) => {
        if (response["is-success"]) {
          const companyInformation = response?.data;
          setAddRess(companyInformation.address);
          setMail(companyInformation.mail);
          setTel(companyInformation.tel);
          setFax(companyInformation.fax);
          setLoading(false);
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata ile karşılaşıldı."
          );
          setLoading(false);
          return false;
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir hata ile karşılaşıldı.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFormSubmit = () => {
    setLoading(true);

    const data = {
      address,
      mail,
      tel,
      fax,
    };

    CreateOrUpdateCompanyInformation(data)
      .then((response) => {
        if (response["is-success"] && response?.data) {
          NotificationHelper.Success("Değişiklikler kaydedildi.");
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi"
          );
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir hata meydana geldi");
      })
      .finally(() => {
        getCompanyInformation();
      });
  };

  return (
    <>

      {loading ? (
        <GemGastroSpinner />
      ) : (
        <div className='container '>
          <div className='row companyInformationContainer'>
            <div className='col-12'>
              <div class='form-group'>
                <label for='exampleInputEmail1'>Şirket Adresi</label>
                <input
                  type='email'
                  class='form-control'
                  id='exampleInputEmail1'
                  value={address}
                  onChange={(e) => setAddRess(e.target.value)}
                />
              </div>
              <div class='form-group'>
                <label for='exampleInputEmail1'>Şirket Telefonu</label>
                <input
                  type='email'
                  class='form-control'
                  id='exampleInputEmail1'
                  value={tel}
                  onChange={(e) => setTel(e.target.value)}
                />
              </div>
              <div class='form-group'>
                <label for='exampleInputEmail1'>Şirket Fax No</label>
                <input
                  type='email'
                  class='form-control'
                  id='exampleInputEmail1'
                  value={fax}
                  onChange={(e) => setFax(e.target.value)}
                />
              </div>
              <div class='form-group'>
                <label for='exampleInputEmail1'>Şirket E-Posta</label>
                <input
                  type='email'
                  class='form-control'
                  id='exampleInputEmail1'
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                />
              </div>
            </div>
            <div className='col-12'>
              <button
                className='companyInformationButton'
                onClick={onFormSubmit}>
                Kaydet
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CompanyInformation;
