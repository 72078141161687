import RequestHelper from "../helper/RequestHelper";

export async function GetInventories() {
  const response = await RequestHelper.post(
    {
      data: {
        data: {},
        Language: "de",
      },
      language: "de",
    },
    "Inventory",
    "getInventories"
  );
  const r = response.data;
  return r;
}
export async function AdminGetAllOrder() {
  const response = await RequestHelper.post(
    {
      data: {},
      language: "de",
    },
    "Inventory",
    "AdminGetAllOrder"
  );
  const r = response.data;
  return r;
}
export async function AdminGetAllProduct() {
  const response = await RequestHelper.post(
    {
      data: {},
      language: "de",
    },
    "Inventory",
    "AdminGetAllProduct"
  );
  const r = response.data;
  return r;
}
export async function AdminGetAllDeletedProduct() {
  const response = await RequestHelper.get(
    "Inventory",
    "AdminGetAllDeletedProduct"
  );
  const r = response.data;
  return r;
}
export async function UpdateSliderLink(imageId, link) {
  const response = await RequestHelper.post(
    { id: imageId, sliderLink: link },
    "Inventory",
    "UpdateSliderLink"
  );
  const r = response.data;
  return r;
}
export async function UpdateErrorMessages(data) {
  const response = await RequestHelper.post(
    data,
    "Inventory",
    "UpdateErrorMessages"
  );
  const r = response.data;
  return r;
}
export async function UpdateClientLocalization(data) {
  const response = await RequestHelper.post(
    data,
    "Inventory",
    "UpdateClientLocalization"
  );
  const r = response.data;
  return r;
}
export async function UpdateSettings(data) {
  const response = await RequestHelper.post(
    data,
    "Inventory",
    "UpdateSettings"
  );
  const r = response.data;
  return r;
}
export async function UpdateProductToActive(productId) {
  const response = await RequestHelper.post(
    productId,
    "Inventory",
    "UpdateProductToActive"
  );
  const r = response.data;
  return r;
}
export async function AdminGetAllMainCategories() {
  const response = await RequestHelper.post(
    {
      data: {},
      language: "de",
    },
    "Inventory",
    "AdminGetAllMainCategories"
  );
  const r = response.data;
  return r;
}
export async function AdminGetAllCategories() {
  const response = await RequestHelper.post(
    {
      data: {},
      language: "de",
    },
    "Inventory",
    "AdminGetAllCategories"
  );
  const r = response.data;
  return r;
}
export async function AdminGetAllCustomers() {
  const response = await RequestHelper.get(
    "Inventory",
    "AdminGetAllCustomers"
  );
  const r = response.data;
  return r;
}
export async function AdminGetAllCargos() {
  const response = await RequestHelper.post(
    {
      data: {},
      language: "de",
    },
    "Inventory",
    "AdminGetAllCargos"
  );
  const r = response.data;
  return r;
}
export async function CreateOrUpdateSupplier(data) {
  const response = await RequestHelper.post(
    data,
    "inventory",
    "createorupdatesupplier"
  );
  const r = response.data;
  return r;
}
export async function GetAllSuppliers() {
  const response = await RequestHelper.get("inventory", "GetAllSuppliers");
  const r = response.data;
  return r;
}
export async function DeleteSupplier(data) {
  const response = await RequestHelper.post(
    data,
    "inventory",
    "deleteSupplier"
  );
  const r = response.data;
  return r;
}
export async function GetPaymentServiceSettings() {
  const response = await RequestHelper.post(
    true,
    "inventory",
    "GetPaymentServiceSettings"
  );
  const r = response.data;
  return r;
}
export async function UpdatePaymentServiceSettings(data) {
  const response = await RequestHelper.post(
    data,
    "inventory",
    "UpdatePaymentServiceSettings"
  );
  const r = response.data;
  return r;
}
export async function GetBanks() {
  const response = await RequestHelper.post(
    {},
    "inventory",
    "GetBanks"
  );
  const r = response.data;
  return r;
}
export async function DeleteBank(data) {
  const response = await RequestHelper.post(
    data,
    "inventory",
    "deleteBank"
  );
  const r = response.data;
  return r;
}
export async function CreateUpdateBank(data) {
  const response = await RequestHelper.post(
    data,
    "inventory",
    "createOrUpdateBank"
  );
  const r = response.data;
  return r;
}

export async function GetSliderImages() {
  const response = await RequestHelper.get("homepage", "getsliderimages");
  const r = response.data;
  return r;
}
export async function GetHomePage() {
  const response = await RequestHelper.post(
    {
      data: {},
      language: "de",
    },
    "homepage",
    "gethomepage"
  );
  const r = response.data;
  return r;
}
export async function EditHomePageProducts(data) {
  const response = await RequestHelper.post(
    data,
    "homepage",
    "EditHomePageProducts"
  );
  const r = response.data;
  return r;
}
export async function GetAboutUsImages() {
  const response = await RequestHelper.get("homepage", "GetAboutUsImages");
  const r = response.data;
  return r;
}
export async function GetCompanyInformation() {
  const response = await RequestHelper.get("homepage", "GetCompanyInformation");
  const r = response.data;
  return r;
}
export async function CreateOrUpdateCompanyInformation(data) {
  const response = await RequestHelper.post(
    data,
    "inventory",
    "CreateOrUpdateCompanyInformation"
  );
  const r = response.data;
  return r;
}
