import RequestHelper from "../helper/RequestHelper";

export async function GetProductDetail(data) {
  const response = await RequestHelper.post(
    data,
    "inventory",
    "GetProductDetail"
  );
  const r = response.data;
  return r;
}
export async function TransferCategoryProduct(data) {
  const response = await RequestHelper.post(
    data,
    "product",
    "TransferCategoryProduct"
  );
  const r = response.data;
  return r;
}
export async function DeleteProducts(data) {
  const response = await RequestHelper.post(data, "product", "DeleteProduct");
  const r = response.data;
  return r;
}
export async function CreateProduct(data) {
  const response = await RequestHelper.post(data, "product", "CreateProduct");
  const r = response.data;
  return r;
}
export async function UpdateProduct(data) {
  const response = await RequestHelper.post(data, "product", "UpdateProduct");
  const r = response.data;
  return r;
}
