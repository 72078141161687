import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useSelector } from "react-redux";
import StringHelper from "../../helper/StringHelper";

function Confirm({ visible, setVisible, callBack, confirmMessage }) {
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label='No'
        icon='pi pi-times'
        className='p-button-text'
        onClick={() => setVisible(false)}
      />
      <Button
        label='Yes'
        icon='pi pi-check'
        className='p-button-text'
        onClick={() => callBack()}
      />
    </React.Fragment>
  );
  return (
    <Dialog
      visible={visible}
      style={{ width: "450px" }}
      header='Confirm'
      modal
      footer={deleteProductsDialogFooter}
      onHide={() => setVisible(false)}>
      <div className='confirmation-content'>
        <i
          className='pi pi-exclamation-triangle p-mr-3'
          style={{ fontSize: "2rem" }}
        />
          <span>
            {!StringHelper.isNullOrWhiteSpace(confirmMessage)
              ? confirmMessage
              : "Yapılan işlem geri alınamaya bilir.Emin misiniz ?"}
          </span>
      </div>
    </Dialog>
  );
}

export default Confirm;
