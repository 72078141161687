import React, { Component } from "react";
import { connect } from "react-redux";
import LoginForm from "../components/Login/LoginForm";


export class Login extends Component {
  render() {
    return (
      <div className='container-fluid'>
        <div className='row d-flex align-content-center my-4'>
          <div className='col-12 my-3'>
            <img className=' img-fluid' src={window.location.origin + '/gastrologopng.png'} />
          </div>
          <div className='col-12 col-md-4 col-lg-3 d-flex justify-content-center mx-auto'>
            <LoginForm type='login' />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(Login);
