export default class StringHelper {
  static isNullOrWhiteSpace = (val) => {
    if (val && typeof val == "string" && val.trim().length) return false;
    else return true;
  };

  static makeId(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
