import RequestHelper from "../helper/RequestHelper";

export async function GetAllOrders() {
  const data = {
    data: {},
    language: RequestHelper.localization,
  };
  const response = await RequestHelper.post(data, "order", "GetAllOrder");
  const r = response.data;
  return r;
}
export async function UpdateOrderStatus(data) {
  const response = await RequestHelper.post(data, "order", "UpdateOrder");
  const r = response.data;
  return r;
}
export async function GetOrderLogs(orderId) {
  const response = await RequestHelper.post(orderId, "order", "getorderlogs");
  const r = response.data;
  return r;
}
export async function GetPaymentHistoryLogsByOrder(orderId) {
  const response = await RequestHelper.post(orderId, "order", "GetPaymentHistoryLogsByOrder");
  const r = response.data;
  return r;
}
export async function CreateOffer(data) {
  const response = await RequestHelper.post(data, "order", "CreateOffer");
  const r = response.data;
  return r;
}