import { Button } from "primereact/button";
import React from "react";
import { useDispatch } from "react-redux";
import { AdminGetAllOrder } from "../../service/inventoryService";
import { GetAllOrders } from "../../service/orderService";
import { setOrders } from "../../storage/order/orderSlice";
import NotificationHelper from "../../helper/NotificationHelper";
import { setCustomers } from "../../storage/customer/customerSlice";
import { AdminGetAllCustomers } from "../../service/customerService";

export const GetAllOrdersButton = (props) => {
  const dispatch = useDispatch();
  return (
    <Button
      label='Yenile'
      icon='pi pi-refresh'
      className='p-button mx-3'
      onClick={() => {
        props.setComponentLoading(true);
        AdminGetAllOrder()
          .then((response) => {
            if (response?.["is-success"] && response.data) {
              const orders = response.data;
              dispatch(setOrders(orders));
              AdminGetAllCustomers().then((response) => {
                if (response?.["is-success"]) {
                  dispatch(setCustomers(response.data));
                } else {
                  NotificationHelper.Error(
                    response.userMessage || "Bir hata ile karşılaşıldı."
                  );
                }
              });
            } else {
              NotificationHelper.Error(
                response.userMessage || "Bir hata ile karşılaşıldı."
              );
            }
          })
          .finally(() => props.setComponentLoading(false));
      }}
    />
  );
};

export default GetAllOrdersButton;
