import { createSlice } from "@reduxjs/toolkit";

export const localizationSlice = createSlice({
  name: "localization",
  initialState: {
    localizations: [],
    clientLocalizations: {},
    selectedClientLocalization:{},
    selectedErrorMessages:{},
    errorMessages: {},
    settings: [],
    loading: false,
  },
  reducers: {
    setLocalizations: (state, action) => {
      state.localizations = action.payload;
    },
    setSelectedClientLocalization: (state, action) => {
      state.selectedClientLocalization = action.payload;
    },
    setSelectedErrorMessages: (state, action) => {
      state.selectedErrorMessages = action.payload;
    },
    onChangeSelectedClientLocalization: (state, action) => {
      let model = state.selectedClientLocalization;
      model.value= action.payload;
      state.selectedClientLocalization = model;
    },
    onChangeSelectedErrorMessages: (state, action) => {
      let model = state.selectedErrorMessages;
      model.value= action.payload;
      state.selectedErrorMessages = model;
    },
    setClientLocalizations: (state, action) => {
      state.clientLocalizations = action.payload;
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    updateSetting: (state, action) => {
      state.settings = state.settings.map((item) => {
        if (action.payload.id == item.id) {
          item.value = action.payload.value;
        }
        return item;
      });
    },
    setErrorMessages: (state, action) => {
      state.errorMessages = action.payload;
    },
   
    setDiffErrorMessages: (state, action) => {
      const { language, id, value } = action.payload;
      let model = state.errorMessages;
      model[language] = model[language].map((item) => {
        if (item.id == id) {
          item.value = value;
        }
        return item;
      });
      state.errorMessages = model;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { onChangeSelectedErrorMessages,setSelectedErrorMessages,setSelectedClientLocalization,onChangeSelectedClientLocalization,setSettings,updateSetting,setDiffErrorMessages, setLocalizations, setLoading, setClientLocalizations, setErrorMessages, setDiffClientLocalizations } = localizationSlice.actions;

export default localizationSlice.reducer;
