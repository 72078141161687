import { Button } from "primereact/button";
import React from "react";
import { useDispatch } from "react-redux";
import NotificationHelper from "../../helper/NotificationHelper";
import { AdminGetAllDeletedProduct } from "../../service/inventoryService";
import { setDeletedProducts } from "../../storage/product/productSlice";

function GetAllDeletedProducts({ setComponentLoading }) {
  const dispatch = useDispatch();

  const reload = () => {
    setComponentLoading(true);
    AdminGetAllDeletedProduct()
      .then((response) => {
        if (response["is-success"]) {
          dispatch(setDeletedProducts(response.data));
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi."
          );
        }
      })
      .finally(() => setComponentLoading(false));
  };

  return (
    <Button
      label='Yenile'
      icon='pi pi-refresh'
      className='p-button mx-3'
      onClick={() => reload()}
    />
  );
}

export default GetAllDeletedProducts;
