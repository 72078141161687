import { createSlice } from "@reduxjs/toolkit";

export const supplierSlice = createSlice({
  name: "supplier",
  initialState: {
    suppliers: [],
    loading: false,
  },
  reducers: {
    setSuppliers: (state, action) => {
      state.suppliers = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSuppliers, setLoading } = supplierSlice.actions;

export default supplierSlice.reducer;
