import RequestHelper from "../helper/RequestHelper";

export async function DeleteImage(data) {
  const response = await RequestHelper.post(data, "image", "deleteImage");
  const r = response.data;
  return r;
}
export async function CreateImage(data) {
  const response = await RequestHelper.multipart(data, "image", "createImage");
  const r = response.data;
  return r;
}
export async function GetImagesByEntity(data) {
  const response = await RequestHelper.post(
    data,
    "image",
    "GetImagesByEntity"
  );
  const r = response.data;
  return r;
}
export async function ImageUploadLocalization(data) {
  const response = await RequestHelper.multipart(
    data,
    "image",
    "ImageUploadLocalization"
  );
  const r = response.data;
  return r;
}
export async function GetLocalizedImagesByImageId(data) {
  const response = await RequestHelper.post(
    data,
    "image",
    "GetLocalizedImagesByImageId"
  );
  const r = response.data;
  return r;
}
export async function CreateAboutUsImage(data) {
  const response = await RequestHelper.multipart(data, "image", "CreateAboutUsImage");
  const r = response.data;
  return r;
} 