import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React from "react";
import CategoryHelper from "./CategoryHelper";

export default class ProductHelper {
  static formatCurrency = (value) => {
    return value?.toLocaleString("de", {
      style: "currency",
      currency: "EUR",
    });
  };
  static getProductById(productId, products) {
    let product = products.find((x) => x.id == productId);
    return product;
  }
  static prepareAccessories(accessories, products, productId) {
    const foundedProducts = products.filter((x) =>
      accessories.includes(x.productCode)
    );

    if (foundedProducts && foundedProducts.length) {
      return foundedProducts.map((foundedProduct) => {
        return {
          childrenId: foundedProduct.id,
          relationIsActive: true,
          parentId: productId,
        };
      });
    }
  }

  static foundAccessoriesProductCode(accessories, products) {
    const foundedProducts = products.filter((x) =>
      accessories.map((i) => i.childrenId).includes(x.id)
    );
    if (foundedProducts && foundedProducts.length) {
      return foundedProducts.map((i) => i.productCode);
    }
  }

  static getProductImageUrlById(productImages) {
    return productImages[0].path == "Diamond Product"
      ? process.env.REACT_APP_API_IMG_DIAMOND
      : process.env.REACT_APP_API_IMG + "/" + productImages[0].url;
  }
  static categoryBodyTempalte = (product, categories) => {
    const categoryId = product.categoryId;
    const category = CategoryHelper.findById(categoryId, categories);

    const categoryName =
      category?.categoryLocalizations?.[0]?.categoryName || "";

    return <span className='d-flex ml-3'>{categoryName}</span>;
  };
  static imageBodyTemplate = (product) => {
    if (product.productImage?.length)
      return (
        <img
          src={ProductHelper.getProductImageUrlById(product.productImage)}
          className='product-image d-flex justify-content-center'
          style={{ width: "100px",maxHeight:'100px' }}
        />
      );
    return (
      <img
        src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'
        className='product-image'
      />
    );
  };
  static priceBodyTemplate = (product) => {
    return (
      <span className='d-flex ml-3'>
        {this.formatCurrency(product.unitPrice)}
      </span>
    );
  };
  static statusBodyTemplate = (product) => {
    let inventoryStatus;
    if (0 < product.unitStock < 15) inventoryStatus = "lowstock";
    if (15 < product.unitStock) inventoryStatus = "instock";
    if (product.unitStock == 0) inventoryStatus = "outofstock";
    return (
      <span className={`product-badge status-${inventoryStatus.toLowerCase()}`}>
        {inventoryStatus == "lowstock"
          ? "Az Stok"
          : inventoryStatus == "instock"
          ? "Stokta Var"
          : "Stokta Yok"}
      </span>
    );
  };
  static productNameTemplate = (product) => {
    return (
      <span>
        {(product.productLocalizations.length &&
          product.productLocalizations[0]?.productName) ||
          ""}
      </span>
    );
  };
  static productBrancdNameTemplate = (product, suppliers) => {
    return (
      <span>
        {suppliers.find((x) => x.id == product.supplierId)?.name || ""}
      </span>
    );
  };
  static actionBodyTemplate = (product, editFunc) => {
    return (
      <React.Fragment>
        <Button
          icon='pi pi-pencil'
          className='p-button-rounded p-button-success p-mr-2 mr-4'
          onClick={() => editFunc(product)}
        />
        {/* <Button
					icon="pi pi-trash"
					className="p-button-rounded p-button-warning"
					onClick={() => confirmDeleteProduct(product)}
				/> */}
      </React.Fragment>
    );
  };

  static getProductTechnicalInfoLocalizations = (
    productTechnicalInfos,
    localization
  ) => {
    const result = productTechnicalInfos?.map((i) =>
      i.productTechnicalInfoLocalizations.find(
        (x) => x.localizationId == localization.id
      )
    );
    return result;
  };
  static getProductTechnicalInfoValueLocalizations = (
    productTechnicalInfos,
    localization
  ) => {
    const result = productTechnicalInfos
      ?.map((i) => i.technicalValues)?.[0]
      .map((techs) =>
        techs.productTechnicalInfoValueLocalizations.find(
          (x) => x.localizationId == localization.id
        )
      );
    return result;
  };
  static filterTechnicalValueLocalizationsByInfoLocalization(
    productTechInfoLocalization,
    selectedProduct
  ) {
    const productTechnicalInfoId =
      productTechInfoLocalization.productTechnicalInfoId ||
      productTechInfoLocalization.randomTechnicalInfoId;

    let technicalValues = selectedProduct.productTechnicalInfos.find(
      (x) =>
        x.id == productTechnicalInfoId ||
        x.randomTechnicalInfoId == productTechnicalInfoId
    ).technicalValues;

    let technicalLocalizations = technicalValues.map((i) => {
      const filteredLocalization =
        i.productTechnicalInfoValueLocalizations.find(
          (y) => y.localizationId == productTechInfoLocalization.localizationId
        );
      return filteredLocalization;
    });

    // technicalValues =  technicalValues.filter(y => y.productTechnicalInfoId == productTechnicalInfoId && y.localizationId == productTechInfoLocalization.localizationId);

    return technicalLocalizations;
  }
}
