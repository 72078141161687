import { InputText } from 'primereact/inputtext';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GemGastroSpinner from '../components/Core/GemGastroSpinner';
import NotificationHelper from '../helper/NotificationHelper';
import { UpdateSettings } from '../service/inventoryService';
import { setSettings, updateSetting } from '../storage/localization/localizationSlice';

function Settings() {
    const settings = useSelector(x => x.localization.settings);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const enums = () => {
        return [
            { key: 'DefaultLocalization', val: 1 },
            { key: 'MinLocalization', val: 2 },
            { key: 'DefaultCurrencyLocalization', val: 3 },
            { key: 'MultipleCurrency', val: 4 },
            { key: 'SenderMail', val: 5 },
            { key: 'SenderMailPassword', val: 6 },
            { key: 'MailDisplayName', val: 7 },
            { key: 'AdminMail', val: 8 },
            { key: 'MailSender', val: 9 },
            { key: 'DefaultTemplateType', val: 10 },
            { key: 'OrderDetailContent', val: 11 }
        ]
    }
    const onChange = (setting, val) => {
        dispatch(updateSetting({ id: setting.id, value: val }))
    }
    const onSubmit = () => {
        setLoading(true);
        UpdateSettings(settings).then((promise) => {
            if (promise["is-success"]) {
                dispatch(setSettings(promise.data));
            } else {
                NotificationHelper.Error(promise.userMessage);
            }
        }).catch(() => {
            NotificationHelper.Error("Bilinmeyen bir hata.");
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        loading ? <GemGastroSpinner /> :
            <>
                {settings?.map((setting) => {
                    const val = setting.value;
                    const type = setting.settingType;

                    return (
                        <div className='p-field'>
                            <label>{enums().find(x => x.val == type).key}</label>
                            <InputText
                                name={enums().find(x => x.val == type).key}
                                value={val}
                                onChange={(e) => onChange(setting, e.target.value)}
                            />
                        </div>
                    )
                })}
                <button className='btn btn-success' onClick={() => onSubmit()}>Kaydet</button>
            </>
    )

}

export default Settings