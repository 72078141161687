import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Ripple } from "primereact/ripple";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import CargoHelper from "../helper/CargoHelper";
import ProductHelper from "../helper/ProductHelper";
import { GetCargoDetail } from "../service/cargoService";
import CreateOrUpdateCargo from "../components/Cargo/CreateOrUpdateCargo";

import { Toolbar } from "primereact/toolbar";
import { Divider } from "primereact/divider";

import {
  onChangeSelectedCargo,
  setCargoDetail,
  setCargos,
  setSelectedCargo,
  setSelectedCargos,
} from "../storage/cargo/cargoSlice";
import Confirm from "../components/Core/Confirm";
import { AdminGetAllCargo } from "../service/inventoryService";
import NotificationHelper from "../helper/NotificationHelper";
import GetAllCargos from "../components/Cargo/GetAllCargos";
import DeleteCargo from "../components/Cargo/DeleteCargo";
export class Cargo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gridLoading: false,
      selectedCargos: null,
      first1: 0,
      globalFilter: null,
      currentPage: 1,
      pageInputTooltip: "Press 'Enter' key to go to this page.",
      rows: 10,
      dt: React.createRef(),
      editModel: null,
      openCreateOrUpdateCargo: false,
      openConfirmDialog: false,
      confirmDialogMessage:
        "Seçili kargoları silmek istediğinize emin misiniz?",
      selectedTransferCargo: null,
    };
  }
  editCargo = (cargo) => {


    this.setState({ gridLoading: true }, () => {
      this.props.setSelectedCargo(cargo);

      this.setState({ editModel: this.props.selectedCargo }, () => {
        this.setState({ openCreateOrUpdateCargo: true });
        this.setState({ gridLoading: false });
      });
    });
  };

  onPageInputKeyDown = (event, options) => {
    if (event.key === "Enter") {
      const page = parseInt(this.state.currentPage);
      if (page < 0 || page > options.totalPages) {
        this.setState({
          pageInputTooltip: `Value must be between 1 and ${options.totalPages}.`,
        });
      } else {
        const first = this.state.currentPage ? options.rows * (page - 1) : 0;

        this.setState({ first1: first });

        this.setState({
          pageInputTooltip: "Press 'Enter' key to go to this page.",
        });
      }
    }
  };
  paginatorTemplate = {
    layout:
      "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport",
    PrevPageLink: (options) => {
      return (
        <button
          type='button'
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}>
          <span className='p-p-3'>Önceki</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type='button'
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}>
          <span className='p-p-3'>Sonraki</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { "p-disabled": true });

        return (
          <span className={className} style={{ userSelect: "none" }}>
            ...
          </span>
        );
      }

      return (
        <button
          type='button'
          className={options.className}
          onClick={options.onClick}>
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 30, value: 30 },
        { label: 40, value: 40 },
        { label: 50, value: 50 },
      ];

      return (
        <Dropdown
          value={options.value}
          options={dropdownOptions}
          onChange={options.onChange}
          appendTo={document.body}
        />
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          className='p-mx-3'
          style={{ color: "var(--text-color)", userSelect: "none" }}>
          Go to{" "}
          <InputText
            size='2'
            className='p-ml-1'
            value={this.state.currentPage}
            tooltip={this.state.pageInputTooltip}
            onKeyDown={(e) => this.onPageInputKeyDown(e, options)}
            onChange={this.onPageInputChange}
          />
        </span>
      );
    },
  };
  onCustomPage1 = (event) => {
    this.setState({ first1: event.first });
    this.setState({ rows: event.rows });
    this.setState({ currentPage: event.page + 1 });
  };
  gridHeader = (
    <div className='table-header'>
      <h5 className='p-m-1'>Kargo İşlemleri</h5>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onChange={(e) => {
            this.setState({ globalFilter: e.target.value });
          }}
          placeholder='Search...'
        />
      </span>
    </div>
  );

  leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className='container'>
          <div className='row'>
            <div className='col-12 d-flex '>
              <div>
                <Button
                  label='Kargo Ekle'
                  icon='pi pi-plus'
                  className='p-button-success p-mr-2'
                  onClick={() => {
                    this.props.setSelectedCargo({});
                    this.setState({ openCreateOrUpdateCargo: true });
                  }}
                />
                <DeleteCargo
                  selectedCargos={this.state.selectedCargos}
                  setSelectedCargos={(p) =>
                    this.setState({ selectedCargos: p })
                  }
                />
                {/* <GetAllCargos setComponentLoading = {(p) => this.setState({gridLoading:p})}/> */}
              </div>
              
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  render() {

    return (
      <div className='datatable-crud-demo'>
        <Toolbar
          className='p-mb-4 w-100'
          left={this.leftToolbarTemplate}></Toolbar>
        <DataTable
          ref={this.state.dt}
          value={this.props.cargos}
          selection={this.state.selectedCargos}
          onSelectionChange={(e) =>
            this.setState({ selectedCargos: e.value })
          }
          dataKey='id'
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 20, 25, 50]}
          paginatorTemplate={this.paginatorTemplate}
          first={this.state.first1}
          onPage={this.onCustomPage1}
          globalFilter={this.state.globalFilter}
          emptyMessage='Kargo bulunamadı.'
          header={this.gridHeader}
          loading={this.state.gridLoading}>
          <Column
            selectionMode='multiple'
            headerStyle={{ width: "3rem" }}></Column>
          <Column field='id' header='Kargo Kodu' sortable></Column>
          <Column
            header='Kargo Şirketi Adı'
            field='fullName'
            sortable></Column>

          {/* <Column field="rating" header="Reviews" body={ratingBodyTemplate} sortable></Column> */}
          <Column
            body={(p) =>
              CargoHelper.actionBodyTemplate(p, this.editCargo)
            }></Column>
        </DataTable>

        {this.state.openCreateOrUpdateCargo && (
          <CreateOrUpdateCargo
            visible={this.state.openCreateOrUpdateCargo}
            setVisible={(val) =>
              this.setState({ openCreateOrUpdateCargo: val })
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cargos: state.cargo.cargos,
    selectedCargos: state.cargo.selectedCargos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedCargo: (cargo) => dispatch(setSelectedCargo(cargo)),
    setCargos: (cargos) => dispatch(setCargos(cargos)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cargo)