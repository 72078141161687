import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React from "react";
import ProductHelper from "./ProductHelper";

export default class MainCategoryHelper {
  static findById(mainCategoryId, mainCategories) {
    return mainCategories.find((x) => x.id == mainCategoryId);
  }
  static getMainCategoryImageUrlById(mainCategoryImage) {
    return process.env.REACT_APP_API_IMG + "/" + mainCategoryImage.url;
  }
  static mainCategoryNameTemplate = (mainCategory) => {
    return (
      <span>
        {(mainCategory.mainCategoryLocalizations?.length &&
          mainCategory.mainCategoryLocalizations[0]?.mainCategoryName) ||
          ""}
      </span>
    );
  };
  static mainCategoryDescriptionNameTemplate = (mainCategory) => {
    return <span>
    {(mainCategory.mainCategoryLocalizations?.length &&
      mainCategory.mainCategoryLocalizations[0]?.description) ||
      ""}
  </span>;
  };
  static imageBodyTemplate = (mainCategory) => {
    if (mainCategory.mainCategoryImage?.id)
      return (
        <img
          src={MainCategoryHelper.getMainCategoryImageUrlById(mainCategory.mainCategoryImage)}
          className="mainCategory-image d-flex justify-content-center"
          style={{ width: "100px" }}
        />
      );
    return (
      <img
        src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
        className="mainCategory-image"
      />
    );
  };
  static actionBodyTemplate = (mainCategory, editFunc) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2 mr-4"
          onClick={() => editFunc(mainCategory)}
        />
        {/* <Button
					icon="pi pi-trash"
					className="p-button-rounded p-button-warning"
					onClick={() => confirmDeleteProduct(mainCategory)}
				/> */}
      </React.Fragment>
    );
  };
}
