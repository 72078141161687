import React, { useState, useEffect, useContext } from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { Link, useHistory } from "react-router-dom";
import CookieHelper from "../../../helper/CookieHelper";

export const AppProfile = () => {
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [user, setUser] = useState(null);
  useEffect(() => {
    const currentUser = CookieHelper.currentUser;
    setUser(currentUser);
  }, []);
  const onClick = (event) => {
    setExpanded((prevState) => !prevState);
    event.preventDefault();
  };
  return (
    <div className='layout-profile'>
      {user != null && (
        <>
          <button className='p-link layout-profile-link' onClick={onClick}>
            <span className='username'>{user.username}</span>
            <i className='pi pi-fw pi-user-edit' />
          </button>
          <CSSTransition
            classNames='p-toggleable-content'
            timeout={{ enter: 1000, exit: 450 }}
            in={expanded}
            unmountOnExit>
            <ul className={classNames({ "layout-profile-expanded": expanded })}>
              <li className='py-2'>
                <Link className='p-link text-white' to='/sirket-bilgileri'>
                  {" "}
                  <i className='pi pi-fw pi-pencil' />{" "}
                  <span>Şirket Bilgileri</span>
                </Link>
              </li>
              <li>
                <button
                  onClick={() => {
                    CookieHelper.deleteCookie(CookieHelper.userCookieName);
                    history.push("/login");
                  }}
                  type='button'
                  className='p-link'>
                  <i className='pi pi-fw pi-power-off' />{" "}
                  <span>Çıkış</span>
                </button>
              </li>
            </ul>
          </CSSTransition>{" "}
        </>
      )}
    </div>
  );
};
