import RequestHelper from "../helper/RequestHelper";

export async function DeleteMainCategories(data) {
  const response = await RequestHelper.post(data, "mainCategory", "DeleteMainCategories");
  const r = response.data;
  return r;
}
export async function CreateMainCategory(data) {
  const response = await RequestHelper.post(data, "mainCategory", "CreateMainCategory");
  const r = response.data;
  return r;
}
export async function UpdateMainCategory(data) {
  const response = await RequestHelper.post(data, "mainCategory", "UpdateMainCategory");
  const r = response.data;
  return r;
}
