import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { Tooltip } from "primereact/tooltip";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import NotificationHelper from "../../helper/NotificationHelper";
import {
  CreateImage,
  DeleteImage,
  GetImagesByEntity,
} from "../../service/imageService";
import { setCategoryImages } from "../../storage/category/categorySlice";
import { setMainCategoryImages } from "../../storage/mainCategory/mainCategorySlice";
import { setProductImages } from "../../storage/product/productSlice";
import Confirm from "../Core/Confirm";
import GemGastroSpinner from "../Core/GemGastroSpinner";
import ImageLocalization from "./ImageLocalization";

function Image({ visible, setVisible, entityId, fieldName }) {
  //image useState
  const [files, setFiles] = useState([]);
  const [updateFiles, setupdateFiles] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const dt = useRef(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [imageLocalizationVisible, setImageLocalizationVisible] = useState(false);
  const [componentLoading, setComponentLoading] = useState(false);
  const [imageList, setImageList] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    imagesReload();
  }, [visible]);

  //#region FileUpload'ın 3 tane butonun fonksiyonları
  const onRemoveUpdate = (e) => {
    //	setupdateFiles(files.filter((x) => x != e.file));
    setupdateFiles([]);
    NotificationHelper.Success("Fotoğraf Kaldırıldı");
  };
  const onClearUpdate = () => {
    setupdateFiles([]);
    NotificationHelper.Success("Fotoğraflar silindi");
  };
  const onUploadUpdate = (e) => {
    setupdateFiles(e.files);
    NotificationHelper.Success("Dosyalar alındı lütfen kaydetmeyi unutmayın");
  };
  //#endregion

  //#region image reload
  const imagesReload = async () => {
    if (!entityId || entityId < 1) {
      return false;
    }
    setComponentLoading(true);

    const request = {
      FieldName: fieldName,
      EntityId: entityId,
    };

    GetImagesByEntity(request)
      .then((response) => {
        if (response["is-success"]) {
          const reloadedImages = response.data;
          switch (fieldName) {
            case "ProductId":
              dispatch(
                setProductImages({ imageList: reloadedImages, id: entityId })
              );
              break;
            case "MainCategoryId":
              dispatch(
                setMainCategoryImages({
                  imageList: reloadedImages,
                  id: entityId,
                })
              );
              break;
            case "CategoryId":
              dispatch(
                setCategoryImages({ imageList: reloadedImages, id: entityId })
              );
              break;
            default:
              break;
          }
          setImageList(reloadedImages);
        }
      })
      .catch((err) => {
        NotificationHelper.Error("Bir hata meydana geldi.");
      })
      .finally(() => {
        setComponentLoading(false);
      });
  };
  //#endregion

  //#region CreateImage
  const onUploadUpdateSave = async () => {
    setComponentLoading(true);

    const newImage = updateFiles;
    const data = new FormData();
    newImage.map((img, i) => {
      data.append("data.FormFileImages", img);
    });
    data.append("data.FieldName", fieldName);
    data.append("data.FieldId", entityId || 0);
    data.append("data.SliderLink", " ");

    CreateImage(data)
      .then((response) => {
        if (response["is-success"]) {
          NotificationHelper.Success("Fotoğraf başarıyla oluşturuldu.");
          setVisible(false);
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi"
          );
        }
      })
      .catch((err) => {
        NotificationHelper.Error("Beklenmedik bir hata oluştu.");
      })
      .finally(() => {
        setComponentLoading(false);
      });
  };

  //#endregion

  //#region delete image
  const deleteSelectedImage = async () => {
    setComponentLoading(true);
    if (selectedImage) {
      var arr = [];
      arr.push(selectedImage?.id);
      DeleteImage(arr).then((deleteResponse) => {
        if (deleteResponse["is-success"]) {
          NotificationHelper.Success("Fotoğraf başarıyla silindi.");
          imagesReload();
        } else {
          NotificationHelper.Error(deleteResponse?.userMessage);
        }
      });
      setOpenConfirmDialog(false);
    } else {
      NotificationHelper.Error("Lütfen öncelikle fotoğraf seçiniz.");
    }
  };
  //#endregion

  //#region  footers
  const imageFooter = (
    <React.Fragment>
      <Button
        label='İptal Et'
        icon='pi pi-times'
        className='p-button-text'
        onClick={() => setVisible(false)}
      />
      <Button
        label='Kaydet ve Çık'
        icon='pi pi-check'
        className='p-button-text'
        disabled={updateFiles < 1}
        onClick={() => onUploadUpdateSave()}
      />
    </React.Fragment>
  );

  //#endregion

  //#region BodyTemplates
  const imageListBodyTemplate = (rowData) => {
    return (
      <img
        src={`${process.env.REACT_APP_API_IMG}/${rowData.url}`}
        className='product-image'
        style={{ width: "110px" }}
      />
    );
  };
  const actionBodyTemplateImage = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon='pi pi-pencil'
          className='p-button-rounded p-button-info'
          onClick={() => { setSelectedImage(rowData); setImageLocalizationVisible(true) }}
        />
        <Button
          icon='pi pi-trash'
          className='p-button-rounded p-button-warning'
          onClick={() => confirmDeleteImage(rowData)}
        />
      </React.Fragment>
    );
  };
  //#endregion

  //#region  confirm delete
  const confirmDeleteImage = (rowData) => {
    setSelectedImage(rowData);
    setOpenConfirmDialog(true);
  };
  //#endregion

  return (
    <div>
      {componentLoading ? (
        <GemGastroSpinner isLogin={false} />
      ) : (
        <>
          <ImageLocalization imageId={selectedImage?.id} setVisible={(d) => setImageLocalizationVisible(d)} visible={imageLocalizationVisible} />
          <Confirm
            visible={openConfirmDialog}
            setVisible={(p) => setOpenConfirmDialog(p)}
            callBack={() => deleteSelectedImage()}
            confirmMessage='Seçilen fotoğraflar silenecek emin misiniz ?'
          />
          <Dialog
            header={"Ürün Fotoğrafı Düzenle"}
            visible={visible}
            onHide={() => setVisible(false)}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw" }}
            footer={imageFooter}>
            <div>
              <Tooltip
                target='.custom-choose-btn'
                content='Choose'
                position='bottom'
              />
              <Tooltip
                target='.custom-upload-btn'
                content='Upload'
                position='bottom'
              />
              <Tooltip
                target='.custom-cancel-btn'
                content='Clear'
                position='bottom'
              />

              <div className='card'>
                <>
                  <FileUpload
                    disabled={imageList?.length && fieldName != 'ProductId'}
                    name='Image'
                    url='./upload.php'
                    //   value={selectedProduct?.productImage}
                    customUpload
                    onRemove={onRemoveUpdate}
                    onClear={onClearUpdate}
                    uploadHandler={onUploadUpdate}
                    single
                    maxFileSize={1000000}
                    emptyTemplate={
                      <p className='p-m-0'>Fotoğrafı sürükle bırak</p>
                    }
                  />
                  <hr />
                </>
                {imageList?.length && (
                  <DataTable
                    ref={dt}
                    value={imageList}
                    selection={selectedImage}
                    onSelectionChange={(e) => {
                      setSelectedImage(e.value);
                    }}
                    dataKey='id'
                    paginator
                    rows={5}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                    currentPageReportTemplate='Showing {first} to {last} of {totalRecords} products'
                    header={
                      <div className='table-header'>
                        <h5 className='p-m-0'>Eski Fotoğraf</h5>
                      </div>
                    }>
                    <Column field='id' header='Fotoğraf Kodu' sortable></Column>
                    <Column
                      field='url'
                      header='Fotoğraf'
                      body={imageListBodyTemplate}></Column>
                    <Column body={actionBodyTemplateImage}></Column>
                  </DataTable>
                )}
              </div>
            </div>
          </Dialog>
        </>
      )}
    </div>
  );
}

export default Image;
