import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function Customer({ customerId }) {
  const [customer, setCustomer] = useState(null);

  const customers = useSelector((state) => state.customer.customers);
  useEffect(() => {
    const foundedCustomer = customers.find((x) => x.id == customerId);
    setCustomer(foundedCustomer);
  }, [customerId]);

  return (
    <div className='p-field'>
      <h5
        className='text-muted pb-2'
        style={{
          fontSize: "16px",
          borderBottom: "1px solid #aaa",
        }}>
        Müşteri Detayları
      </h5>
      <div className='p-grid' style={{ marginLeft: "5px" }}>
        {/* <div className="row" style={{ marginLeft: "5px" }}> */}
        <div className='p-col-4'>
          <label htmlFor='name'>Müşteri Adı</label>
          <InputText
            id='name'
            value={customer?.name}
            autoFocus
            disabled='true'
          />
        </div>
        <div className='p-col-4'>
          <label htmlFor='surname'>Müşteri Soyadı</label>
          <InputText
            id='surname'
            value={customer?.surname}
            autoFocus
            disabled='true'
          />
        </div>
        <div className='p-col-4'>
          <label htmlFor='phone'>Müşteri Telefonu</label>
          <InputText
            id='phone'
            value={customer?.phone}
            autoFocus
            disabled='true'
          />
        </div>
        {/* </div> */}
        <div>
          {customer?.address?.map((item) => (
            <>
              <p
                className='text-muted pb-2 w-50'
                style={{
                  fontSize: "12px",
                  borderBottom: "1px solid #aaa",
                }}>
                Müşteri Adresleri
              </p>
              <div className='p-grid' style={{ marginLeft: "5px" }}>
                <div className='p-col-4'>
                  <label>Ülke</label>
                  <InputText
                    id={item.country}
                    value={item.country}
                    disabled
                    autoFocus
                  />
                </div>
                <div className='p-col-4'>
                  <label>Şehir</label>
                  <InputText
                    id={item.city}
                    value={item.city}
                    disabled
                    autoFocus
                  />
                </div>
                <div className='p-col-4'>
                  <label>İlçe</label>
                  <InputText
                    id={item.town}
                    value={item.town}
                    disabled
                    autoFocus
                  />
                </div>
                <div className='p-col-4'>
                  <label>Posta Kodu</label>
                  <InputText
                    id={item.postCode}
                    value={item.postCode}
                    disabled
                    autoFocus
                  />
                </div>
                <div className='p-col-4'>
                  <label>Bina No</label>
                  <InputText
                    id={item.buildingNo}
                    value={item.buildingNo}
                    disabled
                    autoFocus
                  />
                </div>
                <div className='p-col-4'>
                  <label>Adres Açıklaması</label>
                  <InputText
                    id={item.addressDescription}
                    value={item.addressDescription}
                    disabled
                    autoFocus
                  />
                </div>
                <div className='p-col-4'>
                  <label>Şirket Adı</label>
                  <InputText
                    id={item.companyName}
                    value={item.companyName}
                    disabled
                    autoFocus
                  />
                </div>

                <div
                  className='p-field-checkbox p-col-4'
                  style={{ marginTop: "25px" }}>
                  <Checkbox
                    style={{ fontSize: "20px" }}
                    inputId='ship'
                    checked={item.isInvoiceAdress}
                    disabled='true'
                  />
                  <label htmlFor='ship'>{"Fatura Adresi"}</label>
                </div>
                <div
                  className='p-field-checkbox p-col-4'
                  style={{ marginTop: "25px" }}>
                  <Checkbox
                    style={{ fontSize: "20px" }}
                    inputId='ship'
                    checked={item.isShipAdress}
                    disabled='true'
                  />
                  <label htmlFor='ship'>{"Teslimat Adresi"}</label>
                </div>
              </div>
            </>
          ))}
        </div>
        <div className='p-grid w-100' style={{ marginLeft: "5px" }}>
          <div className='p-col-6'>
            <label>Email Adresi</label>
            <InputText
              id={customer?.emailAdress?.emailAdress}
              value={customer?.emailAdress?.emailAdress}
              disabled
              autoFocus
            />
          </div>
        </div>
      </div>
    </div>
  );
}
