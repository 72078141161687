import React from "react";

export const AppFooter = () => {
  return (
    <div className='layout-footer'>
      <span className='footer-text' style={{ marginRight: "5px" }}>
        GEM Gastro
      </span>
      <img src='/gastrologopng.png' alt='Logo' width='80' />
      <span className='footer-text' style={{ marginLeft: "5px" }}>
        Admin Panel
      </span>
    </div>
  );
};
