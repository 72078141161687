import { Button } from "primereact/button";
import React from "react";
import { useDispatch } from "react-redux";
import NotificationHelper from "../../helper/NotificationHelper";
import { AdminGetAllCustomers } from "../../service/customerService";
import { setCustomers } from "../../storage/customer/customerSlice";

function GetAllCustomers({setComponentLoading,setPureData}) {
  const dispatch = useDispatch();
  const reload = () => {
    setComponentLoading(true);
    AdminGetAllCustomers()
      .then((response) => {
        if (response["is-success"]) {
          dispatch(setCustomers(response.data));
          setPureData(response.data)
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi."
          );
        }
      })
      .finally(() => setComponentLoading(false));
  };
  return (
    <Button
      label='Yenile'
      icon='pi pi-refresh'
      className='p-button mx-3'
      onClick={() => reload()}
    />
  );
}

export default GetAllCustomers;
