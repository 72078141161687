import { createSlice } from "@reduxjs/toolkit";

export const mainCategoryLocalizationSlice = createSlice({
  name: "mainCategoryLocalization",
  initialState: {
    mainCategoryLocalizations: [],
  },
  reducers: {
    setMainCategoryLocalization: (state, action) => {
      state.mainCategoryLocalization = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMainCategoryLocalization } =
  mainCategoryLocalizationSlice.actions;

export default mainCategoryLocalizationSlice.reducer;
