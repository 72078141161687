import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { Tooltip } from "primereact/tooltip";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import NotificationHelper from "../../helper/NotificationHelper";
import {
  ImageUploadLocalization,
  DeleteImage,
  GetLocalizedImagesByImageId,
} from "../../service/imageService";
import Confirm from "../Core/Confirm";
import GemGastroSpinner from "../Core/GemGastroSpinner";

function ImageLocalization({ visible, setVisible, imageId }) {
  //image useState
  const [files, setFiles] = useState([]);
  const [updateFiles, setupdateFiles] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const dt = useRef(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [componentLoading, setComponentLoading] = useState(false);
  const [imageList, setImageList] = useState(false);
  const [mainImage, setMainImage] = useState(undefined);
  const [selectedLocalization, setSelectedLocalization] = useState(undefined);

  useEffect(() => {
    if (imageId)
      imagesReload();
  }, [imageId]);

  //#region FileUpload'ın 3 tane butonun fonksiyonları
  const onRemoveUpdate = (e) => {
    //	setupdateFiles(files.filter((x) => x != e.file));
    setupdateFiles([]);
    NotificationHelper.Success("Fotoğraf Kaldırıldı");
  };
  const onClearUpdate = () => {
    setupdateFiles([]);
    NotificationHelper.Success("Fotoğraflar silindi");
  };
  const onUploadUpdate = (e) => {
    setupdateFiles(e.files);
    NotificationHelper.Success("Dosyalar alındı lütfen kaydetmeyi unutmayın");
  };
  //#endregion

  //#region image reload
  const imagesReload = async () => {

    setComponentLoading(true);

    GetLocalizedImagesByImageId(imageId)
      .then((response) => {
        if (response["is-success"]) {
          const reloadedImages = response.lookup.localizedImages;
          setMainImage(response.data);
          setImageList(reloadedImages);
        } else {
          NotificationHelper.Error(response.userMessage);
        }
      })
      .catch((err) => {
        NotificationHelper.Error("Bir hata meydana geldi.");
      })
      .finally(() => {
        setComponentLoading(false);
      });
  };
  //#endregion

  //#region CreateImage
  const onUploadUpdateSave = async () => {
    setComponentLoading(true);
 
    const data = new FormData();
    updateFiles.map((img, i) => {
      data.append("data.FormFileImages", img);
    }); 
    data.append("data.ImageId", imageId);
    data.append("data.LocalizationId", 1);

    ImageUploadLocalization(data)
      .then((response) => {
        if (response["is-success"]) {
          NotificationHelper.Success("Fotoğraf başarıyla oluşturuldu.");
          imagesReload();
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi"
          );
        }
      })
      .catch((err) => {
        NotificationHelper.Error("Beklenmedik bir hata oluştu.");
      })
      .finally(() => {
        setComponentLoading(false);
      });
  };

  //#endregion

  //#region delete image
  const deleteSelectedImage = async () => {
    setComponentLoading(true);
    if (selectedImage) {
      var arr = [];
      arr.push(selectedImage?.id);
      DeleteImage(arr).then((deleteResponse) => {
        if (deleteResponse["is-success"]) {
          NotificationHelper.Success("Fotoğraf başarıyla silindi.");
          imagesReload();
        } else {
          NotificationHelper.Error(deleteResponse?.userMessage);
        }
      });
      setOpenConfirmDialog(false);
    } else {
      NotificationHelper.Error("Lütfen öncelikle fotoğraf seçiniz.");
    }
  };
  //#endregion

  //#region  footers
  const imageFooter = (
    <React.Fragment>
      <Button
        label='İptal Et'
        icon='pi pi-times'
        className='p-button-text'
        onClick={() => setVisible(false)}
      />
      <Button
        label='Kaydet ve Çık'
        icon='pi pi-check'
        className='p-button-text'
        disabled={updateFiles < 1}
        onClick={() => onUploadUpdateSave()}
      />
    </React.Fragment>
  );

  //#endregion

  //#region BodyTemplates
  const imageListBodyTemplate = (rowData) => {
    return (
      <img
        src={`${process.env.REACT_APP_API_IMG}/${rowData?.url}`}
        className='product-image'
        style={{ width: "110px" }}
      />
    );
  };
  const actionBodyTemplateImage = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon='pi pi-trash'
          className='p-button-rounded p-button-warning'
          onClick={() => confirmDeleteImage(rowData)}
        />
      </React.Fragment>
    );
  };
  //#endregion

  //#region  confirm delete
  const confirmDeleteImage = (rowData) => {
    setSelectedImage(rowData);
    setOpenConfirmDialog(true);
  };
  //#endregion

  return (
    <div>
      {componentLoading ? (
        <GemGastroSpinner isLogin={false} />
      ) : (
        <>
          <Confirm
            visible={openConfirmDialog}
            setVisible={(p) => setOpenConfirmDialog(p)}
            callBack={() => deleteSelectedImage()}
            confirmMessage='Seçilen fotoğraflar silenecek emin misiniz ?'
          />
          <Dialog
            header={"Dile Göre Özelleştirilmiş Fotoğrafı Düzenle"}
            visible={visible}
            onHide={() => setVisible(false)}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw" }}
            footer={imageFooter}>
            <div>
              <Tooltip
                target='.custom-choose-btn'
                content='Choose'
                position='bottom'
              />
              <Tooltip
                target='.custom-upload-btn'
                content='Upload'
                position='bottom'
              />
              <Tooltip
                target='.custom-cancel-btn'
                content='Clear'
                position='bottom'
              />

              <div className='card'>
                <>
                  <h1>Ana Fotoğraf</h1>
                  {imageListBodyTemplate(mainImage)}
                  <hr />
                  <FileUpload
                    name='Image'
                    url='./upload.php'
                    //   value={selectedProduct?.productImage}
                    customUpload
                    onRemove={onRemoveUpdate}
                    onClear={onClearUpdate}
                    uploadHandler={onUploadUpdate}
                    single
                    maxFileSize={1000000}
                    emptyTemplate={
                      <p className='p-m-0'>Fotoğrafı sürükle bırak</p>
                    }
                  />
                  <hr />
                </>
                {imageList?.length && (
                  <DataTable
                    ref={dt}
                    value={imageList}
                    selection={selectedImage}
                    onSelectionChange={(e) => {
                      setSelectedImage(e.value);
                    }}
                    dataKey='id'
                    paginator
                    rows={5}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                    currentPageReportTemplate='Showing {first} to {last} of {totalRecords} products'
                    header={
                      <div className='table-header'>
                        <h5 className='p-m-0'>Eski Fotoğraf</h5>
                      </div>
                    }>
                    <Column field='id' header='Fotoğraf Kodu' sortable></Column>
                    <Column
                      field='url'
                      header='Fotoğraf'
                      body={imageListBodyTemplate}></Column>
                    <Column body={actionBodyTemplateImage}></Column>
                  </DataTable>
                )}
              </div>
            </div>
          </Dialog>
        </>
      )}
    </div>
  );
}

export default ImageLocalization;
