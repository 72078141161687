import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";

function GemGastroSpinner({ isLogin }) {
  return (
    <>
      {isLogin ? (
        <div className={`gemSpinner`}>
          <div className={`gemSpin`}>
            <p className='my-3'>
              <stong>Lütfen bekleyin yükleniyor...</stong>
            </p>
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth='8'
              fill='#EEEEEE'
              animationDuration='.5s'
            />
          </div>
        </div>
      ) : (
        <div className={`gemSpinnerForm`}>
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth='8'
            fill='#EEEEEE'
            animationDuration='.5s'
          />
        </div>
      )}
    </>
  );
}

export default GemGastroSpinner;
