import { createSlice } from "@reduxjs/toolkit";

export const categorySlice = createSlice({
  name: "category",
  initialState: {
    categories: [],
    selectedCategory: {},
    loading: false,
  },
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setCategoryImages: (state, action) => {
      const request = action.payload;
      state.categories = state.categories.map((category) => {
        if (category.id == request.id) {
          category.categoryImage = request.imageList[0];
        }
        return category;
      });
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    onChangeSelectedCategory: (state, action) => {
      const changedValue = action.payload;
      let model = state.selectedCategory;
      if (!model) model = {};
      model[changedValue.name] = changedValue.value;
      state.selectedCategory = model;
    },
    onChangeSelectedCategoryLocalization: (state, action) => {
      const request = action.payload;
      const selectedCategory = state.selectedCategory;
      const localizationId = request.localizationId;
      const name = request.name;
      const value = request.value;
      if (selectedCategory) {
        const changedcategoryLocalization =
          selectedCategory.categoryLocalizations?.find(
            (x) => x.localizationId == localizationId
          );
        if (changedcategoryLocalization) {
          changedcategoryLocalization[name] = value;
          selectedCategory.categoryLocalizations =
            selectedCategory.categoryLocalizations.map((item) => {
              if (
                changedcategoryLocalization.localizationId ==
                item.localizationId
              )
                return changedcategoryLocalization;
              return item;
            });
          state.selectedCategory = selectedCategory;
        } else {
          let models = selectedCategory.categoryLocalizations || [];
          let model = {};
          model[`${name}`] = value;
          model.localizationId = localizationId;
          models.push(model);
          selectedCategory.categoryLocalizations = models;
          state.selectedCategory = selectedCategory;
        }
      } else {
        let categoryLocalizationModel = [];
        let model = {};
        model[`${name}`] = value;
        model.localizationId = localizationId;
        categoryLocalizationModel.push(model);

        let categoryModel = {};
        categoryModel["categoryLocalizations"] = categoryLocalizationModel;
        state.selectedCategory = categoryModel;
      }
    },
    deleteCategories: (state, action) => {
      const categoryIds = action.payload;
      let model = state.categories;
      state.categories = model.filter((x) => !categoryIds.includes(x.id));
    },

    updateCategory: (state, action) => {
      const updatedCategory = state.selectedCategory;
      state.categories = state.categories.map((i) => {
        if (i.id == updatedCategory.id) return updatedCategory;
        return i;
      });
    },
    createCategory: (state, action) => {
      const createdCategory = state.selectedCategory;
      let models = state.categories;

      createdCategory.id = action.payload;
      createdCategory.categoryLocalizations =
        createdCategory.categoryLocalizations.map((i) => {
          i.categoryId = action.payload;
          return i;
        });
      models.unshift(createdCategory);
      state.categories = models;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCategories,
  setCategoryImages,
  setSelectedCategory,
  createCategory,
  updateCategory,
  deleteCategories,
  onChangeSelectedCategoryLocalization,
  onChangeSelectedCategory,
  setCategoryDetail,
  setLoading,
} = categorySlice.actions;

export default categorySlice.reducer;
