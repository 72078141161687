import "./App.css";
import { Route, Switch, useHistory } from "react-router-dom";
import Login from "./page/Login";
import Dashboard from "./page/Dashboard";
import SliderSettings from "./page/SliderSettings";
import Product from "./page/Product";
import Category from "./page/Category";
import MainCategory from "./page/MainCategory";
import Order from "./page/Order";
import NewOrder from "./page/NewOrder";
import OfferManagement from "./page/OfferManagement";
import Return from "./page/Return";
import SendMail from "./page/SendMail";
import UploadMailTemp from "./page/UploadMailTemp";
import ProductSettings from "./page/ProductSettings";
import AboutUsImagesSettings from "./page/AboutUsImagesSettings";
import Cargo from "./page/Cargo";
import CompanyInformation from "./page/CompanyInformation";
import PaymentServiceSettings from "./page/PaymentServiceSettings";
import Bank from "./page/Bank";
import { ToastContainer } from "react-toastify";
import Main from "./components/Core/Layout/Main";
import CustomerManagement from "./page/CustomerManagement";
import PaymentAfterOrder from "./page/PaymentAfterOrder";
import Confirm from './components/Core/Confirm';
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeletedProduct from "./page/DeletedProduct";
import ClientLocalizationManager from "./page/ClientLocalizationManager";
import ErrorMessageLocalization from "./page/ErrorMessageLocalization";
import LocalizationSettings from "./page/LocalizationSettings";
import Settings from "./page/Settings";
import { AuthProvider } from "./context/AuthContext";

function App() {
  // const { logout } = useContext(AuthContext);
  // const visibleConfirm = useSelector(state => state.inventory.visibleLoginConfirm);
  // useDispatch(set)
  return (
    <div className='App'>
      {/* <Confirm key={"login-error-confirm"} callBack={() => logout()} confirmMessage="You do not have authorization.Please login again." setVisible={ ()=> } /> */}
      <ToastContainer
        position='top-center'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Switch>
        <Route path='/login' component={Login} />
        <AuthProvider>
          <Main>
            <Route path='/' exact component={Dashboard} />
            <Route path='/slayt-ayarlari' exact component={SliderSettings} />
            <Route path='/urunler' exact component={Product} />
            <Route path='/kategoriler' exact component={Category} />
            <Route path='/ana-kategoriler' exact component={MainCategory} />
            <Route path='/siparisler' exact component={Order} />
            <Route path='/yeni-siparisler' exact component={NewOrder} />
            <Route path='/teklif-islemleri' exact component={OfferManagement} />
            <Route path='/iadeler' exact component={Return} />
            <Route path='/musteriler' exact component={CustomerManagement} />
            <Route path='/mail-gonder' exact component={SendMail} />
            <Route path='/sablon-yukle' exact component={UploadMailTemp} />
            <Route path='/dil-ayarlari' exact component={ClientLocalizationManager} />
            <Route path='/hata-mesajlari-ayarlari' exact component={ErrorMessageLocalization} />
            <Route path='/dil-yonetimi' exact component={LocalizationSettings} />
            <Route path='/genel-ayarlar' exact component={Settings} />

            <Route
              path='/urun-slayt-ayarlari'
              exact
              component={ProductSettings}
            />
            <Route
              path='/ubers-uns-fotograflar'
              exact
              component={AboutUsImagesSettings}
            />
            <Route path='/kargolar' exact component={Cargo} />
            <Route
              path='/sirket-bilgileri'
              exact
              component={CompanyInformation}
            />
            <Route
              path='/ödeme-methodlari'
              exact
              component={PaymentServiceSettings}
            />
            <Route path='/banka-yonetimi' exact component={Bank} />
            <Route path='/ödeme-sonrasi' exact component={PaymentAfterOrder} />
            <Route path='/silinmis-urunler' exact component={DeletedProduct} />

          </Main>
        </AuthProvider>

      </Switch>
    </div>
  );
}

export default App;
