import { createSlice } from "@reduxjs/toolkit";

export const inventorySlice = createSlice({
  name: "inventory",
  initialState: {
    categoriesOrders: [],
    mainCategoriesOrders: [],
    canbeChangeOrderStatus: [],
    "order-history-log-type-with-label": [],
    loading: false,
    visibleLoginConfirm:false,
  },
  reducers: {
    setCategoriesOrders: (state, action) => {
      state.categoriesOrders = action.payload;
    },
    setMainCategoriesOrders: (state, action) => {
      state.mainCategoriesOrders = action.payload;
    },
    setCanBeChangeOrderStatusWithLabel: (state, action) => {
      state.canbeChangeOrderStatus = action.payload;
    },
    setOrderHistoryLogTypeWithLabel: (state, action) => {
      state["order-history-log-type-with-label"] = action.payload;
    },
    setInventoryLoading: (state, action) => {
      state.loading = action.payload;
    },
    setVisibleLoginConfirm:(state,action) => {
      state.visibleLoginConfirm = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setCategoriesOrders,
  setMainCategoriesOrders,
  setCanBeChangeOrderStatusWithLabel,
  setOrderHistoryLogTypeWithLabel,
  setInventoryLoading,
  setVisibleLoginConfirm
} = inventorySlice.actions;

export default inventorySlice.reducer;
