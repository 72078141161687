import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onChangeSelectedProductLocalization } from "../../storage/product/productSlice";

function CreateOrUpdateProductLocalization({ visible, setVisible }) {
  const selectedProduct = useSelector((state) => state.product.selectedProduct);
  const localizations = useSelector(
    (state) => state.localization.localizations
  );
  const dispatch = useDispatch();
  const onInputChange = (name, value, localizationId) => {
    dispatch(
      onChangeSelectedProductLocalization({ name, value, localizationId })
    );
  };

  return (
    <Dialog
      visible={visible}
      style={{ width: "450px" }}
      header={`${
        selectedProduct?.productLocalizations !== null
          ? `Ürün Bilgileri Güncelleme`
          : `Ürün Bilgileri Oluşturma`
      }`}
      modal
      onHide={() => setVisible(false)}>
      {localizations?.map((localization, i) => {
        const productLocalization = selectedProduct?.productLocalizations?.find(
          (x) => x.localizationId == localization.id
        );
        return (
          <>
            <div className='p-field'>
              <span>
                {i + 1}.Dil - {localization.language} -
              </span>
            </div>
            <Divider />
            <div
              className='p-field w-100'
              style={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor='productName'>Adı</label>
              <InputText
                name='productName'
                value={productLocalization?.productName}
                onChange={(e) =>
                  onInputChange(e.target.name, e.target.value, localization.id)
                }
                required
                autoFocus
              />
            </div>
            <div
              className='p-field'
              style={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor='smallDescription'>Kısa Açıklama</label>
              <InputText
                name='smallDescription'
                value={productLocalization?.smallDescription}
                onChange={(e) =>
                  onInputChange(e.target.name, e.target.value, localization.id)
                }
                required
                autoFocus
              />
            </div>
            <div
              className='p-field'
              style={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor='description'>Uzun Açıklama</label>
              <InputText
                name='description'
                value={productLocalization?.description}
                onChange={(e) =>
                  onInputChange(e.target.name, e.target.value, localization.id)
                }
                required
                autoFocus
              />
            </div>
            {/* <div
              className='p-field'
              style={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor='unitPrice'>Fiyat</label>
              <InputNumber
                id='unitPriceGe'
                name='unitPrice'
                value={productLocalization?.unitPrice}
                // onChange={(e) =>
                //   onInputChange(e.target.name, e.target.value, localization.id)
                // }
                onValueChange={(e) =>
                  onInputChange("unitPrice", e.target.value, localization.id)
                }
                mode='currency'
                currency='EUR'
                locale='de-DE'
              />
            </div>
            <div
              className='p-field'
              style={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor='unitPrice'>İndirimli Fiyat</label>
              <InputNumber
                id='unitPriceGe'
                name='promoUnitPrice'
                value={productLocalization?.promoUnitPrice}
                // onChange={(e) =>
                //   onInputChange(e.target.name, e.target.value, localization.id)
                // }
                onValueChange={(e) =>
                  onInputChange(
                    "promoUnitPrice",
                    e.target.value,
                    localization.id
                  )
                }
                mode='currency'
                currency='EUR'
                locale='de-DE'
              />
            </div> */}
          </>
        );
      })}
    </Dialog>
  );
}

export default CreateOrUpdateProductLocalization;
