import { createSlice } from "@reduxjs/toolkit";

export const mainCategorySlice = createSlice({
  name: "mainCategory",
  initialState: {
    mainCategories: [],
    loading: false,
  },
  reducers: {
    setMainCategories: (state, action) => {
      state.mainCategories = action.payload;
    },
    setMainCategoryImages: (state, action) => {
      const request = action.payload;
      state.mainCategories = state.mainCategories.map((mainCategory) => {
        if (mainCategory.id == request.id) {
          mainCategory.mainCategoryImage = request.imageList[0];
        }
        return mainCategory;
      });
    },
    setSelectedMainCategory: (state, action) => {
      state.selectedMainCategory = action.payload;
    },
    onChangeSelectedMainCategory: (state, action) => {
      const changedValue = action.payload;
      let model = state.selectedMainCategory;
      if (!model) model = {};
      model[changedValue.name] = changedValue.value;
      state.selectedMainCategory = model;
    },
    onChangeSelectedMainCategoryLocalization: (state, action) => {
      const request = action.payload;
      const selectedMainCategory = state.selectedMainCategory;
      const localizationId = request.localizationId;
      const name = request.name;
      const value = request.value;
      if (selectedMainCategory) {
        const changedmainCategoryLocalization =
          selectedMainCategory.mainCategoryLocalizations?.find(
            (x) => x.localizationId == localizationId
          );
        if (changedmainCategoryLocalization) {
          changedmainCategoryLocalization[name] = value;
          selectedMainCategory.mainCategoryLocalizations =
            selectedMainCategory.mainCategoryLocalizations.map((item) => {
              if (
                changedmainCategoryLocalization.localizationId ==
                item.localizationId
              )
                return changedmainCategoryLocalization;
              return item;
            });
          state.selectedMainCategory = selectedMainCategory;
        } else {
          let models = selectedMainCategory.mainCategoryLocalizations || [];
          let model = {};
          model[`${name}`] = value;
          model.localizationId = localizationId;
          models.push(model);
          selectedMainCategory.mainCategoryLocalizations = models;
          state.selectedMainCategory = selectedMainCategory;
        }
      } else {
        let mainCategoryLocalizationModel = [];
        let model = {};
        model[`${name}`] = value;
        model.localizationId = localizationId;
        mainCategoryLocalizationModel.push(model);

        let mainCategoryModel = {};
        mainCategoryModel["mainCategoryLocalizations"] =
          mainCategoryLocalizationModel;
        state.selectedMainCategory = mainCategoryModel;
      }
    },
    deleteMainCategories: (state, action) => {
      const mainCategoryIds = action.payload;
      let model = state.mainCategories;
      state.mainCategories = model.filter(
        (x) => !mainCategoryIds.includes(x.id)
      );
    },

    updateMainCategory: (state, action) => {
      const updatedMainCategory = state.selectedMainCategory;
      state.mainCategories = state.mainCategories.map((i) => {
        if (i.id == updatedMainCategory.id) return updatedMainCategory;
        return i;
      });
    },
    createMainCategory: (state, action) => {
      const createdMainCategory = state.selectedMainCategory;
      let models = state.mainCategories;
      const id = action.payload;

      createdMainCategory.id = id;
      createdMainCategory.mainCategoryLocalizations =
        createdMainCategory.mainCategoryLocalizations.map((i) => {
          i.mainCategoryId = id;
          return i;
        });
      models.unshift(createdMainCategory);
      state.mainCategories = models;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setMainCategories,
  setMainCategoryImages,
  setSelectedMainCategory,
  updateMainCategory,
  deleteMainCategories,
  onChangeSelectedMainCategoryLocalization,
  onChangeSelectedMainCategory,
  setMainCategoryDetail,
  createMainCategory,
  setLoading,
} = mainCategorySlice.actions;

export default mainCategorySlice.reducer;
