import { configureStore } from "@reduxjs/toolkit";
import inventoryReducer from "./inventory/inventorySlice";
import cargoReducer from "./cargo/cargoSlice";
import categoryReducer from "./category/categorySlice";
import categoryLocalizationReducer from "./categoryLocalization/categoryLocalizationSlice";
import customerReducer from "./customer/customerSlice";
import localizationReducer from "./localization/localizationSlice";
import mainCategoryReducer from "./mainCategory/mainCategorySlice";
import mainCategoryLocalizationReducer from "./mainCategoryLocalization/mainCategoryLocalizationSlice";
import orderReducer from "./order/orderSlice";
import productReducer from "./product/productSlice";
import productLocalizationReducer from "./productLocalization/productLocalizationSlice";
import settingReducer from "./setting/settingSlice";
import supplierReducer from "./supplier/supplierSlice";
export default configureStore({
  reducer: {
    inventory: inventoryReducer,
    cargo: cargoReducer,
    category: categoryReducer,
    categoryLocalization: categoryLocalizationReducer,
    customer: customerReducer,
    localization: localizationReducer,
    mainCategory: mainCategoryReducer,
    mainCategoryLocalization: mainCategoryLocalizationReducer,
    order: orderReducer,
    product: productReducer,
    productLocalization: productLocalizationReducer,
    setting: settingReducer,
    supplier: supplierReducer,
  },
});
