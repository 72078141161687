import { Dialog } from "primereact/dialog";
import React, { useState, useEffect } from "react";
import NotificationHelper from "../../helper/NotificationHelper";
import { CreateUpdateBank } from "../../service/inventoryService";
import GemGastroSpinner from "../Core/GemGastroSpinner";

function CreateOrUpdateBank({
  bank,
  setBank,
  createOrUpdateDialog,
  setCreateOrUpdateDialog,
  reload,
}) {
  const [componantLoading, setComponantLoading] = useState(false);
  const [iban, setIban] = useState("");
  const [name, setName] = useState("");
  const [reciverName, setReciverName] = useState("");
  useEffect(() => {
    setIban(bank?.iban || "");
    setName(bank?.name || "");
    setReciverName(bank?.reciverName || "");
  }, [bank]);

  const createOrUpdateBank = async () => {
    setComponantLoading(true);

    const newBank = {
      id: bank?.id,
      name,
      reciverName,
      iban,
    };
    CreateUpdateBank(newBank)
      .then((response) => {
        if (response["is-success"]) {
          NotificationHelper.Success("Banka başarıyla kaydedildi.");
          reload();
          setComponantLoading(false);
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi"
          );
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir hata meydana geldi");
        setComponantLoading(false);
      });
  };

  const onChangeBankInputs = (name, value) => {
    const newBank = bank;
    if (name == "name") {
      newBank.name = value;
    } else if (name == "reciverName") {
      newBank.reciverName = value;
    } else if (name == "iban") {
      newBank.iban = value;
    }
    setBank(newBank);
  };
  const createOrUpdateDialogFooter = () => {
    return (
      <button className='btn btn-success' onClick={() => createOrUpdateBank()}>
        {" "}
        Kaydet{" "}
      </button>
    );
  };

  return (
    <Dialog
      visible={createOrUpdateDialog}
      style={{ width: "450px" }}
      header={`${bank?.id !== null ? `Banka Güncelleme` : `Banka Oluşturma`}`}
      modal
      className='p-fluid'
      footer={createOrUpdateDialogFooter}
      onHide={() => setCreateOrUpdateDialog(false)}>
      {componantLoading ? (
        <GemGastroSpinner isLogin={false} />
      ) : (
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <div className='card'>
                <div className='card-body'>
                  <div className='form-group'>
                    <label htmlFor='name' className='form-label'>
                      Banka Adı
                    </label>
                    <input
                      id='name'
                      name='name'
                      value={name}
                      className='form-control'
                      onChange={(e) => {
                        setName(e.target.value);
                        onChangeBankInputs(e.target.name, e.target.value);
                      }}
                    />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='reciverName' className='form-label'>
                      Hesap Sahibinin Adı Soyadı
                    </label>
                    <input
                      id='reciverName'
                      name='reciverName'
                      value={reciverName}
                      className='form-control'
                      onChange={(e) => {
                        setReciverName(e.target.value);
                        onChangeBankInputs(e.target.name, e.target.value);
                      }}
                    />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='iban' className='form-label'>
                      Iban Numarası
                    </label>
                    <input
                      id='iban'
                      name='iban'
                      value={iban}
                      className='form-control'
                      onChange={(e) => {
                        setIban(e.target.value);
                        onChangeBankInputs(e.target.name, e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
}

export default CreateOrUpdateBank;
