import React, { Component } from "react";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Ripple } from "primereact/ripple";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ProductHelper from "../helper/ProductHelper";
import CategoryHelper from "../helper/CategoryHelper";
import {
  GetProductDetail,
  TransferCategoryProduct,
} from "../service/productService";
import CreateOrUpdateProduct from "../components/Product/CreateOrUpdateProduct";
import { Toolbar } from "primereact/toolbar";
import { Divider } from "primereact/divider";

import {
  createNewTechnicalInfo,
  onChangeSelectedProducts,
  setLoading,
  setProductDetail,
  setProducts,
  setSelectedProduct,
  setSelectedProducts,
} from "../storage/product/productSlice";
import Confirm from "../components/Core/Confirm";
import { AdminGetAllProduct } from "../service/inventoryService";
import NotificationHelper from "../helper/NotificationHelper";
import GetAllProducts from "../components/Product/GetAllProducts";
import ProductCategoryTransfer from "../components/Product/ProductCategoryTransfer";
import DeleteProduct from "../components/Product/DeleteProduct";
import Supplier from "../components/Supplier";
export class Product extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedProducts: null,
      first1: 0,
      globalFilter: null,
      currentPage: 1,
      pageInputTooltip: "Press 'Enter' key to go to this page.",
      rows: 10,
      dt: React.createRef(),
      editModel: null,
      openCreateOrUpdateProduct: false,
      openConfirmDialog: false,
      confirmDialogMessage: "Seçili ürünleri silmek istediğinize emin misiniz?",
      selectedTransferCategory: null,
      openBrandDialog: false,
      pureData: [],
      data: []
    };
  }

  componentDidMount() {
    if (!this.props.products?.length) {
      setLoading(true);
      AdminGetAllProduct()
        .then((response) => {
          if (response["is-success"]) {
            setProducts(response.data);
            this.setState({ pureData: response.data, data: response.data});
          } else {
            NotificationHelper.Error(
              response.userMessage || "Bir hata meydana geldi."
            );
          }
        })
        .finally(() => setLoading(false));
    } else {
      this.setState({ pureData: this.props.products, data: this.props.products });
    }
  }
  
  editProduct = (product) => {
    this.props.setLoading(true);
    GetProductDetail(product.id).then((response) => {
      this.props.setSelectedProduct(product);
      this.props.setProductDetail(response.lookup);
      if (!response.lookup.technicalInfos.length)
        this.props.createNewTech(this.props.localizations);
      this.setState({ editModel: this.props.selectedProduct }, () => {
        this.setState({ openCreateOrUpdateProduct: true });
        this.props.setLoading(false);
      });
    });
  };

  onPageInputKeyDown = (event, options) => {
    if (event.key === "Enter") {
      const page = parseInt(this.state.currentPage);
      if (page < 0 || page > options.totalPages) {
        this.setState({
          pageInputTooltip: `Value must be between 1 and ${options.totalPages}.`,
        });
      } else {
        const first = this.state.currentPage ? options.rows * (page - 1) : 0;

        this.setState({ first1: first });

        this.setState({
          pageInputTooltip: "Press 'Enter' key to go to this page.",
        });
      }
    }
  };

  onCustomPage1 = (event) => {
    this.setState({ first1: event.first });
    this.setState({ rows: event.rows });
    this.setState({ currentPage: event.page + 1 });
  };

  gridHeader = (
    <div className='table-header'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-2'>
            <h5 className='p-m-0'>Ürün Kodu</h5>
            <span className='p-input-icon-left'>
              <i className='pi pi-search' />
              <InputText
                type='search'
                onChange={(e) => {
                  const newData = this.state.pureData.filter(x => x.productCode.toLowerCase().includes(e.target.value.toLowerCase()));
                  this.setState({ data: newData });
                }}
                placeholder='Search...'
              />
            </span>
          </div>
          <div className='col-2'>
            <h5 className='p-m-0'>Ürün Adı</h5>
            <span className='p-input-icon-left'>
              <i className='pi pi-search' />
              <InputText
                type='search'
                onChange={(e) => {
                  const newData = this.state.pureData.filter(x => x.productLocalizations[0].productName.toLowerCase()
                    .includes(e.target.value.toLowerCase()));
                  this.setState({ data: newData });
                }}
                placeholder='Search...'
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  onPageInputChange = (event) => {
    this.setState({ currentPage: event.target.value });
  };

  leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className='container'>
          <div className='row'>
            <div className='col-12 d-flex '>
              <div>
                <Button
                  label='Ürün Ekle'
                  icon='pi pi-plus'
                  className='p-button-success p-mr-2'
                  onClick={() => {
                    this.props.setSelectedProduct({});
                    this.props.createNewTech(this.props.localizations);
                    this.setState({ openCreateOrUpdateProduct: true });
                  }}
                />
                <DeleteProduct
                  selectedProducts={this.state.selectedProducts}
                  setSelectedProducts={(p) =>
                    this.setState({ selectedProducts: p })
                  }
                  setComponentLoading={(p) => this.props.setLoading(p)}
                />
                <GetAllProducts
                  setComponentLoading={(p) => this.props.setLoading(p)}
                  setPureData={(p) => this.setState({ pureData: p, data: p })}


                />
                <Button
                  label='Markaları Yönet'
                  icon='pi pi-pencil'
                  className='p-button-success p-mr-2'
                  onClick={() => this.setState({ openBrandDialog: true })}
                />
              </div>
              <Divider layout='vertical' />
              <ProductCategoryTransfer
                selectedProducts={this.state.selectedProducts}
                setSelectedProducts={(p) =>
                  this.setState({ selectedProducts: p })
                }
                setComponentLoading={(p) => this.props.setLoading(p)}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  render() {
    const paginatorTemplate = {
      layout:
        "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport",
      PrevPageLink: (options) => {
        return (
          <button
            type='button'
            className={options.className}
            onClick={options.onClick}
            disabled={options.disabled}>
            <span className='p-p-3'>Önceki</span>
            <Ripple />
          </button>
        );
      },
      NextPageLink: (options) => {
        return (
          <button
            type='button'
            className={options.className}
            onClick={options.onClick}
            disabled={options.disabled}>
            <span className='p-p-3'>Sonraki</span>
            <Ripple />
          </button>
        );
      },
      PageLinks: (options) => {
        if (
          (options.view.startPage === options.page &&
            options.view.startPage !== 0) ||
          (options.view.endPage === options.page &&
            options.page + 1 !== options.totalPages)
        ) {
          const className = classNames(options.className, {
            "p-disabled": true,
          });

          return (
            <span className={className} style={{ userSelect: "none" }}>
              ...
            </span>
          );
        }

        return (
          <button
            type='button'
            className={options.className}
            onClick={options.onClick}>
            {options.page + 1}
            <Ripple />
          </button>
        );
      },
      RowsPerPageDropdown: (options) => {
        const dropdownOptions = [
          { label: 10, value: 10 },
          { label: 20, value: 20 },
          { label: 30, value: 30 },
          { label: 40, value: 40 },
          { label: 50, value: 50 },
          { label: 100, value: 100 },
          { label: 200, value: 200 },
          { label: 300, value: 300 },
        ];

        return (
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
            appendTo={document.body}
          />
        );
      },
      CurrentPageReport: (options) => {
        return (
          <span
            className='p-mx-3'
            style={{ color: "var(--text-color)", userSelect: "none" }}>
            Go to{" "}
            <InputText
              size='2'
              className='p-ml-1'
              value={this.state.currentPage}
              tooltip={this.state.pageInputTooltip}
              onKeyDown={(e) => this.onPageInputKeyDown(e, options)}
              onChange={this.onPageInputChange}
            />
          </span>
        );
      },
    };

    return (
      <div className='datatable-crud-demo'>
        <Toolbar
          className='p-mb-4 w-100'
          left={this.leftToolbarTemplate}></Toolbar>
        <DataTable
          ref={this.state.dt}
          value={this.state.data}
          selection={this.state.selectedProducts}
          onSelectionChange={(e) =>
            this.setState({ selectedProducts: e.value })
          }
          dataKey='id'
          paginator
          rows={this.state.rows}
          rowsPerPageOptions={[5, 10, 20, 25, 50]}
          paginatorTemplate={paginatorTemplate}
          first={this.state.first1}
          onPage={this.onCustomPage1}
          globalFilter={this.state.globalFilter}
          emptyMessage='Ürün bulunamadı.'
          header={this.gridHeader}
          loading={this.props.loading}>
          <Column
            selectionMode='multiple'
            headerStyle={{ width: "3rem" }}></Column>
          <Column field='productCode' header='Ürün Kodu' sortable></Column>
          <Column
            body={ProductHelper.productNameTemplate}
            header='Ürün Adı'
            sortable></Column>
          <Column
            field='brandName'
            body={(p) =>
              ProductHelper.productBrancdNameTemplate(p, this.props.suppliers)
            }
            header='Marka'
            sortable></Column>
          <Column
            field='unitPrice'
            header='Ürün Fiyatı'
            body={ProductHelper.priceBodyTemplate}
            sortable></Column>
          <Column field='unitStock' header='Ürün Stok Adeti' sortable></Column>
          <Column
            field='categoryId'
            header='Ürünün Kategorisi'
            body={(p) =>
              ProductHelper.categoryBodyTempalte(p, this.props.categories)
            }
            sortable></Column>
          {/* <Column field="rating" header="Reviews" body={ratingBodyTemplate} sortable></Column> */}
          <Column
            field='productImages'
            header='Ürün Resmi'
            body={ProductHelper.imageBodyTemplate}></Column>
          <Column
            field='unitStock'
            header='Durum'
            body={ProductHelper.statusBodyTemplate}
            sortable></Column>
          <Column
            body={(p) =>
              ProductHelper.actionBodyTemplate(p, this.editProduct)
            }></Column>
        </DataTable>

        {this.state.openCreateOrUpdateProduct && (
          <CreateOrUpdateProduct
            visible={this.state.openCreateOrUpdateProduct}
            setVisible={(val) =>
              this.setState({ openCreateOrUpdateProduct: val })
            }
          />
        )}
        {this.state.openBrandDialog && (
          <Supplier
            brandDialog={this.state.openBrandDialog}
            setBrandDialog={(p) => this.setState({ openBrandDialog: p })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.product.products,
    categories: state.category.categories,
    suppliers: state.supplier.suppliers,
    selectedProduct: state.product.selectedProduct,
    localizations: state.localization.localizations,
    inventoryLoading: state.inventory.loading,
    loading: state.product.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedProduct: (product) => dispatch(setSelectedProduct(product)),
    setProductDetail: (lookup) => dispatch(setProductDetail(lookup)),
    setProducts: (products) => dispatch(setProducts(products)),
    createNewTech: (localizations) =>
      dispatch(createNewTechnicalInfo(localizations)),
    setLoading: (val) => dispatch(setLoading(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
