import { createSlice, current } from "@reduxjs/toolkit";
import StringHelper from "../../helper/StringHelper";

export const productSlice = createSlice({
  name: "product",
  initialState: {
    products: [],
    deletedProducts: [],
    selectedProduct: {},
    selectedDeletedProduct: {},
    loading: false,
    deletedLoading: false,
  },
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setSelectedDeletedProduct: (state, action) => {
      state.selectedDeletedProduct = action.payload;
    },
    setDeletedProducts: (state, action) => {
      state.deletedProducts = action.payload;
    },
    setDeletedLoading: (state, action) => {
      state.deletedLoading = action.payload;
    },
     
    setDeletedProductsToProducts: (state, action) => {
      let product = state.selectedDeletedProduct;
      console.log(current(product));
      product.dataStatus = 2;
      state.products.push(product)
      let model = [];
      state.deletedProducts.forEach((deletedProduct) => {
        if (deletedProduct.id != product.id)
          model.push(deletedProduct);
      });
      state.deletedProducts = model;
    },
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
    setProductDetail: (state, action) => {
      let model = state.selectedProduct;
      let lookup = action.payload;
      model.productLocalizations = lookup.productLocalizations;
      model.productTechnicalInfos = lookup.technicalInfos;
      model.subProduct = lookup.accessories;
      state.selectedProduct = model;
    },
    onChangeSelectedProduct: (state, action) => {
      const changedValue = action.payload;
      let model = state.selectedProduct;
      if (!model) model = {};
      model[changedValue.name] = changedValue.value;
      state.selectedProduct = model;
    },
    onChangeSelectedProductLocalization: (state, action) => {
      const request = action.payload;
      const selectedProduct = state.selectedProduct;
      const localizationId = request.localizationId;
      const name = request.name;
      const value = request.value;
      if (selectedProduct) {
        const changedProductLocalization =
          selectedProduct.productLocalizations?.find(
            (x) => x.localizationId == localizationId
          );
        if (changedProductLocalization) {
          changedProductLocalization[name] = value;
          selectedProduct.productLocalizations =
            selectedProduct.productLocalizations.map((item) => {
              if (
                changedProductLocalization.localizationId == item.localizationId
              )
                return changedProductLocalization;
              return item;
            });
          state.selectedProduct = selectedProduct;
        } else {
          let models = selectedProduct.productLocalizations || [];
          let model = {};
          model[`${name}`] = value;
          model.localizationId = localizationId;
          models.push(model);
          selectedProduct.productLocalizations = models;
          state.selectedProduct = selectedProduct;
        }
      } else {
        let productLocalizationModel = [];
        let model = {};
        model[`${name}`] = value;
        model.localizationId = localizationId;
        productLocalizationModel.push(model);

        let productModel = {};
        productModel["productLocalizations"] = productLocalizationModel;
        state.selectedProduct = productModel;
      }
    },
    setProductsCategoryId: (state, action) => {
      const request = action.payload;
      let model = state.products;
      state.products = model.map((product) => {
        if (request.productIds.includes(product.id)) {
          product.categoryId = request.categoryId;
        }
        return product;
      });
    },
    deleteProducts: (state, action) => {
      const productIds = action.payload;
      let products = state.products.filter((x) => !productIds.includes(x.id));
      let deleteds = state.products.filter((x) => productIds.includes(x.id));
      state.products = products;
      deleteds.forEach((deleted)=>{
        state.deletedProducts.push(deleted);
      });
    },
    setProductImages: (state, action) => {
      const request = action.payload;
      state.products = state.products.map((product) => {
        if (product.id == request.id) {
          product.productImage = request.imageList;
        }
        return product;
      });
    },
    updateProduct: (state, action) => {
      const updatedProduct = state.selectedProduct;
      state.products = state.products.map((i) => {
        if (i.id == updatedProduct.id) return updatedProduct;
        return i;
      });
    },
    createProduct: (state, action) => {
      const createdProduct = state.selectedProduct;
      let models = state.products;
      const id = action.payload;

      createdProduct.id = id;
      createdProduct.productLocalizations =
        createdProduct.productLocalizations.map((i) => {
          i.productId = id;
          return i;
        });
      models.unshift(createdProduct);
      state.products = models;
    },
    onChangeSelectedTechnicalInfoLocalization: (state, action) => {
      const value = action.payload.val,
        techLocalization = action.payload.productTechnicalInfoLocalization;

      let selectedProduct = state.selectedProduct,
        changedTechnicalInfo = selectedProduct.productTechnicalInfos.find(
          (x) =>
            (x.id && x.id == techLocalization.productTechnicalInfoId) ||
            x.randomTechnicalInfoId == techLocalization.randomTechnicalInfoId
        );

      if (
        changedTechnicalInfo &&
        (changedTechnicalInfo.id || changedTechnicalInfo.randomTechnicalInfoId)
      ) {
        let changedTechnicalInfoLocalization =
          changedTechnicalInfo.productTechnicalInfoLocalizations.find(
            (x) =>
              (x.id && x.id == techLocalization.id) ||
              (x.randomTechnicalInfoId &&
                x.randomTechnicalInfoId ==
                techLocalization.randomTechnicalInfoId &&
                x.localizationId == techLocalization.localizationId)
          );

        changedTechnicalInfoLocalization.techName = value;

        changedTechnicalInfo.productTechnicalInfoLocalizations =
          changedTechnicalInfo.productTechnicalInfoLocalizations.map(
            (techLoc) => {
              if (
                (techLoc.id &&
                  techLoc.id == changedTechnicalInfoLocalization.id) ||
                (techLoc.randomTechnicalInfoId ==
                  changedTechnicalInfoLocalization.randomTechnicalInfoId &&
                  techLoc.localizationId ==
                  changedTechnicalInfoLocalization.localizationId)
              )
                return changedTechnicalInfoLocalization;
              return techLoc;
            }
          );

        selectedProduct.productTechnicalInfos =
          selectedProduct.productTechnicalInfos.map((techInfo) => {
            if (
              (techInfo.id && techInfo.id == changedTechnicalInfo.id) ||
              (techInfo.randomTechnicalInfoId &&
                techInfo.randomTechnicalInfoId ==
                changedTechnicalInfo.randomTechnicalInfoId)
            )
              return changedTechnicalInfo;
            return techInfo;
          });

        state.selectedProduct = selectedProduct;
      } else {
      }
    },
    onChangeSelectedTechnicalInfoValueLocalization: (state, action) => {
      const key = action.payload.key,
        value = action.payload.val,
        productTechnicalInfoValueLocalization =
          action.payload.productTechnicalInfoValueLocalization;

      let selectedProduct = state.selectedProduct,
        changedTechnicalInfo = selectedProduct.productTechnicalInfos.find((x) =>
          x.technicalValues.find(
            (y) =>
              y.id &&
              y.id ==
              productTechnicalInfoValueLocalization.productTechnicalValueId
          )
        );

      if (!changedTechnicalInfo) {
        changedTechnicalInfo = selectedProduct.productTechnicalInfos.find((x) =>
          x.technicalValues.find(
            (y) =>
              y.randomTechnicalValueId ==
              productTechnicalInfoValueLocalization.randomTechnicalValueId
          )
        );
      }

      if (
        changedTechnicalInfo &&
        (changedTechnicalInfo.id || changedTechnicalInfo.randomTechnicalInfoId)
      ) {
        let changedTechnicalValue = changedTechnicalInfo.technicalValues.find(
          (y) =>
            y.id &&
            y.id ==
            productTechnicalInfoValueLocalization.productTechnicalValueId
        );

        if (!changedTechnicalValue)
          changedTechnicalValue = changedTechnicalInfo.technicalValues.find(
            (y) =>
              y.randomTechnicalValueId ==
              productTechnicalInfoValueLocalization.randomTechnicalValueId
          );

        let changedTechnicalValueLocalization =
          changedTechnicalValue.productTechnicalInfoValueLocalizations.find(
            (x) =>
              (x.id && x.id == productTechnicalInfoValueLocalization.id) ||
              (x.randomTechnicalValueId ==
                productTechnicalInfoValueLocalization.randomTechnicalValueId &&
                x.localizationId ==
                productTechnicalInfoValueLocalization.localizationId)
          );

        changedTechnicalValueLocalization[key] = value;

        changedTechnicalValue.productTechnicalInfoValueLocalizations =
          changedTechnicalValue.productTechnicalInfoValueLocalizations.map(
            (i) => {
              if (
                (i.id && i.id == changedTechnicalValueLocalization.id) ||
                (i.randomTechnicalValueId ==
                  changedTechnicalValueLocalization.randomTechnicalValueId &&
                  i.localizationId ==
                  changedTechnicalValueLocalization.localizationId)
              )
                return changedTechnicalValueLocalization;
              return i;
            }
          );

        changedTechnicalInfo.technicalValues =
          changedTechnicalInfo.technicalValues.map((i) => {
            if (
              (i.id && i.id == changedTechnicalValue.id) ||
              (i.randomTechnicalValueId &&
                i.randomTechnicalValueId ==
                changedTechnicalValue.randomTechnicalValueId)
            )
              return changedTechnicalValue;
            return i;
          });

        selectedProduct.productTechnicalInfos =
          selectedProduct.productTechnicalInfos.map((i) => {
            if (
              (i.id && i.id == changedTechnicalInfo.id) ||
              i.randomTechnicalInfoId ==
              changedTechnicalInfo.randomTechnicalInfoId
            )
              return changedTechnicalInfo;
            return i;
          });

        state.selectedProduct = selectedProduct;
      }
    },
    createNewTechnicalInfo: (state, action) => {
      const localizations = action.payload;

      const randomTechnicalValueId = StringHelper.makeId(10);
      const randomTechnicalInfoId = StringHelper.makeId(10);

      const newTechnicalInfo = {
        technicalValues: [
          {
            productTechnicalInfoValueLocalizations: [],
            randomTechnicalValueId,
            randomTechnicalInfoId,
          },
        ],
        productTechnicalInfoLocalizations: [],
        randomTechnicalInfoId,
      };

      localizations.forEach((localization) => {
        newTechnicalInfo.productTechnicalInfoLocalizations.push({
          techName: "",
          localizationId: localization.id,
          randomTechnicalInfoId,
        });

        newTechnicalInfo.technicalValues[0].productTechnicalInfoValueLocalizations.push(
          {
            localizationId: localization.id,
            parameterName: "",
            value: "",
            randomTechnicalValueId,
          }
        );
      });

      let techInfos = state.selectedProduct?.productTechnicalInfos || [],
        selectedProduct = state.selectedProduct;

      techInfos.push(newTechnicalInfo);

      selectedProduct.productTechnicalInfos = techInfos;

      state.selectedProduct = selectedProduct;
    },
    createNewTechnicalValue: (state, action) => {
      const request = action.payload,
        technicalInfoLocalization = request.productTechInfoLocalization,
        localizations = request.localizations,
        randomTechnicalValueId = StringHelper.makeId(10);

      let technicalValue = null;

      if (technicalInfoLocalization.id) {
        technicalValue = {
          productTechnicalInfoValueLocalizations: [],
          randomTechnicalValueId,
          technicalInfoId: technicalInfoLocalization.productTechnicalValueId,
        };
      } else if (technicalInfoLocalization.randomTechnicalInfoId) {
        technicalValue = {
          productTechnicalInfoValueLocalizations: [],
          randomTechnicalValueId,
          randomTechnicalInfoId:
            technicalInfoLocalization.randomTechnicalInfoId,
        };
      }

      localizations.forEach((localization) => {
        technicalValue.productTechnicalInfoValueLocalizations.push({
          localizationId: localization.id,
          parameterName: "",
          value: "",
          randomTechnicalValueId,
        });
      });

      let selectedProduct = state.selectedProduct,
        techInfos = state.selectedProduct.productTechnicalInfos;

      let changedTechnicalInfo = techInfos.find(
        (x) =>
          (x.id && x.id == technicalInfoLocalization.productTechnicalInfoId) ||
          x.randomTechnicalInfoId ==
          technicalInfoLocalization.randomTechnicalInfoId
      );

      changedTechnicalInfo.technicalValues.push(technicalValue);

      selectedProduct.productTechnicalInfos =
        selectedProduct.productTechnicalInfos.map((i) => {
          if (
            (i.id && i.id == changedTechnicalInfo.id) ||
            (i.randomTechnicalInfoId &&
              i.randomTechnicalInfoId ==
              changedTechnicalInfo.randomTechnicalInfoId)
          )
            return changedTechnicalInfo;
          return i;
        });

      state.selectedProduct = selectedProduct;
    },

    deleteNewTechnical: (state, action) => {
      const technicalRandomId = action.payload;
      let technicalInfos = state.selectedProduct.productTechnicalInfos;
      const deletedTechnicalInfos = technicalInfos.filter(
        (x) => x.randomTechnicalInfoId != technicalRandomId
      );
      state.selectedProduct.productTechnicalInfos = deletedTechnicalInfos;
    },
    deleteNewTechnicalValue: (state, action) => {
      const request = action.payload;
      let valueId = request.valueId;
      let techId = request.techId;
      let model = state.selectedProduct.productTechnicalInfos;
      let technicalInfo = model.find(
        (x) => x.id == techId || x.randomTechnicalInfoId == techId
      );

      let deletedTechnicalValues = technicalInfo.technicalValues.filter(
        (x) => x.randomTechnicalValueId != valueId
      );
      state.selectedProduct.productTechnicalInfos = model.map((x) => {
        if (x.id == techId || x.randomTechnicalInfoId == techId)
          x.technicalValues = deletedTechnicalValues;
        return x;
      });
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setProducts,
  setSelectedProduct,
  setProductDetail,
  onChangeSelectedProduct,
  setProductsCategoryId,
  deleteProducts,
  setProductImages,
  onChangeSelectedProductLocalization,
  updateProduct,
  createProduct,
  onChangeSelectedTechnicalInfoLocalization,
  onChangeSelectedTechnicalInfoValueLocalization,
  createNewTechnicalInfo,
  createNewTechnicalValue,
  deleteNewTechnical,
  deleteNewTechnicalValue,
  setLoading,
  setDeletedLoading,
  setDeletedProducts,
  setDeletedProductsToProducts,
  setSelectedDeletedProduct
} = productSlice.actions;

export default productSlice.reducer;
