import { InputText } from 'primereact/inputtext';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onChangeSelectedErrorMessages, setDiffErrorMessages, setErrorMessages, setSelectedErrorMessages } from '../storage/localization/localizationSlice';
import GemGastroSpinner from '../components/Core/GemGastroSpinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import NotificationHelper from '../helper/NotificationHelper';
import { UpdateErrorMessages } from '../service/inventoryService';

function ErrorMessageLocalization() {
    const errorMessages = useSelector(x => x.localization.errorMessages);
    const localizations = useSelector(x => x.localization.localizations);
    const selectedErrorMessages = useSelector(x => x.localization.selectedErrorMessages);
    const loading = useSelector(x => x.localization.loading);
    const [componentLoading, setComponentLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [tabActiveIndex, setTabActiveIndex] = useState(0)
    const dt = useRef();
    const dispatch = useDispatch();
    const updateErrorMessages = () => {
        setComponentLoading(true);
        UpdateErrorMessages(selectedErrorMessages).then((response) => {
            if (response?.["is-success"]) {
                dispatch(setErrorMessages(response.data));
                NotificationHelper.Success("İşlem Başarılı.");
                setVisible(false);
            } else {
                NotificationHelper.Error(response.userMessage);
            }
        }).catch(()=>{
            NotificationHelper.Error("Bir hata ile karşılaşıldı.")
        }).finally(()=>{
            setComponentLoading(false);
        })
    }
    return (
        loading || componentLoading ? <GemGastroSpinner isLogin={false} /> :
        <div className='w-50 m-auto'>
            <TabView activeIndex={tabActiveIndex}
                onTabChange={(e) => setTabActiveIndex(e.index)}>
                {
                    localizations.map((localization) => {
                        return <TabPanel header={localization.language}>
                            <DataTable
                                ref={dt}
                                value={
                                    errorMessages[localization.language]
                                }
                                dataKey='id'
                                paginator
                                rows={10}
                                rowsPerPageOptions={[5, 10, 25]}
                                scrollable
                                header={"Web Sitesi Dil Ayarları"}
                                loading={loading}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            >
                                <Column
                                    field='recordId'
                                    header='Parametre No'
                                    filter
                                    sortable
                                    filterPlaceholder='Ara'></Column>
                                <Column
                                    header='Değeri'
                                    field="value"
                                    sortable
                                    filter
                                    filterPlaceholder='Ara'></Column>
                                <Column body={(rowData) => <div className="w-100 d-flex justify-content-around align-items-center">
                                    <Button
                                        icon='pi pi-pencil'
                                        className='p-button-rounded p-button-success p-m-0 p-mr-1'
                                        onClick={() => { setVisible(true); dispatch(setSelectedErrorMessages(rowData)) }}
                                    />
                                </div>}></Column>
                            </DataTable>
                        </TabPanel>
                    })
                }
            </TabView >
            <Dialog
                header={"Dil Değerini Düzenle"}
                visible={visible}
                onHide={() => setVisible(false)}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw" }}
            >
                <div className='p-field'>
                    <label>Parametre No : {selectedErrorMessages.recordId}</label>
                    <InputText
                        name={selectedErrorMessages.recordId}
                        value={selectedErrorMessages.value}
                        onChange={(e) => { dispatch(onChangeSelectedErrorMessages(e.target.value)) }
                        }
                    />
                </div>
                <button className="btn btn-success" onClick={() => updateErrorMessages()}> Kaydet</button>
            </Dialog>
        </div>
    )
}

export default ErrorMessageLocalization