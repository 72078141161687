import React, { Component } from "react";
import { connect } from "react-redux";
import GemGastroSpinner from "../Core/GemGastroSpinner";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import CategoryHelper from "../../helper/CategoryHelper";
import classNames from "classnames";
import {
  createCategory,
  onChangeSelectedCategory,
  setCategoryDetail,
  setSelectedCategory,
  updateCategory,
} from "../../storage/category/categorySlice";
import Confirm from "../Core/Confirm";
import CreateOrUpdateCategoryLocalization from "./CreateOrUpdateCategoryLocalization";
import Image from "../Image";
import { CreateCategory, UpdateCategory } from "../../service/categoryService";
import NotificationHelper from "../../helper/NotificationHelper";
import CategoryProducts from "./CategoryProducts";

//props
//editModel=
//setEditMod
//visible={t
//setVisible
//
export class CreateOrUpdateCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      componentLoading: false,
      openCategoryTechnicalDialog: false,
      openImageDialog: false,
      openDiscountDialog: false,
      openCategoryLocalizationDialog: false,
      openCategoryProducts: false,
    };
  }
  saveCategory = () => {
    const updateCategoryOnClient = this.props.updateCategory;
    const createCategoryOnClient = this.props.createCategory;
    const category = this.props.selectedCategory;
    this.setState({ componentLoading: true }, () => {
      //update
      if (this.props.selectedCategory && this.props.selectedCategory?.id) {
        UpdateCategory(this.props.selectedCategory)
          .then((response) => {
            if (response?.["is-success"] && response.data) {
              updateCategoryOnClient(category);
              NotificationHelper.Success("Güncelleme işlemi başarılı");
            } else {
              NotificationHelper.Error(response?.userMessage);
            }
          })
          .catch((err) => {
            NotificationHelper.Error("Beklenmedik bir hata oluştu.");
          }).finally(()=>this.setState({componentLoading:false}));
      } else if (
        this.props.selectedCategory &&
        !this.props.selectedCategory?.id
      ) {
        CreateCategory(this.props.selectedCategory)
          .then((response) => {
            if (response?.["is-success"] && response.data) {
              createCategoryOnClient(response.data);
              NotificationHelper.Success("Kategori oluşturma işlemi başarılı");
            } else {
              NotificationHelper.Error(response?.userMessage);
            }
          })
          .catch((err) => {
            NotificationHelper.Error("Beklenmedik bir hata oluştu.");
          }).finally(()=>this.setState({componentLoading:false}));
      }
    });
  };
  categoryDialogFooter = (
    <React.Fragment>
      <Button
        label='İptal'
        icon='pi pi-times'
        className='p-button-text'
        onClick={() => this.props.setVisible(false)}
      />
      <Button
        label='Kaydet'
        icon='pi pi-check'
        className='p-button-text'
        onClick={this.saveCategory}
      />
    </React.Fragment>
  );

  onInputChange = (name, value) => {
    console.log(value);
    let model = {
      name,
      value,
    };
    this.props.onChangeSelectedCategory(model);
  };
  render() {
    const { componentLoading } = this.state;
    const { setVisible, visible } = this.props;
    return (
      <>
        <Dialog
          visible={visible}
          style={{ width: "450px" }}
          header={`${
            this.props.selectedCategory?.id
              ? `Kategori Güncelleme`
              : `Kategori Oluşturma`
          }`}
          modal
          className='p-fluid'
          footer={this.categoryDialogFooter}
          onHide={() => setVisible(false)}>
          {!componentLoading ? (
            <>
              {this.props.selectedCategory &&
              this.props.selectedCategory.categoryImage?.length ? (
                <div className='p-field d-flex justify-content-center'>
                  <img
                    src={CategoryHelper.getCategoryImageUrlById(
                      this.props.selectedCategory.categoryImage || []
                    )}
                    className='category-image d-flex justify-content-center'
                    style={{ width: "100px" }}
                  />
                </div>
              ) : (
                ""
              )}
              {/* <div className='p-field'>
                <label htmlFor='id'>Kategori Kodu</label>
                <InputText
                  name='id'
                  value={this.props.selectedCategory?.id}
                  readOnly
                  disabled
                  required
                  autoFocus
                />
              </div> */}
              <div className='p-field'>
                <Button
                  label={`${
                    this.props.selectedCategory?.categoryLocalizations &&
                    this.props.selectedCategory.categoryLocalizations.length
                      ? "Kategori Bilgilerini Güncelleme"
                      : "Kategori Bilgilerini Gir"
                  }`}
                  icon='pi pi-external-link'
                  onClick={() =>
                    this.setState({ openCategoryLocalizationDialog: true })
                  }
                />
              </div>
              <div className='p-formgrid'>
                <div className='p-field'>
                  <label htmlFor='mainCategory'>Ana Kategorisi</label>
                  <Dropdown
                    value={
                      this.props.mainCategories.find(
                        (x) =>
                          x.id == this.props.selectedCategory?.mainCategoryId
                      )?.mainCategoryLocalizations?.[0]
                    }
                    options={this.props.mainCategories.map(
                      (mainCategory) =>
                        mainCategory.mainCategoryLocalizations[0]
                    )}
                    optionLabel='mainCategoryName'
                    name='mainCategoryName'
                    filter
                    filterBy='mainCategoryName'
                    onChange={(e) =>
                      this.onInputChange(
                        "mainCategoryId",
                        e.target.value.mainCategoryId
                      )
                    }
                  />
                </div>
                {this.props.selectedCategory?.id && (
                  <div className='p-field p-col my-2'>
                    <Button
                      label='Kategori Fotoğrafı Ekle'
                      icon='pi pi-upload'
                      onClick={() => this.setState({ openImageDialog: true })}
                    />
                  </div>
                )}
                {this.props.selectedCategory?.id && (
                  <div className='p-field p-col my-2'>
                    <Button
                      label='Kategori Ürünleri'
                      className='p-button-warning'
                      icon='pi pi-play'
                      onClick={() =>
                        this.setState({ openCategoryProducts: true })
                      }
                    />
                  </div>
                )}
              </div>

              {this.state.openCategoryLocalizationDialog && (
                <CreateOrUpdateCategoryLocalization
                  visible={this.state.openCategoryLocalizationDialog}
                  setVisible={(p) =>
                    this.setState({ openCategoryLocalizationDialog: p })
                  }
                />
              )}
              {this.state.openImageDialog &&
                this.props.selectedCategory?.id && (
                  <Image
                    visible={this.state.openImageDialog}
                    setVisible={(p) => this.setState({ openImageDialog: p })}
                    entityId={this.props.selectedCategory.id}
                    fieldName={"CategoryId"}
                  />
                )}
              {this.state.openCategoryProducts &&
                this.props.selectedCategory?.id && (
                  <CategoryProducts
                    categoryId={this.props.selectedCategory.id}
                    setVisible={(p) =>
                      this.setState({ openCategoryProducts: p })
                    }
                    visible={this.state.openCategoryProducts}
                  />
                )}
            </>
          ) : (
            <GemGastroSpinner />
          )}
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.category.categories,
  mainCategories: state.mainCategory.mainCategories,
  selectedCategory: state.category.selectedCategory,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedCategory: (category) => dispatch(setSelectedCategory(category)),
    setCategoryDetail: (lookup) => dispatch(setCategoryDetail(lookup)),
    onChangeSelectedCategory: (changedValue) =>
      dispatch(onChangeSelectedCategory(changedValue)),
    updateCategory: (category) => dispatch(updateCategory(category)),
    createCategory: (category) => dispatch(createCategory(category)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOrUpdateCategory);
