import RequestHelper from "../helper/RequestHelper";

export async function GetMailTemplates() {
  const response = await RequestHelper.post({}, "mail", "GetMailTemplates");
  const r = response.data;
  return r;
}
export async function SendMail(data) {
  const response = await RequestHelper.post(data, "mail", "SendMail");
  const r = response.data;
  return r;
}
export async function CreateOrUpdateMailTemplate(data) {
  const response = await RequestHelper.multipart(data, "mail", "CreateOrUpdateMailTemplate");
  const r = response.data;
  return r;
}
;
