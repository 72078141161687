import { createSlice } from "@reduxjs/toolkit";

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    orders: [],
    selectedOrder: null,
    loading:false,

  },
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    setSelectedOrder: (state, action) => {
      let model = action.payload;
      state.selectedOrder = model;
    },
    onCargoChange: (state, action) => {
      const cargoId = action.payload.cargoId,
        productId = action.payload.productId;
      let model = state.selectedOrder;
      model.orderDetails = state.selectedOrder.orderDetails.map(
        (orderDetail) => {
          if (orderDetail.productId == productId)
            orderDetail.cargoId = +cargoId;
          return orderDetail;
        }
      );
      state.selectedOrder = model;
    },
    onCargoPriceChange: (state, action) => {
      const cargoPrice = action.payload.cargoPrice,
        productId = action.payload.productId;
      let model = state.selectedOrder;
      model.orderDetails = state.selectedOrder.orderDetails.map(
        (orderDetail) => {
          if (orderDetail.productId == productId)
            orderDetail.cargoPrice = +cargoPrice;
          return orderDetail;
        }
      );
      state.selectedOrder = model;
    },
    setLoading:(state,action) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOrders, setSelectedOrder, onCargoChange,onCargoPriceChange,setLoading} =
  orderSlice.actions;

export default orderSlice.reducer;
