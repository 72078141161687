import { Column } from "primereact/components/column/Column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NotificationHelper from "../../helper/NotificationHelper";
import OrderHelper from "../../helper/OrderHelper";
import { GetOrderLogs } from "../../service/orderService";

function OrderHistoryLog({ visible, setVisible }) {
  const [orderHistoryLogs, setOrderHistoryLogs] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const order = useSelector((state) => state.order.selectedOrder);
  const [orderId, setOrderId] = useState(order.id);

  useEffect(() => {
    getOrderLogs();
  }, [orderId]);

  const getOrderLogs = () => {
    GetOrderLogs(orderId)
      .then((response) => {
        if (response["is-success"]) {
          setOrderHistoryLogs(response.data);
           
        } else {
          NotificationHelper.Error(response.userMessage);
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir hata ile karşılaşıldı");
      });
  };
  const statusBodyTemplate = (rowData) => {
    const foundedStatus = OrderHelper.allStatusOptions.find(
      (element) => rowData.status.toString() == element.value
    );
    return (
      <span className={`order-badge order`}>{foundedStatus?.text || ""}</span>
    );
  };

  return (
    <Dialog
      visible={visible}
      style={{ width: "900px" }}
      header={"Sipariş Durum Kayıtları"}
      modal
      className='p-fluid'
      onHide={() => setVisible(false)}>
      <div className='card'>
        <DataTable
          // ref={dt}
          value={orderHistoryLogs}
          dataKey='id'
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
          currentPageReportTemplate='Showing {first} to {last} of {totalRecords} order logs'
          emptyMessage='Sipariş durum kaydı Bulunamadı.'
          header={""}
          //sortMode="multiple"
          scrollable>
          {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
          <Column
            field='orderCode'
            header='Sipariş Kodu'
            sortable
            ></Column>
            <Column
            field='type'
            header='İşlem Tipi'
            sortable
            body={(x)=>OrderHelper.formatType(x.type)}></Column>
            <Column
            field='status'
            header='Sipariş Durumu'
            body={statusBodyTemplate}
            sortable></Column>
          <Column
            field='customerFullName'
            header='Müşteri Adı'
            sortable
            ></Column>
          
          <Column
            field='createdDate'
            header='İşlem Tarihi'
            sortable
            body={OrderHelper.formatDateTime}></Column>
            

        </DataTable>
      </div>
    </Dialog>
  );
}

export default OrderHistoryLog;
