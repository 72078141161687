import React, { useRef, useState, useEffect } from "react";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";

import GemGastroSpinner from "../components/Core/GemGastroSpinner";
import { GetSliderImages, UpdateSliderLink } from "../service/inventoryService";
import NotificationHelper from "../helper/NotificationHelper";
import StringHelper from "../helper/StringHelper";
import { CreateImage, DeleteImage } from "../service/imageService";
import ImageLocalization from "../components/Image/ImageLocalization";


function SliderSettings() {
  const [images, setImages] = useState([]);
  useEffect(() => {
    getSliderImages();
  }, []);

  //image useState
  const [files, setFiles] = useState([]);
  const [deleteImageDialog, setDeleteImageDialog] = useState(false);
  const [imageCreateDialog, setImageCreateDialog] = useState(false);
  const [imageLocalizationDialog, setImageLocalizationDialog] = useState(false);
  const [mainImage, setMainImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [link, setLink] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const [componentLoading, setComponentLoading] = useState(false);
  const [gridLoading, setGridLoading] = useState(false);

  const [selectedSlideLink, setSelectedSlideLink] = useState();
  const [selectedSlide, setSelectedSlide] = useState();
  const [imageLinkDialog, setImageLinkDialog] = useState(false);

  const onUpload = (e) => {
    setFiles(e.files);
    NotificationHelper.Success("Dosyalar alındı lütfen kaydetmeyi unutmayın");
  };
  const getSliderImages = () => {
    setGridLoading(true);
    GetSliderImages()
      .then((response) => {
        if (response["is-success"]) {
          setImages(response.data);
        } else {
          NotificationHelper.Error(response.userMessage);
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir hata oluştu lütfen tekrar deneyiniz.");
      })
      .finally(() => {
        setGridLoading(false);
      });
  };
  const onRemove = (e) => {
    setFiles(files.filter((x) => x != e.file));
    // setImages(images.filter((x) => x != e.id));
  };
  const onClear = () => {
    setFiles([]);
  };
  const onUploadUpdateSave = async () => {
    setComponentLoading(true);
    const newImage = files;
    const data = new FormData();
    if (newImage.length) {
      newImage.map((img, i) => {
        data.append("data.formFileImages", img);
      });
      data.append("data.fieldName", "HomePageSliderId");
      data.append("data.fieldId", 1);
      data.append("data.sliderLink", link);

      CreateImage(data)
        .then((response) => {
          if (response["is-success"] && response.data) {
            NotificationHelper.Success("Yeni Fotoğraf eklendi");
            setImageCreateDialog(false);
            getSliderImages();
          } else {
            NotificationHelper.Success(
              response.userMessage || "Bir sorun oldu fotoğraf yüklenemedi"
            );
            setImageCreateDialog(false);
            getSliderImages();
          }
        })
        .catch(() => {
          NotificationHelper.Error("Bir sorun oldu fotoğraf yüklenemedi");
        })
        .finally(() => {
          setComponentLoading(false);
        });
    } else {
      NotificationHelper.Error("Önce dosya seçiniz");
    }
  };
  const confirmImageSlider = (rowData) => {
    setFiles(rowData);
    setDeleteImageDialog(true);
  };
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label='Yeni Fotoğraf Ekle'
          icon='pi pi-plus'
          className='p-button-success p-mr-2'
          onClick={() => setImageCreateDialog(true)}
        />
        <Button
          label='Yenile'
          icon='pi pi-refresh'
          className='p-button mx-3'
          onClick={() => getSliderImages()}
        />
      </React.Fragment>
    );
  };
  const hideImageDeleteDialog = () => {
    setDeleteImageDialog(false);
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon='pi pi-pencil'
          className='p-button-rounded p-2'
          style={{ backgroundColor: "blue", color: 'white' }}
          onClick={async () => {

            await setSelectedSlide(rowData)
            await setSelectedSlideLink(rowData.sliderLink);
            setImageLinkDialog(true);
          }}
        />
        <Button
          icon='pi pi-info'
          className='p-button-rounded p-2'
          style={{ backgroundColor: "blue", color: 'white' }}
          onClick={async () => {

            await setMainImage(rowData)
            await setImageLocalizationDialog(true);
          }}
        />
        <Button
          icon='pi pi-trash'
          className='p-button-rounded p-2'
          style={{ backgroundColor: "red" }}
          onClick={() => confirmImageSlider(rowData)}
        />
      </React.Fragment>
    );
  };
  const handleChange = (value) => {
    setLink(value);
  };
  const deleteImageSave = async () => {
    setComponentLoading(true);
    var arr = [];
    arr.push(selectedImage.id);
    DeleteImage(arr)
      .then((response) => {
        if (response["is-success"]) {
          NotificationHelper.Success("Fotoğraf başarıyla silindi");
          setComponentLoading(false);
          setDeleteImageDialog(false);
          getSliderImages();
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi"
          );
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir hata meydana geldi");
        setComponentLoading(false);
      });

    setComponentLoading(false);
    setDeleteImageDialog(false);
    getSliderImages();
  };

  const deleteImageDialogFooter = () => {
    return (
      <React.Fragment>
        <Button
          icon='pi pi-trash'
          className='p-button-rounded p-2'
          style={{ backgroundColor: "red" }}
          onClick={() => deleteImageSave()}
        />
      </React.Fragment>
    );
  };
  const header = (
    <div className='table-header'>
      <h5 className='p-m-0'>Slaytın Fotoğrafları</h5>
    </div>
  );
  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={`${process.env.REACT_APP_API_IMG}/${rowData.url}`}
        className='product-image'
        style={{ width: "110px" }}
      />
    );
  };
  const onUpdateSliderLink = () => {
    setComponentLoading(true);

    if (!selectedSlide?.id) {
      NotificationHelper.Error("Fotoğraf bulunamadı.");
      setComponentLoading(false); return false;
    }

    UpdateSliderLink(selectedSlide.id, selectedSlideLink)
      .then((response) => {
        if (response["is-success"]) {
          NotificationHelper.Success("Fotoğraf başarıyla güncellendi");
          setComponentLoading(false);
          setImageLinkDialog(false);
          setSelectedSlide(undefined);
          setSelectedSlideLink('');
          getSliderImages();
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi"
          );
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir hata meydana geldi");
        setComponentLoading(false);
        getSliderImages();
      });
  }
  const linkImageDialogFooter = (
    <React.Fragment>
      <Button
        label='Kaydet'
        icon='pi pi-check'
        className='p-button-text'
        onClick={() => onUpdateSliderLink()}
      />
    </React.Fragment>
  );

  const createImageDialogFooter = (
    <React.Fragment>
      <Button
        label='Kaydet'
        icon='pi pi-check'
        className='p-button-text'
        onClick={() => onUploadUpdateSave()}
      />
    </React.Fragment>
  );
  return (
    <>
      {componentLoading ? (
        <GemGastroSpinner isLogin={false} />
      ) : (
        <div>
          <Toast ref={toast}></Toast>
          <Toolbar className='p-mb-4' left={leftToolbarTemplate}></Toolbar>
          <ImageLocalization imageId={mainImage?.id} setVisible={(d) => setImageLocalizationDialog(d)} visible={imageLocalizationDialog} />
          <DataTable
            ref={dt}
            value={images}
            selection={selectedImage}
            onSelectionChange={(e) => setSelectedImage(e.value)}
            dataKey='id'
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords} products'
            header={header}
            loading={gridLoading}>
            <Column
              selectionMode='single'
              headerStyle={{ width: "3rem" }}></Column>
            <Column field='id' header='Ürün Kodu' sortable></Column>
            <Column
              field='url'
              header='Slayt Resmi'
              body={imageBodyTemplate}></Column>
            <Column body={actionBodyTemplate}></Column>
          </DataTable>
          <Dialog
            visible={deleteImageDialog}
            style={{ width: "450px" }}
            header='Confirm'
            modal
            footer={deleteImageDialogFooter}
            onHide={hideImageDeleteDialog}>
            <div className='confirmation-content'>
              <i
                className='pi pi-exclamation-triangle p-mr-3'
                style={{ fontSize: "2rem", color: "red" }}
                onClick={() => setDeleteImageDialog(false)}
              />
              {selectedImage && <span>Fotoğraf silinecek emin misiniz ?</span>}
            </div>
          </Dialog>
          <Dialog
            header={"Slayt Link Düzenle"}
            visible={imageLinkDialog}
            onHide={() => {
              setImageLinkDialog(false);
              setSelectedSlide(undefined);
              setSelectedSlideLink('');
            }}
            breakpoints={{ "960px": "75vw" }}
            footer={linkImageDialogFooter}
            style={{ width: "50vw" }}>
            <div className='p-field my-2 p-2'>
              <label htmlFor='SliderLink'>Fotoğraf Linki</label>
              <InputText
                id='SliderLink'
                value={selectedSlideLink}
                onChange={(e) => {
                  setSelectedSlideLink(e.target.value);
                }}
                required
                autoFocus
                className={"p-invalid w-100"}
              />
            </div>
          </Dialog>
          <Dialog
            header={"Slayt Fotoğrafı Ekle"}
            visible={imageCreateDialog}
            onHide={() => setImageCreateDialog(false)}
            breakpoints={{ "960px": "75vw" }}
            footer={createImageDialogFooter}
            style={{ width: "50vw" }}>
            <div className='p-field my-2 p-2'>
              <label htmlFor='SliderLink'>Fotoğraf Linki</label>
              <InputText
                id='SliderLink'
                onChange={(e) => handleChange(e.target.value)}
                required
                autoFocus
                className={"p-invalid w-100"}
              />
            </div>
            <FileUpload
              name='productImages'
              url='./upload.php'
              customUpload
              onRemove={(e) => onRemove(e)}
              onClear={() => onClear()}
              uploadHandler={(e) => onUpload(e)}
              accept='image/*'
              maxFileSize={1000000}
              emptyTemplate={<p className='p-m-0'>Fotoğrafı sürükle bırak</p>}
            />
          </Dialog>
        </div>
      )}
    </>
  );
}

export default SliderSettings;
