import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Chart } from "primereact/chart";
import { Column } from "primereact/column";
import OrderHelper from "../helper/OrderHelper";
import moment from "moment";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const chartData = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [
          "#42A5F5",
          "#66BB6A",
          "#FFA726",
          "#d7bde2",
          "#aed6f1",
          "#f9e79f",
          "#d7dbdd",
          "#17a589",
          "#f1948a",
          "#e6b0aa",
        ],
        hoverBackgroundColor: [
          "#64B5F6",
          "#81C784",
          "#FFB74D",
          "#fadbd8",
          "#5d6d7e",
          "#7b241c",
          "#17a589",
          "#f1948a",
          "#e6b0aa",
          "#FFA726",
        ],
      },
    ],
  };

  const lightOptions = {
    legend: {
      labels: {
        fontColor: "#495057",
      },
    },
  };
  const chartDataTwo = {
    labels: [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ],
    datasets: [
      {
        type: "line",
        label: "Siparişler",
        borderColor: "#42A5F5",
        borderWidth: 2,
        fill: false,
        data: [],
      },
      {
        type: "bar",
        label: "Satışlar",
        backgroundColor: "#66BB6A",
        data: [],
        borderColor: "white",
        borderWidth: 2,
      },
      {
        type: "bar",
        label: "İadeler",
        backgroundColor: "#FFA726",
        data: [],
      },
    ],
  };

  const lightOptionsTwo = {
    legend: {
      labels: {
        fontColor: "#495057",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: "#495057",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: "#495057",
          },
        },
      ],
    },
  };
  const chartDataThree = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [
          "#42A5F5",
          "#66BB6A",
          "#FFA726",
          "#d7bde2",
          "#aed6f1",
          "#f9e79f",
          "#d7dbdd",
          "#17a589",
          "#f1948a",
          "#e6b0aa",
        ],
        hoverBackgroundColor: [
          "#64B5F6",
          "#81C784",
          "#FFB74D",
          "#fadbd8",
          "#5d6d7e",
          "#7b241c",
          "#17a589",
          "#f1948a",
          "#e6b0aa",
          "#FFA726",
        ],
      },
    ],
  };

  const lightOptionsThree = {
    legend: {
      labels: {
        fontColor: "#495057",
      },
    },
  };

  const ordersModel = useSelector((state) => state.order.orders);
  const customersModel = useSelector((state) => state.customer.customers);

  const categoriesChartModel = useSelector(
    (state) => state.inventory.categoriesOrders
  );
  const mainCategoriesChartModel = useSelector(
    (state) => state.inventory.mainCategoriesOrders
  );
  const [monthlyTotalPrice, setMonthlyTotalPrice] = useState(0);

  useEffect(() => {
    if (ordersModel.length) {
      var monthlyOrders = ordersModel.filter(
        (x) =>
          moment(x.createdDate).format("YYYY-MM") ==
          moment(Date.now()).format("YYYY-MM")
      );
      if (monthlyOrders.length) {
        var monthlyTotalPriceCal = monthlyOrders
          .map((item) => item.totalPrice)
          .reduce((a, b) => (parseFloat(a) + parseFloat(b)).toFixed(2));
        setMonthlyTotalPrice(monthlyTotalPriceCal);
      }

      fetchChartData();
      fetchChartDataTwo();
    }

    return false;
  }, [ordersModel, categoriesChartModel, mainCategoriesChartModel]);

  const formatCurrency = (value) => {
    return value?.toLocaleString("de", { style: "currency", currency: "EUR" });
  };
  const fetchChartData = () => {
    var categoryLabels = [];
    var orderData = [];

    categoriesChartModel.forEach((element) => {
      categoryLabels.push(element.categoryName);
      orderData.push(element.orderLength);
    });

    chartData.labels = categoryLabels;
    chartData.datasets[0]?.data.push(orderData);

    var maincategoryLabels = [];
    var mainorderData = [];

    mainCategoriesChartModel.forEach((element) => {
      maincategoryLabels.push(element.mainCategoryName);
      mainorderData.push(element.ordersLength);
    });

    chartDataThree.labels = maincategoryLabels;
    chartDataThree.datasets[0]?.data.push(mainorderData);
  };

  const fetchChartDataTwo = () => {
    var year = moment(Date.now()).format("YYYY");
    let newOrders = [];
    let orders = [];
    let returns = [];
    for (var i = 1; i < 13; i++) {
      if (i < 10) {
        newOrders = ordersModel?.filter(
          (x) =>
            moment(x.createdDate).format("YYYY-MM") == `${year}-0${i}` &&
            x.status == 1
        ).length;
        orders = ordersModel?.filter(
          (x) => moment(x.createdDate).format("YYYY-MM") == `${year}-0${i}`
        ).length;
        returns = ordersModel?.filter(
          (x) =>
            moment(x.createdDate).format("YYYY-MM") == `${year}-0${i}` &&
            (x.status == 9 || x.status == 7)
        ).length;
      }

      if (i >= 10) {
        newOrders = ordersModel?.filter(
          (x) =>
            moment(x.createdDate).format("YYYY-MM") == `${year}-${i}` &&
            (x.status == 1 || x.status == 8)
        ).length;
        orders = ordersModel?.filter(
          (x) => moment(x.createdDate).format("YYYY-MM") == `${year}-0${i}`
        ).length;
        returns = ordersModel?.filter(
          (x) =>
            moment(x.createdDate).format("YYYY-MM") == `${year}-0${i}` &&
            x.status == 15
        ).length;
      }

      chartDataTwo.datasets[0].data.push(newOrders);
      chartDataTwo.datasets[1].data.push(orders);
      chartDataTwo.datasets[2].data.push(returns);
    }
  };

  return (
    <div className="p-grid p-fluid dashboard">
      <div className="p-col-12 p-lg-3">
        <div className="card summary">
          <span className="title">Yeni Siparişler</span>
          <span className="detail">Yeni Sipariş Sayısı</span>
          <span className="count visitors" style={{ fontSize: "14px" }}>
            {
              ordersModel.filter(
                (x) =>
                  x.status == 1 &&
                  x.status == 8 &&
                  moment(x.createdDate).format("YYYY-MM-DD") ==
                    moment(Date.now()).format("YYYY-MM-DD")
              ).length
            }
          </span>
        </div>
      </div>
      <div className="p-col-12 p-lg-3">
        <div className="card summary">
          <span className="title">Siparişler</span>
          <span className="detail">Aylık Toplam Sipariş Sayısı</span>
          <span className="count purchases" style={{ fontSize: "14px" }}>
            {
              ordersModel.filter(
                (x) =>
                  moment(x.createdDate).format("YYYY-MM") ==
                  moment(Date.now()).format("YYYY-MM")
              ).length
            }
          </span>
        </div>
      </div>
      <div className="p-col-12 p-lg-3">
        <div className="card summary">
          <span className="title">Siparişler</span>
          <span className="detail">Yıllık Toplam Sipariş Sayısı</span>
          <span className="count purchases" style={{ fontSize: "14px" }}>
            {
              ordersModel.filter(
                (x) =>
                  moment(x.createdDate).format("YYYY") ==
                  moment(Date.now()).format("YYYY")
              ).length
            }
          </span>
        </div>
      </div>
      <div className="p-col-12 p-lg-3">
        <div className="card summary">
          <span className="title">Gelir</span>
          <span className="detail">Aylık Toplam Gelir</span>
          <span className="count revenue" style={{ fontSize: "12px" }}>
            {" "}
            {monthlyTotalPrice} €
          </span>
        </div>
      </div>
      <div className="p-col-12 p-lg-12">
        <div className="card">
          <div className="p-grid">
            <div className="p-col-12 p-lg-6">
              <label
                className="p-col-6"
                style={{ color: "#2196F3", fontSize: "20px" }}
              >
                {" "}
                Kategoriye Göre Satış Grafiği
              </label>
              {/* <Chart type="pie" data={chartData} options={lightOptions} /> */}
            </div>
            <div className="p-col-12 p-lg-6">
              <label
                className="p-col-6"
                style={{ color: "#2196F3", fontSize: "24px" }}
              >
                {" "}
                Satış ve İade Grafiği
              </label>
              <Chart type="bar" data={chartDataTwo} options={lightOptionsTwo} />
            </div>
          </div>
        </div>
      </div>
      <div className="p-col-12 p-lg-6">
        <div className="card">
          <h1 style={{ fontSize: "16px" }}>Son Siparişler</h1>
          <DataTable
            value={ordersModel}
            className="p-datatable-customers"
            rows={5}
            style={{ marginBottom: "20px" }}
            paginator
          >
            <Column field="id" header="Sipariş No" sortable></Column>
            <Column field="orderCode" header="Sipariş Kodu" sortable></Column>
            <Column
              body={(data) =>
                OrderHelper.findOrderCustomer(data.customerId, customersModel)
                  .name
              }
              header="Müşteri Adı"
              sortable
            ></Column>
            <Column
              body={(data) =>
                OrderHelper.findOrderCustomer(data.customerId, customersModel)
                  .surname
              }
              header="Müşteri Soyadı"
              sortable
            ></Column>
            <Column
              field="totalPrice"
              header="Sipariş Tutarı"
              sortable
              body={(data) => formatCurrency(data.totalPrice)}
            ></Column>
          </DataTable>
        </div>
      </div>
      <div className="p-col-12 p-lg-6">
        <label
          className="p-col-6"
          style={{ color: "#2196F3", fontSize: "24px" }}
        >
          {" "}
          Ana Kategoriye Göre Satış Grafiği
         </label>
         <Chart
          type='doughnut'
          options={lightOptionsThree}
          data={chartDataThree}
        />
      </div>
    </div>
  );
};
export default Dashboard;
