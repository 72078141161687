export default class InventoryHelper {
  static prepareProducts(productsResponse) {
    return productsResponse;
  }
  static prepareCategories(categoriesResponse) {
    return categoriesResponse;
  }
  static prepareMainCategories(mainCategoriesResponse) {
    return mainCategoriesResponse;
  }
  static prepareCustomers(customersResponse) {
    return customersResponse;
  }
  static prepareOrders(ordersResponse) {
    return ordersResponse;
  }
  static prepareCategoriesOrders(categoriesOrdersResponse) {
    return categoriesOrdersResponse;
  }
  static prepareMainCategoriesOrders(mainCategoriesOrdersResponse) {
    return mainCategoriesOrdersResponse;
  }
  static prepareCargos(cargosResponse) {
    return cargosResponse;
  }
  static prepareSuppliers(suppliersResponse) {
    return suppliersResponse;
  }
  static prepareCanBeChangeOrderStatusWithLabel(
    canBeChangeOrderStatusWithLabelResponse
  ) {
    return canBeChangeOrderStatusWithLabelResponse;
  }
  static prepareOrderHistoryLogTypeWithLabel(
    orderHistoryLogTypeWithLabelResponse
  ) {
    return orderHistoryLogTypeWithLabelResponse;
  }
  static prepareLocalizations(localizationsResponse) {
    return localizationsResponse;
  }
}
