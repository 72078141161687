import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import InventoryHelper from "../../../helper/InventoryHelper";

import NotificationHelper from "../../../helper/NotificationHelper";
import { GetInventories, AdminGetAllDeletedProduct } from "../../../service/inventoryService";
 
import {
  setCargos,
  setLoading as cargoLoading,
} from "../../../storage/cargo/cargoSlice";
import {
  setCategories,
  setLoading as categoryLoading,
} from "../../../storage/category/categorySlice";
import {
  setCustomers,
  setLoading as customerLoading,
} from "../../../storage/customer/customerSlice";
import {
  setCanBeChangeOrderStatusWithLabel,
  setCategoriesOrders,
  setInventoryLoading,
  setMainCategoriesOrders,
  setOrderHistoryLogTypeWithLabel,
  setLoading as inventoryLoading,
} from "../../../storage/inventory/inventorySlice";
import {
  setLocalizations,
  setLoading as localizationLoading,
  setClientLocalizations,
  setErrorMessages,
  setSettings,
} from "../../../storage/localization/localizationSlice";
import {
  setMainCategories,
  setLoading as mainCategoryLoading,
} from "../../../storage/mainCategory/mainCategorySlice";
import {
  setOrders,
  setLoading as orderLoading,
} from "../../../storage/order/orderSlice";
import {
  setProducts,
  setLoading as productLoading,
  setDeletedProducts,
  setDeletedLoading
} from "../../../storage/product/productSlice";
import {
  setSuppliers,
  setLoading as supplierLoading,
} from "../../../storage/supplier/supplierSlice";
import classNames from "classnames";
import GemGastroSpinner from "../GemGastroSpinner";
import { CSSTransition } from "react-transition-group";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import { AppFooter } from "./AppFooter";
import { AppProfile } from "./AppProfile";
import { AppMenu } from "./AppMenu";
import "./adminLayout.scss";
import "./layout.scss";

function Main({ children }) {
  const dispatch = useDispatch();

  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("dark");
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(false);
  const [loading, setLoading] = useState(false);
  const sidebar = useRef();

  const history = useHistory();

  let menuClick = false;

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  const handleInventories = (token) => {
    setLoading(false);
  };

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
    menuClick = false;
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };

  const menu = [
    { label: "Ana Sayfa", icon: "pi pi-fw pi-home", to: "/" },
    {
      label: "Ürün Yönetimi",
      icon: "pi pi-fw pi-pencil",
      items: [
        { label: "Ürünler", icon: "pi pi-fw pi-desktop", to: "/urunler" },
        { label: "Silinmiş Ürünler", icon: "pi pi-fw pi-times-circle", to: "/silinmis-urunler" }
      ],
    },
    { label: "Kategoriler", icon: "pi pi-fw pi-sitemap", to: "/kategoriler" },
    {
      label: "Ana Kategoriler",
      icon: "pi pi-fw pi-sitemap",
      to: "/ana-kategoriler",
    },
    {
      label: "Sipariş Listeleri",
      icon: "pi pi-fw pi-shopping-cart",
      items: [
        {
          label: "Yeni Siparişler",
          icon: "pi pi-fw pi-star",
          to: "/yeni-siparisler",
        },
        {
          label: "Ödeme Sonrası",
          icon: "pi pi-fw pi-money-bill",
          to: "/ödeme-sonrasi",
        },
        {
          label: "Siparişler",
          icon: "pi pi-fw pi-wallet",
          to: "/siparisler",
        },
        {
          label: "İadeler ve İptaller",
          icon: "pi pi-fw pi-info-circle",
          to: "/iadeler",
        },
       
      ],
    },
    { label: "Müşteriler", icon: "pi pi-fw pi-users", to: "/musteriler" },
    
    {
      label: "E-Mail İşlemleri",
      icon: "pi pi-fw pi-inbox",
      items: [
        {
          label: "E-Mail Gönderme",
          icon: "pi pi-fw pi-send",
          to: "/mail-gonder",
        },
        {
          label: "Mail Şablonu Yükleme",
          icon: "pi pi-fw pi-upload",
          to: "/sablon-yukle",
        },
      ],
    },
    { label: "Kargo Yönetimi", icon: "pi pi-fw pi-directions", to: "/kargolar" },
    {
      label: "Ayarlar",
      icon: "pi pi-fw pi-cog",
      items: [
        {
          label: "Genel Ayarlar",
          icon: "pi pi-fw pi-cog",
          to: "/genel-ayarlar",
        },
        {
          label: "Dil Ayarları",
          icon: "pi pi-fw pi-briefcase",
          items: [
            {
              label: "Hata Mesajları",
              icon: "pi pi-fw pi-cog",
              to: "/hata-mesajlari-ayarlari",
            },
            {
              label: "Web Dil Ayarları",
              icon: "pi pi-fw pi-cog",
              to: "/dil-ayarlari",
            },
            {
              label: "Dil Yönetimi",
              icon: "pi pi-fw pi-cog",
              to: "/dil-yonetimi",
            },
          ],
        },
        {
          label: "Sayfa Ayarları",
          icon: "pi pi-fw pi-briefcase",
          items: [
            {
              label: "Slayt Ayarları",
              icon: "pi pi-fw pi-cog",
              to: "/slayt-ayarlari",
            },
            {
              label: "Ürün Slayt Ayarları",
              icon: "pi pi-fw pi-cog",
              to: "/urun-slayt-ayarlari",
            },
            {
              label: "Uber Uns Slayt Ayarları",
              icon: "pi pi-fw pi-cog",
              to: "/ubers-uns-fotograflar",
            },
          ],
        },
        {
          label: "Ödeme Ayarları",
          icon: "pi pi-fw pi-user-edit",
          items: [
            {
              label: "Ödeme Methodları Bilgileri",
              icon: "pi pi-fw pi-cog",
              to: "/ödeme-methodlari",
            },
            {
              label: "Banka Yönetimi",
              icon: "pi pi-fw pi-cog",
              to: "/banka-yonetimi",
            },
          ],
        },
      ],
    },
  ];

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const isDesktop = () => {
    return window.innerWidth > 1024;
  };

  const isSidebarVisible = () => {
    if (isDesktop()) {
      if (layoutMode === "static") return !staticMenuInactive;
      else if (layoutMode === "overlay") return overlayMenuActive;
      else return true;
    }

    return true;
  };

  const logo =
    layoutColorMode === "dark"
      ? "assets/layout/images/logo-white.svg"
      : "assets/layout/images/logo.svg";

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
  });

  const sidebarClassName = classNames("layout-sidebar", {
    "layout-sidebar-dark": layoutColorMode === "dark",
    "layout-sidebar-light": layoutColorMode === "light",
  });

  useEffect(() => {
    dispatch(setInventoryLoading(true));
    setAllLoading(true);
    GetInventories()
      .then((response) => {
        if (response["is-success"]) {
          const inventory = response.data;
          dispatch(
            setProducts(InventoryHelper.prepareProducts(inventory.products))
          );
          
          dispatch(
            setCategories(
              InventoryHelper.prepareCategories(inventory.categories)
            )
          );
          dispatch(
            setMainCategories(
              InventoryHelper.prepareMainCategories(
                inventory["main-categories"]
              )
            )
          );
          dispatch(
            setCustomers(InventoryHelper.prepareCustomers(inventory.customers))
          );
          dispatch(
            setSettings(inventory.settings)
          );
          dispatch(setOrders(InventoryHelper.prepareOrders(inventory.orders)));
          dispatch(setLocalizations(inventory.localizations));
          dispatch(
            setClientLocalizations(inventory.clientLocalizations)
          );
          dispatch(
            setErrorMessages(inventory.errorMessages)
          );
          dispatch(
            setCategoriesOrders(
              InventoryHelper.prepareCategoriesOrders(
                inventory["categories-orders"]
              )
            )
          );
          dispatch(
            setMainCategoriesOrders(
              InventoryHelper.prepareMainCategoriesOrders(
                inventory["main-categories-orders"]
              )
            )
          );
          dispatch(setCargos(InventoryHelper.prepareCargos(inventory.cargos)));
          dispatch(
            setSuppliers(InventoryHelper.prepareSuppliers(inventory.suppliers))
          );
          dispatch(
            setCanBeChangeOrderStatusWithLabel(
              inventory["can-be-change-order-status-with-label"]
            )
          );
          dispatch(
            setOrderHistoryLogTypeWithLabel(
              InventoryHelper.prepareOrderHistoryLogTypeWithLabel(
                inventory["order-history-log-type-with-label"]
              )
            )
          );
        } else {
          NotificationHelper.Error(response.userMessage);
        }
      })
      .catch((err) => {
        console.log(err);
        // NotificationHelper.Error(
        //   "Bir hata meydana geldi lütfen yazılımcınıza danışınız."
        // );
      })
      .finally(
        () => {

          setAllLoading(false)
        
        });
  }, []);

  useEffect(()=>{
    dispatch(setDeletedLoading(true));
    AdminGetAllDeletedProduct().then((response)=>{
      if(response["is-success"]){
        console.log("lale")
        dispatch(setDeletedProducts(response.data));
      } 
    }).finally(()=>{
      dispatch(setDeletedLoading(false));
    })
  },[]);

  const setAllLoading = (val) => {
    dispatch(cargoLoading(val));
    dispatch(categoryLoading(val));
    dispatch(customerLoading(val));
    dispatch(localizationLoading(val));
    dispatch(mainCategoryLoading(val));
    dispatch(orderLoading(val));
    dispatch(productLoading(val));
    dispatch(supplierLoading(val));
  };
  return (
    <>
      {loading ? (
        <GemGastroSpinner />
      ) : (
        <div className={wrapperClass} onClick={onWrapperClick}>
          <CSSTransition
            classNames='layout-sidebar'
            timeout={{ enter: 200, exit: 200 }}
            in={isSidebarVisible()}
            unmountOnExit>
            <div
              ref={sidebar}
              className={sidebarClassName}
              onClick={onSidebarClick}>
              <div
                className='layout-logo'
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/")}>
                <img
                  alt='Logo'
                  src='/gastrologopng.png'
                  className='img-fluid'
                />
              </div>
              <AppProfile />
              <AppMenu model={menu} onMenuItemClick={onMenuItemClick} />
            </div>
          </CSSTransition>

          <div className='layout-main'>{children}</div>

          <AppFooter />
        </div>
      )}
    </>
  );
}

export default Main;
