import React, { Component } from "react";
import { connect } from "react-redux";
import GemGastroSpinner from "../Core/GemGastroSpinner";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import CargoHelper from "../../helper/CargoHelper";
import classNames from "classnames";
import {
  createCargo,
  onChangeSelectedCargo,
  setCargoDetail,
  setSelectedCargo,
  updateCargo,
} from "../../storage/cargo/cargoSlice";
import Confirm from "../Core/Confirm";
import Image from "../Image";
import { CreateCargo, UpdateCargo } from "../../service/cargoService";
import NotificationHelper from "../../helper/NotificationHelper";

//props
//editModel=
//setEditMod
//visible={t
//setVisible
//
export class CreateOrUpdateCargo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      componentLoading: false,
    };
  }
  saveCargo = () => {
    const updateCargoOnClient = this.props.updateCargo;
    const createCargoOnClient = this.props.createCargo;
    const cargo = this.props.selectedCargo;
    //update
    if (this.props.selectedCargo && this.props.selectedCargo?.id) {
      UpdateCargo(this.props.selectedCargo)
        .then((response) => {
          if (response?.["is-success"] && response.data) {
            updateCargoOnClient(cargo);
            NotificationHelper.Success("Güncelleme işlemi başarılı");
          } else {
            NotificationHelper.Error(response?.userMessage);
          }
        })
        .catch((err) => {
          NotificationHelper.Error("Beklenmedik bir hata oluştu.");
        });
    } else if (this.props.selectedCargo && !this.props.selectedCargo?.id) {
      CreateCargo(this.props.selectedCargo.fullName)
        .then((response) => {
          if (response?.["is-success"] && response.data) {
            createCargoOnClient(cargo);
            NotificationHelper.Success("Kargo oluşturma işlemi başarılı");
          } else {
            NotificationHelper.Error(response?.userMessage);
          }
        })
        .catch((err) => {
          NotificationHelper.Error("Beklenmedik bir hata oluştu.");
        });
    }
  };
  cargoDialogFooter = (
    <React.Fragment>
      <Button
        label='İptal'
        icon='pi pi-times'
        className='p-button-text'
        onClick={() => this.props.setVisible(false)}
      />
      <Button
        label='Kaydet'
        icon='pi pi-check'
        className='p-button-text'
        onClick={this.saveCargo}
      />
    </React.Fragment>
  );

  onInputChange = (name, value) => {
    let model = {
      name,
      value,
    };
    this.props.onChangeSelectedCargo(model);
  };
  render() {
    const { componentLoading } = this.state;
    const { setVisible, visible } = this.props;
    return (
      <>
        <Dialog
          visible={visible}
          style={{ width: "450px" }}
          header={`${
            this.props.selectedCargo?.id
              ? `Kargo Güncelleme`
              : `Kargo Oluşturma`
          }`}
          modal
          className='p-fluid'
          footer={this.cargoDialogFooter}
          onHide={() => setVisible(false)}>
          {!componentLoading ? (
            <>
              {/* <div className='p-field'>
                <label htmlFor='id'>Kargo Kodu</label>
                <InputText
                  name='id'
                  value={this.props.selectedCargo?.id}
                  readOnly
                  disabled
                  required
                  autoFocus
                />
              </div> */}
              <div className='p-field'>
                <label htmlFor='id'>Kargo Şirketi Adı</label>
                <InputText
                  name='fullName'
                  value={this.props.selectedCargo?.fullName}
                  required
                  autoFocus
                  onChange={(e) =>
                    this.onInputChange(e.target.name, e.target.value)
                  }
                />
              </div>
            </>
          ) : (
            <GemGastroSpinner />
          )}
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  cargos: state.cargo.cargos,
  selectedCargo: state.cargo.selectedCargo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedCargo: (cargo) => dispatch(setSelectedCargo(cargo)),
    setCargoDetail: (lookup) => dispatch(setCargoDetail(lookup)),
    onChangeSelectedCargo: (changedValue) =>
      dispatch(onChangeSelectedCargo(changedValue)),
    updateCargo: (cargo) => dispatch(updateCargo(cargo)),
    createCargo: (cargo) => dispatch(createCargo(cargo)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOrUpdateCargo);
