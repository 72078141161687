import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";

import GemGastroSpinner from "../components/Core/GemGastroSpinner";

import { useSelector } from "react-redux";
import {
  CreateOrUpdateMailTemplate,
  GetMailTemplates,
} from "../service/mailService";
import NotificationHelper from "../helper/NotificationHelper";
const UploadMailTemp = () => {
  const toast = useRef(null);

  //selected mail template
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  //şablon title
  const [title, setTitle] = useState("");
  const [files, setFiles] = useState([]);
  const [replaceChar, setReplaceChar] = useState("");
  useEffect(() => {
    getAllMailTemplates();
  }, []);

  const [mailTemplates, setMailTemplates] = useState([]);
  const [createProcessor, setCreateProcessor] = useState(true);
  const [mailTemplateTypes, setMailTemplateTypes] = useState([
    { name: "Campaign", value: 2 },
    { name: "CustomerInformation1", value: 4 },
    { name: "CustomerInformation2", value: 5 },
    { name: "CustomerInformation3", value: 6 },
  ]);
  const [componentLoading, setComponentLoading] = useState(false);
  const getAllMailTemplates = () => {
    setComponentLoading(true);
    GetMailTemplates()
      .then((response) => {
        if (response["is-success"] && response.data) {
          const data = response.data;
          setMailTemplates(data);
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi"
          );
        }
      })
      .finally(() => {
        setComponentLoading(false);
      });
  };
  const models = {
    Name: "",
    FormFile: null,
    ReplaceChar: "",
    MailTemplateType: 0,
  };

  const onUpload = (e) => {
    setFiles(e.files);
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "Dosya alındı, şablonu oluşturabilirsiniz. ",
    });
  };
  const onRemove = (e) => {
    setFiles([]);
  };
  const onClear = () => {
    setFiles([]);
  };
  const onTemplateChange = (e) => {

    const foundedMailTemplate = mailTemplates.find(
      (x) => x.mailTemplateType == e.target.value
    );
    if (foundedMailTemplate) {
      setTitle(foundedMailTemplate.name);
      setReplaceChar(foundedMailTemplate.replaceChar);
      setCreateProcessor(false);
    } else {
      setTitle("");
      setReplaceChar("");
      setCreateProcessor(true);
    }

    setSelectedTemplate(e.value);
  };
  const createOrUpdateMailTemplate = async (e) => {
    setComponentLoading(true);
    models.Name = title;
    models.MailTemplateType = selectedTemplate;

    const data = new FormData();
    const foundedMailTemplate = mailTemplates.find(
      (x) => x.mailTemplateType == selectedTemplate
    );

    files.map((file) => data.append("data.TemplateBody", file));
    data.append("data.id", !createProcessor ? foundedMailTemplate.id : 0);
    data.append("data.Name", models.Name);
    data.append("data.ReplaceChar", replaceChar);
    data.append("data.MailTemplateType", models.MailTemplateType);

    CreateOrUpdateMailTemplate(data)
      .then((response) => {
        if (response["is-success"]) {
          setFiles([]);
          NotificationHelper.Success(
            `Mail Tasarımı başarıyla ${
              createProcessor ? "kaydedildi" : "güncellendi"
            }.`
          );
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata Meydana geldi"
          );
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir hata meydana geldi");
      });

    getAllMailTemplates();
  };

  return (
    <>
      {componentLoading ? (
        <GemGastroSpinner isLogin={false} />
      ) : (
        <div className='card'> 
          <div className='card'>
            <h5>Mail İçeriğinizin Görünüşü İçin Mail Şablonu Seçiniz</h5>
            <Dropdown
              value={selectedTemplate}
              options={mailTemplateTypes}
              optionLabel='name'
              optionValue='value'
              onChange={onTemplateChange}
              filter
              showClear
              filterBy='name'
              placeholder='Mail Şablonu Seçiniz.'
            />
          </div>
          <div className='card p-fluid'>
            <span className='p-textfield'>
              <h5 htmlFor='title'>Şablon Adı</h5>
              <InputText
                className='p-inputtext'
                id='title'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                disabled={selectedTemplate ? false : true}
              />
            </span>
          </div>
          <div className='card p-fluid'>
            <span className='p-textfield'>
              <h5 htmlFor='title'>
                Replace Edilecek Karakter("[Body] Kullanımı önerilir.")
              </h5>
              <InputText
                className='p-inputtext'
                id='title'
                value={replaceChar}
                onChange={(e) => setReplaceChar(e.target.value)}
                disabled={selectedTemplate ? false : true}
              />
            </span>
          </div>
          <h5>E-Mail Şablonu Yükle</h5>
          <FileUpload
            name='template'
            url='https://primefaces.org/primereact/showcase/upload.php'
            customUpload
            onRemove={(e) => onRemove(e)}
            onClear={() => onClear()}
            uploadHandler={(e) => onUpload(e)}
            single
            maxFileSize={1000000}
            emptyTemplate={<p className='p-m-0'>Dosyayı sürükle ve bırak</p>}
            disabled={selectedTemplate ? false : true}
          />
          <div>
            <Button
              type='button'
              onClick={createOrUpdateMailTemplate}
              label={createProcessor ? "Şablon Oluştur" : "Şablon Güncelle"}
              icon='pi pi-pencil'
              className='p-button-warning'
              disabled={
                !createProcessor
                  ? false
                  : files.length > 0 && selectedTemplate && title
                  ? false
                  : true
              }
            />
          </div>
        </div>
      )}
    </>
  );
};
export default UploadMailTemp;
