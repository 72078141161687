import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Editor } from "primereact/editor";
import { Chips } from "primereact/chips";
import { Toast } from "primereact/toast";
import GemGastroSpinner from "../components/Core/GemGastroSpinner";

import { useSelector } from "react-redux";
import { GetMailTemplates,SendMail as SendMailService } from "../service/mailService";
import NotificationHelper from "../helper/NotificationHelper"; 
const SendMail = () => {
  //cache models
  const customersModel = useSelector((state) => state.customer.customers);
  //all categories
  const [customers, setCustomers] = useState([]);
  //selected products state
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  // const [orders, setOrders] = useState([]);
  // const [products, setProducts] = useState(null);
  // const [categories, setCategories] = useState(null);
  // const [mainCategories, setMainCategories] = useState(null);
  //global filter
  const [globalFilter, setGlobalFilter] = useState(null);
  //mail title
  const [title, setTitle] = useState("");
  //selected mail template
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  // selected additional people
  const [additionalMails, setAdditionalMails] = useState([]);
  const [componentLoading, setComponentLoading] = useState(false);
  const [Body, setBody] = useState("");
  useEffect(() => {
    getAllMailTemplates();
  }, []);

  const [mailTemplates, setMailTemplates] = useState([
    { name: "SecurityCode", code: 1 },
    { name: "AfterOrder", code: 2 },
    { name: "OrderHistoryLog", code: 3 },
    { name: "Campaign", code: 4 },
    { name: "Login", code: 5 },
  ]);
  const getAllMailTemplates = () => {
    GetMailTemplates()
      .then((response) => {
        if (response["is-success"] && response.data) {
          const data = response.data;
          const result = [];
          data.map((item) => {
            const newObj = { name: item.name, code: item.mailTemplateType };
            result.push(newObj);
          });
          setMailTemplates(result);
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi"
          );
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir hata meydana geldi");
      });
  };

  const mailModels = {
    ToEmail: [],
    Subject: "",
    Body: "",
    MailTemplateId: 0,
    FormFile: null,
    ReplaceChar: "",
    MailTemplateType: 0,
  };

  //refs
  const toast = useRef(null);
  const customChip = (item) => {
    return (
      <div>
        <span>{item} - </span>
        <i className='pi pi-user-plus' style={{ fontSize: "14px" }}></i>
      </div>
    );
  };
  useEffect(() => {
    if (customersModel?.length > 0) {
      setCustomers(customersModel);
      return false;
    }
  }, [customersModel]);

  const onTemplateChange = (e) => {
    setSelectedTemplate(e.value);
  };
  const sendMail = async () => {
    setComponentLoading(true);
    mailModels.Subject = title;
    mailModels.ToEmail = selectedCustomers.map(
      (item) => item.emailAdress.emailAdress
    );
    mailModels.ToEmail.push(...additionalMails);
    mailModels.MailTemplateType = selectedTemplate;
    mailModels.Body = Body;

    SendMailService({ ...mailModels })
      .then((response) => {
        if (response["is-success"] && response.data) {
          NotificationHelper.Success(
            "Seçili kişilere mail başarılı bir şekilde gönderildi."
          );
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi"
          );
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir hata meydana geldi");
      })
      .finally(() => {
        setComponentLoading(false);
      });
  };

  return (
    <>
      {componentLoading ? (
        <GemGastroSpinner isLogin={false} />
      ) : (
        <div className='card'>
          <Toast ref={toast}></Toast>
          <h5>Yeni Mail Gönder</h5>
          <hr />
          <div className='p-grid'>
            <div className='p-col-12 p-lg-6'>
              <div className='card'>
                <h1 style={{ fontSize: "16px" }}>Müşteriler</h1>
                <DataTable
                  value={customersModel}
                  selection={selectedCustomers}
                  onSelectionChange={(e) => setSelectedCustomers(e.value)}
                  className='p-datatable-customers'
                  rows={5}
                  sortMode='multiple'
                  style={{ marginBottom: "20px" }}
                  paginator
                  globalFilter={globalFilter}
                  emptyMessage='Müşteri Bulunamadı.'
                  scrollable>
                  <Column
                    selectionMode='multiple'
                    headerStyle={{ width: "3rem" }}></Column>
                  <Column
                    field='name'
                    header='Müşteri Adı'
                    sortable
                    filter
                    filterPlaceholder='Ara'></Column>
                  <Column
                    field='surname'
                    header='Müşteri Soyadı'
                    sortable
                    filter
                    filterPlaceholder='Ara'></Column>
                  <Column
                    field='emailAdress.emailAdress'
                    header='Müşteri Email'
                    sortable
                    filter
                    filterPlaceholder='Ara'></Column>
                </DataTable>
              </div>
            </div>
            <div className='p-col-12 p-lg-6'>
              <div className='card'>
                <h5 style={{ fontSize: "16px" }}>
                  Mail İçeriğinizin Görünüşü İçin Mail Şablonu Seçiniz
                </h5>
                <Dropdown
                  value={selectedTemplate}
                  options={mailTemplates}
                  optionLabel='name'
                  optionValue='code'
                  onChange={onTemplateChange}
                  filter
                  showClear
                  filterBy='name'
                  placeholder='Mail Şablonu Seçiniz.'
                />
              </div>
              <div className='card p-fluid'>
                <h5 style={{ fontSize: "16px" }}>
                  Eklemek İstediğiniz E-Mail Adreslerinizi Girdikten Sonra
                  Enter'a basınız
                </h5>
                <Chips
                  showClear
                  value={additionalMails}
                  onChange={(e) => setAdditionalMails(e.value)}
                  itemTemplate={customChip}></Chips>
              </div>
            </div>
          </div>
          <div className='card p-fluid'>
            <span className='p-textfield'>
              <h5 htmlFor='title'>Mail Konu Başlığı</h5>
              <InputText
                className='p-inputtext'
                id='title'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </span>
          </div>
          <div className='card'>
            <h5>Mail İçeriği </h5>
            <Editor
              style={{ height: "320px" }}
              value={Body}
              onTextChange={(e) => setBody(e.htmlValue)}
            />
          </div>
          <div>
            <Button
              type='button'
              onClick={sendMail}
              label='E-Mail Gönder'
              icon='pi pi-users'
              className='p-button-warning'
              badge={selectedCustomers?.length + additionalMails?.length}
              badgeClassName='p-badge-danger'
            />
          </div>
        </div>
      )}
    </>
  );
};
export default SendMail;
