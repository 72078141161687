import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import React, { Component } from "react";
import { connect } from "react-redux";
import NotificationHelper from "../../helper/NotificationHelper";
import {
  CreateOrUpdateSupplier,
  DeleteSupplier,
  GetAllSuppliers,
} from "../../service/inventoryService";
import { setSuppliers } from "../../storage/supplier/supplierSlice";
import Confirm from "../Core/Confirm";
import GemGastroSpinner from "../Core/GemGastroSpinner";

export class Supplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridBrandLoading: false,
      createOrUpdateBrandDialog: false,
      openConfirmDialog: false,
      selectedBrand: {
        id: null,
        name: "",
      },
      dt: React.createRef(),
      componentLoading: false,
    };
  }

  brandToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className='container'>
          <div className='row'>
            <div className='col-12 d-flex justify-content-around'>
              <div>
                <Button
                  label='Marka Ekle'
                  icon='pi pi-plus'
                  className='p-button-success p-mr-2'
                  onClick={() =>
                    this.setState({ createOrUpdateBrandDialog: true }, () =>
                      this.setState({ selectedBrand: { id: null, name: "" } })
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  createOrUpdateBrand = () => {
    if (!this.state.selectedBrand.name?.trim()) {
      NotificationHelper.Error("Lütfen isim giriniz.");
      return false;
    }
    this.setState({ componentLoading: true }, () => {
      CreateOrUpdateSupplier(this.state.selectedBrand)
        .then((response) => {
          if (response["is-success"]) {
            NotificationHelper.Success("İşlem başarılı.");
            GetAllSuppliers().then((response) => {
              if (response["is-success"]) {
                this.props.setSuppliers(response.data);
                this.setState({ createOrUpdateBrandDialog: false });
              } else {
                NotificationHelper.Error(
                  "Markalar alınamadı.Yaptığınız güncellemeyi görmek için lütfen sayfayı yenileyiniz."
                );
              }
            });
          } else {
            NotificationHelper.Error(
              response.userMessage ||
                "Marka eklenemedi.Lütfen tekrar deneyiniz."
            );
          }
        })
        .finally(() => {
          this.setState({ componentLoading: false });
        });
    });
  };
  brandDialogFooter = (
    <React.Fragment>
      <Button
        label='İptal'
        icon='pi pi-times'
        className='p-button-text'
        onClick={() => this.setState({ createOrUpdateBrandDialog: false })}
      />
      <Button
        label='Kaydet'
        icon='pi pi-check'
        className='p-button-text'
        onClick={this.createOrUpdateBrand}
      />
    </React.Fragment>
  );
  confirmDeleteSelectedBrand = (rowData) => {
    this.setState({ selectedBrand: rowData });
    this.setState({ openConfirmDialog: true });
  };
  editBrand = (rowData) => {
    this.setState({ selectedBrand: rowData });
    this.setState({ createOrUpdateBrandDialog: true });
  };
  deleteBrand = () => {
    const selectedBrandId = this.state.selectedBrand?.id;
    if (selectedBrandId > 0) {
      this.setState({ componentLoading: true }, () => {
        DeleteSupplier(selectedBrandId)
          .then((response) => {
            if (response["is-success"]) {
              const newModel = this.props.suppliers.filter(
                (x) => x.id != selectedBrandId
              );
              NotificationHelper.Success("Marka başarılı bir şekilde silindi.");
              this.props.setSuppliers(newModel);
            }
          })
          .catch(() => {
            NotificationHelper.Error("Bir hata meydana geldi.");
          })
          .finally(() => {
            this.setState({ componentLoading: false });
          });
      });

      this.setState({ openConfirmDialog: false });
      this.setState({
        selectedBrand: {
          id: null,
          name: "",
        },
      });
    } else {
      NotificationHelper.Error("Lütfen bir marka seçiniz.");
    }
  };
  actionBodyTemplateBrand = (rowData) => {
    return (
      <React.Fragment>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Button
            icon='pi pi-pencil'
            className='p-button-success'
            onClick={() => this.editBrand(rowData)}
          />
          <Button
            label='Sil'
            icon='pi pi-trash'
            className='p-button-danger w-50'
            onClick={() => this.confirmDeleteSelectedBrand(rowData)}
          />
        </div>
      </React.Fragment>
    );
  };
  render() {
    return (
      <>
        <Confirm
          visible={this.state.openConfirmDialog}
          setVisible={(p) => this.setState({ openConfirmDialog: p })}
          callBack={this.deleteBrand}
          confirmMessage={"Seçilen marka silinecek emin misiniz ?"}
        />

        <Dialog
          visible={this.state.createOrUpdateBrandDialog}
          style={{ width: "450px" }}
          header={`${
            this.selectedBrand?.id !== null
              ? `Marka Güncelleme`
              : `Marka Oluşturma`
          }`}
          modal
          footer={this.brandDialogFooter}
          onHide={() => this.setState({ createOrUpdateBrandDialog: false })}>
          <div className='row ml-2'>
            <label htmlFor='name'>Marka Adı </label>{" "}
          </div>
          <div className='row p-field ml-2'>
            <InputText
              id='name'
              value={this.state.selectedBrand?.name}
              onChange={(e) =>
                this.setState({
                  selectedBrand: {
                    id: this.state.selectedBrand?.id,
                    name: e.target.value,
                  },
                })
              }
              required
              autoFocus
            />
          </div>
        </Dialog>
        <Dialog
          visible={this.props.brandDialog}
          style={{ width: "600px" }}
          header={`Markaları Yönet`}
          modal
          className='p-fluid'
          //footer={brandDialogFooter}
          onHide={() => this.props.setBrandDialog(false)}>
          {!this.state.componentLoading ? (
            <>
              <div className='card'>
                <Toolbar
                  className='p-mb-4'
                  left={this.brandToolbarTemplate}></Toolbar>

                <DataTable
                  ref={this.state.dt}
                  value={this.props.suppliers}
                  selection={this.state.selectedBrand}
                  onSelectionChange={(e) =>
                    this.setState({
                      selectedBrand: { id: e.value?.id, name: e.value.name },
                    })
                  }
                  dataKey='id'
                  paginator
                  rows={10}
                  emptyMessage='Marka bulunamadı.'
                  //header={header}
                  loading={this.gridBrandLoading}>
                  <Column
                    selectionMode='multiple'
                    headerStyle={{ width: "3rem" }}></Column>
                  <Column field='name' header='Marka Adı' sortable></Column>
                  <Column body={this.actionBodyTemplateBrand}></Column>
                </DataTable>
              </div>
            </>
          ) : (
            <GemGastroSpinner />
          )}
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  suppliers: state.supplier.suppliers,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setSuppliers: (suppliers) => dispatch(setSuppliers(suppliers)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Supplier);
