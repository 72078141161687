import { createContext, useState, useEffect } from "react";
import { useHistory,useLocation } from "react-router-dom";
import CookieHelper from "../helper/CookieHelper";
import { CheckToken } from "../service/userService";
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
   const [isValidUser, setIsValidUser] = useState(false);
  const history = useHistory();
  useEffect(() => {
    const currentUser = CookieHelper.currentUser;
    if (!currentUser) {
      logout();
    }else{
      CheckToken(CookieHelper.getCookie(CookieHelper.userCookieName)).then((response)=>{
        if(response?.["is-success"]){
          if(!response.data){
            logout();
          }else{
            setUser(currentUser);
            setIsValidUser(true);
          }
        }else{
          logout();
        }
      })
    }
  }, []);

  const logout = () => {
    setIsValidUser(false);
    setUser({}); 
    if(history){
      history.push('/login')
    }else{
      window.open('/login','_self')
    }
    
  }

  const values = {
    user,
    isValidUser,
    setIsValidUser,
    logout
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export default AuthContext;
