import { Button } from "primereact/button";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import NotificationHelper from "../../helper/NotificationHelper";
import { DeleteProducts } from "../../service/productService";
import { deleteProducts as deleteProductOnClient } from "../../storage/product/productSlice";
import Confirm from "../Core/Confirm";

function DeleteProduct({ selectedProducts, setSelectedProducts,setComponentLoading }) {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [confirmDialogMessage, setConfirmDialogMessage] = useState(
    "Seçili ürünleri silmek istediğinize emin misiniz?"
  ); 
  const dispatch = useDispatch();

  const deleteProducts = () => {
    setComponentLoading(true);
    setOpenConfirmDialog(false);
    const productIds = selectedProducts.map((p) => p.id);
    DeleteProducts(productIds)
      .then((response) => {
        if (response["is-success"]) {
          dispatch(deleteProductOnClient(productIds));
          NotificationHelper.Success(
            "Silme işlemi başarılı bir şekilde sonuçlandı"
          );
          setSelectedProducts(undefined);
      
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi."
          );
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir hata meydana geldi.");
      }).finally(()=>setComponentLoading(false));
  };

  return (
    <>
      <Confirm
        visible={openConfirmDialog}
        setVisible={(p) => setOpenConfirmDialog(p)}
        callBack={deleteProducts}
        confirmMessage={confirmDialogMessage}
      />
      <Button
        label='Ürün Sil'
        icon='pi pi-trash'
        className='p-button-danger mx-3'
        onClick={() => setOpenConfirmDialog(true)}
        disabled={!selectedProducts || !selectedProducts.length}
      />
    </>
  );
}

export default DeleteProduct;
