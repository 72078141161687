import React, { Component } from "react";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Ripple } from "primereact/ripple";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import CategoryHelper from "../helper/CategoryHelper";
import ProductHelper from "../helper/ProductHelper";
import { GetCategoryDetail } from "../service/categoryService";
import CreateOrUpdateCategory from "../components/Category/CreateOrUpdateCategory";
import ProductCategoryTransfer from "../components/Product/ProductCategoryTransfer";

import { Toolbar } from "primereact/toolbar";
import { Divider } from "primereact/divider";

import {
  onChangeSelectedCategories,
  setCategoryDetail,
  setCategories,
  setSelectedCategory,
  setSelectedCategories,
  setLoading,
} from "../storage/category/categorySlice";
import Confirm from "../components/Core/Confirm";
import { AdminGetAllCategories, AdminGetAllCategory } from "../service/inventoryService";
import NotificationHelper from "../helper/NotificationHelper";
import GetAllCategories from "../components/Category/GetAllCategories";
import DeleteCategory from "../components/Category/DeleteCategory";
export class Category extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCategories: null,
      first1: 0,
      globalFilter: null,
      currentPage: 1,
      pageInputTooltip: "Press 'Enter' key to go to this page.",
      rows: 10,
      dt: React.createRef(),
      editModel: null,
      openCreateOrUpdateCategory: false,
      openConfirmDialog: false,
      confirmDialogMessage:
        "Seçili kategorileri silmek istediğinize emin misiniz?",
      selectedTransferCategory: null,
      pureData: [],
      data: []
    };
  }
  componentDidMount() {
    if (!this.props.categories?.length) {
      setLoading(true);
      AdminGetAllCategories()
        .then((response) => {
          if (response["is-success"]) {
            setCategories(response.data);
            this.setState({ pureData: response.data, data: response.data });
          } else {
            NotificationHelper.Error(
              response.userMessage || "Bir hata meydana geldi."
            );
          }
        })
        .finally(() => setLoading(false));
    } else {
      this.setState({ pureData: this.props.categories, data: this.props.categories });
    }
  } 

  editCategory = (category) => {
    this.props.setLoading(true);
    this.props.setSelectedCategory(category);

    this.setState({ editModel: this.props.selectedCategory }, () => {
      this.setState({ openCreateOrUpdateCategory: true });
      this.props.setLoading(false);
    });
  };

  onPageInputKeyDown = (event, options) => {
    if (event.key === "Enter") {
      const page = parseInt(this.state.currentPage);
      if (page < 0 || page > options.totalPages) {
        this.setState({
          pageInputTooltip: `Value must be between 1 and ${options.totalPages}.`,
        });
      } else {
        const first = this.state.currentPage ? options.rows * (page - 1) : 0;

        this.setState({ first1: first });

        this.setState({
          pageInputTooltip: "Press 'Enter' key to go to this page.",
        });
      }
    }
  };
  onPageInputChange = (event) => {
    this.setState({ currentPage: event.target.value });
  };
  onCustomPage1 = (event) => {
    this.setState({ first1: event.first });
    this.setState({ rows: event.rows });
    this.setState({ currentPage: event.page + 1 });
  };
  gridHeader = (
    <div className='table-header'>
      <h5 className='p-m-1'>Kategori Kodu</h5>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onChange={(e) => {
            if(this.state.pureData?.length){
              const newData = this.state.pureData.filter(x => x.id.toString().includes(e.target.value));
              this.setState({ data: newData });
            } 
          }}
          placeholder='Search...'
        />
      </span>
      <h5 className='p-m-1'>Kategori Adı</h5>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onChange={(e) => {
            if(this.state.pureData?.length){
              const newData = this.state.pureData.filter(x => x.categoryLocalizations?.find(x => x.localizationId == 1)?.categoryName.toLowerCase().includes(e.target.value.toLowerCase()));
              this.setState({ data: newData });
            } 
          }}
          placeholder='Search...'
        />
      </span>
    </div>
  );

  leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className='container'>
          <div className='row'>
            <div className='col-12 d-flex '>
              <div>
                <Button
                  label='Kategori Ekle'
                  icon='pi pi-plus'
                  className='p-button-success p-mr-2'
                  onClick={() => {
                    this.props.setSelectedCategory({});
                    this.setState({ openCreateOrUpdateCategory: true });
                  }}
                />
                <DeleteCategory
                  selectedCategories={this.state.selectedCategories}
                  setSelectedCategories={(p) =>
                    this.setState({ selectedCategories: p })
                  }
                  setComponentLoading={(p) => this.props.setLoading(p)}
                />
                <GetAllCategories
                  setComponentLoading={(p) => this.props.setLoading(p)}
                  setPureData={(p) => this.setState({ pureData: p, data: p })}

                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  render() {
    const paginatorTemplate = {
      layout:
        "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport",
      PrevPageLink: (options) => {
        return (
          <button
            type='button'
            className={options.className}
            onClick={options.onClick}
            disabled={options.disabled}>
            <span className='p-p-3'>Önceki</span>
            <Ripple />
          </button>
        );
      },
      NextPageLink: (options) => {
        return (
          <button
            type='button'
            className={options.className}
            onClick={options.onClick}
            disabled={options.disabled}>
            <span className='p-p-3'>Sonraki</span>
            <Ripple />
          </button>
        );
      },
      PageLinks: (options) => {
        if (
          (options.view.startPage === options.page &&
            options.view.startPage !== 0) ||
          (options.view.endPage === options.page &&
            options.page + 1 !== options.totalPages)
        ) {
          const className = classNames(options.className, { "p-disabled": true });

          return (
            <span className={className} style={{ userSelect: "none" }}>
              ...
            </span>
          );
        }

        return (
          <button
            type='button'
            className={options.className}
            onClick={options.onClick}>
            {options.page + 1}
            <Ripple />
          </button>
        );
      },
      RowsPerPageDropdown: (options) => {
        const dropdownOptions = [
          { label: 10, value: 10 },
          { label: 20, value: 20 },
          { label: 30, value: 30 },
          { label: 40, value: 40 },
          { label: 50, value: 50 },
        ];

        return (
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
            appendTo={document.body}
          />
        );
      },
      CurrentPageReport: (options) => {
        return (
          <span
            className='p-mx-3'
            style={{ color: "var(--text-color)", userSelect: "none" }}>
            Go to{" "}
            <InputText
              size='2'
              className='p-ml-1'
              value={this.state.currentPage}
              tooltip={this.state.pageInputTooltip}
              onKeyDown={(e) => this.onPageInputKeyDown(e, options)}
              onChange={this.onPageInputChange}
            />
          </span>
        );
      },
    };



    return (
      <div className='datatable-crud-demo'>
        <Toolbar
          className='p-mb-4 w-100'
          left={this.leftToolbarTemplate}></Toolbar>
        <DataTable
          ref={this.state.dt}
          value={this.state.data}
          selection={this.state.selectedCategories}
          onSelectionChange={(e) =>
            this.setState({ selectedCategories: e.value })
          }
          dataKey='id'
          paginator
          rows={this.state.rows}
          rowsPerPageOptions={[5, 10, 20, 25, 50]}
          paginatorTemplate={paginatorTemplate}
          first={this.state.first1}
          onPage={this.onCustomPage1}
          globalFilter={this.state.globalFilter}
          emptyMessage='Kategori bulunamadı.'
          header={this.gridHeader}
          loading={this.props.loading}>
          <Column
            selectionMode='multiple'
            headerStyle={{ width: "3rem" }}></Column>
          <Column field='id' header='Kategori Kodu' sortable></Column>
          <Column
            body={CategoryHelper.categoryNameTemplate}
            header='Kategori Adı'
            sortable></Column>
          <Column
            field='description'
            header='Açıklama'
            sortable
            body={CategoryHelper.categoryDescriptionNameTemplate}></Column>

          {/* <Column field="rating" header="Reviews" body={ratingBodyTemplate} sortable></Column> */}
          <Column
            field='categoryImages'
            header='Kategori Resmi'
            body={CategoryHelper.imageBodyTemplate}></Column>
          <Column
            body={(p) =>
              CategoryHelper.actionBodyTemplate(p, this.editCategory)
            }></Column>
        </DataTable>

        {this.state.openCreateOrUpdateCategory && (
          <CreateOrUpdateCategory
            visible={this.state.openCreateOrUpdateCategory}
            setVisible={(val) =>
              this.setState({ openCreateOrUpdateCategory: val })
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.category.categories,
    selectedCategories: state.category.selectedCategories,
    localizations: state.localization.localizations,
    inventoryLoading: state.inventory.loading,
    loading: state.category.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedCategory: (category) => dispatch(setSelectedCategory(category)),
    setCategories: (categories) => dispatch(setCategories(categories)),
    setLoading: (val) => dispatch(setLoading(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);
