import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React from "react";
import ProductHelper from "./ProductHelper";

export default class CustomerHelper {
  static findById(customerId, customers) {
    return customers.find((x) => x.id == customerId);
  }
  static customerNameTemplate = (customer) => {
    return (
      <span>
        {(customer?.length &&
          customer?.name) ||
          ""}
      </span>
    );
  };
  static actionBodyTemplate = (customer, editFunc) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-info"
          className="p-button-rounded p-button-info p-mr-2 mr-4"
          onClick={() => editFunc(customer)}
        />
        {/* <Button
					icon="pi pi-trash"
					className="p-button-rounded p-button-warning"
					onClick={() => confirmDeleteProduct(customer)}
				/> */}
      </React.Fragment>
    );
  };
}
