import { Button } from "primereact/button";
import React from "react";
import { useDispatch } from "react-redux";
import NotificationHelper from "../../helper/NotificationHelper";
import { AdminGetAllCategories } from "../../service/inventoryService";
import { setCategories } from "../../storage/category/categorySlice";

function GetAllCategories( {setComponentLoading, setPureData}) {
  const dispatch = useDispatch();
  const reload = () => {
    setComponentLoading(true)
    AdminGetAllCategories().then((response) => {
      if (response["is-success"]) {
        dispatch(setCategories(response.data));
        setPureData(response.data)
      } else {
        NotificationHelper.Error(
          response.userMessage || "Bir hata meydana geldi."
        );
      }
    }).finally(()=>setComponentLoading(false));
  };
  return (
    <Button
      label='Yenile'
      icon='pi pi-refresh'
      className='p-button mx-3'
      onClick={()=>reload()}
    />
  );
}

export default GetAllCategories;
