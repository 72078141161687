import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { TabPanel, TabView } from "primereact/tabview";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import GemGastroSpinner from "../components/Core/GemGastroSpinner";
import ProductSelectGrid from "../components/Product/ProductSelectGrid";
import NotificationHelper from "../helper/NotificationHelper";
import ProductHelper from "../helper/ProductHelper";
import { EditHomePageProducts, GetHomePage } from "../service/inventoryService";
import { Card } from "primereact/card";
import Confirm from "../components/Core/Confirm";

function ProductSettings() {
  const products = useSelector((state) => state.product.products);
  const [newProducts, setNewProducts] = useState([]);
  const [discountProducts, setDiscountProducts] = useState([]);
  const [mostSaleProducts, setMostSaleProducts] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [activeDialog, setActiveDialog] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getHomePageItems();
  }, []);

  const openProductDialog = (dialogVal) => {
    setActiveDialog(dialogVal);
    setDialogOpen(true);
  };
  const getHomePageItems = () => {
    setLoading(true);
    GetHomePage()
      .then((response) => {
        if (response["is-success"]) {
          const mostSaleProductIds = response.lookup.bestProducts.map(
            (i) => i.id
          );
          const newProductIds = response.lookup.newProducts.map((i) => i.id);
          const discountedProductIds = response.lookup.discountedProducts.map(
            (i) => i.id
          );
          const resultMostSale = products.filter((x) =>
            mostSaleProductIds.includes(x.id)
          );
          const resultNew = products.filter((x) =>
            newProductIds.includes(x.id)
          );
          const resultDis = products.filter((x) =>
            discountedProductIds.includes(x.id)
          );

          setNewProducts(resultNew);
          setMostSaleProducts(resultMostSale);
          setDiscountProducts(resultDis);
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi"
          );
        }
      })
      .catch((err) => {
        NotificationHelper.Error("Bir hata meydana geldi");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveChanges = () => {
    setLoading(true);
    const settings =
      [
        {
          Type: 1,
          Products: mostSaleProducts.map(i => i.id)
        }, {
          Type: 2,
          Products: discountProducts.map(i => i.id)
        },
        {
          Type: 3,
          Products: newProducts.map(i => i.id)
        }
      ]
    EditHomePageProducts({Settings:settings})
      .then((response) => {
        if (response?.["is-success"] && response.data) {
          NotificationHelper.Success("Ürün Slayt ayarları güncellendi");
          getHomePageItems();
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi"
          );
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir hata meydana geldi");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveHeader = () => {
    return (
      <Button className="btn-success" onClick={() => saveChanges()}>
        Değişiklikleri kaydet
      </Button>
    );
  };

  return (
    <div className="container">
      <div className="row">
        {loading ? (
          <GemGastroSpinner isLogin={false} />
        ) : (
          <div
            className="col-12 d-flex justify-content-center align-items-center"
            style={{ flexDirection: "column" }}
          >
            <Card
              title={saveHeader}
              style={{
                marginBottom: "2em",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TabView header={saveHeader}>
                <TabPanel header="Yeni Ürünler">
                  <div className="row">
                    <div className="col-12">
                      <Button onClick={() => openProductDialog("newProducts")}>
                        Düzenlemek için tıklayınız.
                      </Button>
                    </div>
                    <div className="col-12 mt-4">
                      <ProductSelectGrid
                        products={newProducts}
                        selectedProducts={newProducts}

                        disable={true}
                        maxRowNumber={5}
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel header="İndirimli Ürünler">
                  <div className="row">
                    <div className="col-12">
                      <Button
                        onClick={() => openProductDialog("discountProducts")}
                      >
                        Düzenlemek için tıklayınız.
                      </Button>
                    </div>
                    <div className="col-12 mt-4">
                      <ProductSelectGrid
                        products={discountProducts}
                        selectedProducts={discountProducts}

                        disable={true}
                        maxRowNumber={5}
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel header="En Çok Satılan Ürünler">
                  <div className="row">
                    <div className="col-12">
                      <Button
                        onClick={() => openProductDialog("mostSaleProducts")}
                      >
                        Düzenlemek için tıklayınız.
                      </Button>
                    </div>
                    <div className="col-12 mt-4">
                      <ProductSelectGrid
                        products={mostSaleProducts}
                        selectedProducts={mostSaleProducts}

                        disable={true}
                        maxRowNumber={5}
                      />
                    </div>
                  </div>
                </TabPanel>
              </TabView>
            </Card>

            <Dialog
              onHide={() => setDialogOpen(false)}
              visible={dialogOpen}
              modal
              className="p-fluid"
              style={{ width: "1150px" }}
              header={() => (
                <p className="h7 text-muted">Lütfen Ürün Seçiniz</p>
              )}
            >
              <div
                className={`${activeDialog == "newProducts" ? "" : "d-none"}`}
              >
                <ProductSelectGrid
                  products={products}
                  selectedProducts={newProducts}
                  setSelectedProducts={(p) => setNewProducts(p)}
                />
              </div>
              <div
                className={`${activeDialog == "discountProducts" ? "" : "d-none"
                  }`}
              >
                <ProductSelectGrid
                  products={products}
                  selectedProducts={discountProducts}
                  setSelectedProducts={(p) => setDiscountProducts(p)}
                />
              </div>
              <div
                className={`${activeDialog == "mostSaleProducts" ? "" : "d-none"
                  }`}
              >
                <ProductSelectGrid
                  products={products}
                  selectedProducts={mostSaleProducts}
                  setSelectedProducts={(p) => setMostSaleProducts(p)}
                />
              </div>
            </Dialog>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductSettings;
