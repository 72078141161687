import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import GemGastroSpinner from "../components/Core/GemGastroSpinner";
import NotificationHelper from "../helper/NotificationHelper";
import {
  GetPaymentServiceSettings,
  UpdatePaymentServiceSettings,
} from "../service/inventoryService";

function PaymentServiceSettings() {
  const [paymentServiceSettings, setPaymentServiceSettings] = useState([]);
  const [providerTypes, setProviderTypes] = useState([{ name: "Albis", val: 1 }, { name: "Payone", val: 2 }])
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPaymentServiceSettings();
  }, [])


  const getPaymentServiceSettings = () => {
    setLoading(true);
    GetPaymentServiceSettings()
      .then((response) => {
        if (response["is-success"]) {
          const responseLookup = response.lookup;

          const result = [];

          Object.keys(responseLookup).forEach((key) => {
            responseLookup[key].forEach((pS) => result.push(pS));
          });

          setPaymentServiceSettings(result);
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi"
          );
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir hata meydana geldi");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFormSubmit = () => {
    setLoading(true);
    UpdatePaymentServiceSettings(paymentServiceSettings)
      .then((response) => {
        if (response["is-success"] && response?.data) {
          NotificationHelper.Success("Değişiklikler kaydedildi.");
          getPaymentServiceSettings();
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi"
          );
        }
      })
      .catch((err) => {
        NotificationHelper.Error("Bir hata meydana geldi");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangePaymentSetting = (val, paymentSetting) => {
    setPaymentServiceSettings(paymentServiceSettings.map((x) => {
      if (x.settingType == paymentSetting.settingType && x.providerType == paymentSetting.providerType)
        x.value = val;
      return x;
    }));
  }

  return (
    <>
      {paymentServiceSettings?.length && !loading && providerTypes.length ? (
        <Card footer={()=>{
          return (
            <div className="d-flex justify-content-center">
              
            <Button
              type='button'
              onClick={onFormSubmit}
              label='Değişiklikleri Kaydet'
              icon='pi pi-pencil'
              className='p-button-warning'
            /> 
            </div>
          )
        }} title="Ödeme Ayarları" style={{width:'600px',margin:'auto', maxHeight:'80vh',overflowY:'scroll'}}>
          
          <TabView>
            {providerTypes.map((providerType) => {
              return (
                <TabPanel header={providerType.name}>
                  <div className="row">
                    <div className="col-12">
                      {paymentServiceSettings?.filter(x => x.providerType == providerType.val).map((paymentSetting) => (
                        <div className='form-group'>
                          <label
                            className='paymentSettingFormItemLabel'
                            htmlFor={paymentSetting.name}>
                            {paymentSetting.name}
                          </label>
                          <input
                            id={paymentSetting.name}
                            onChange={(e) =>
                              onChangePaymentSetting(e.target.value, paymentSetting)
                            }
                            value={paymentSetting.value}
                            className='form-control'
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </TabPanel>
              )
            })}
          </TabView>  
        </Card>
      ) : (
        <GemGastroSpinner />
      )}
    </>
  );
}

export default PaymentServiceSettings;
