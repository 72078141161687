import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import {
  createNewTechnicalInfo,
  createNewTechnicalValue,
  deleteNewTechnical,
  deleteNewTechnicalValue,
  onChangeSelectedTechnicalInfoLocalization,
  onChangeSelectedTechnicalInfoValueLocalization,
  setSelectedProduct,
} from "../../storage/product/productSlice";
import { useDispatch, useSelector } from "react-redux";
import ProductHelper from "../../helper/ProductHelper";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import GemGastroSpinner from "../Core/GemGastroSpinner";

function CreateOrUpdateProductTechnical({ visible, setVisible }) {
  const selectedProduct = useSelector((state) => state.product.selectedProduct);
  const localizations = useSelector(
    (state) => state.localization.localizations
  );
  const dispatch = useDispatch();

  const [tabActiveIndex, setTabActiveIndex] = useState(0);
  const [accardoionActiveIndex, setAccardionActiveIndex] = useState(0);
  const [componentLoading, setComponentLoading] = useState(false);

  const onChangeTechnical = (val, productTechnicalInfoLocalization) => {
    let model = {
      val,
      productTechnicalInfoLocalization,
    };
    dispatch(onChangeSelectedTechnicalInfoLocalization(model));
  };
  const onChangeTechnicalValue = (
    key,
    val,
    productTechnicalInfoValueLocalization
  ) => {
    let model = {
      key,
      val,
      productTechnicalInfoValueLocalization,
    };
    dispatch(onChangeSelectedTechnicalInfoValueLocalization(model));
  };
  return (
    <Dialog
      visible={visible}
      style={{ width: "750px" }}
      header={`${
        selectedProduct?.productLocalizations?.length
          ? `Ürün Teknik Bilgileri Güncelleme`
          : `Ürün Teknik Bilgileri Oluşturma`
      }`}
      modal
      onHide={() => setVisible(false)}>
      {componentLoading ? (
        <GemGastroSpinner />
      ) : (
        <TabView
          activeIndex={tabActiveIndex}
          onTabChange={(e) => setTabActiveIndex(e.index)}>
          {selectedProduct?.productTechnicalInfos.length &&
            localizations?.map((localization, i) => {
              const productTechnicalInfoLocalizations =
                ProductHelper.getProductTechnicalInfoLocalizations(
                  selectedProduct?.productTechnicalInfos || [],
                  localization
                );

              const productTechnicalInfoValueLocalizations =
                ProductHelper.getProductTechnicalInfoValueLocalizations(
                  selectedProduct?.productTechnicalInfos || [],
                  localization
                );
              return (
                <TabPanel header={localization.language}>
                  <Accordion
                    activeIndex={accardoionActiveIndex}
                    onTabChange={(e) => setAccardionActiveIndex(e.index)}>
                    {productTechnicalInfoLocalizations.map(
                      (productTechInfoLocalization) => (
                        <AccordionTab
                          header={
                            <>
                              <InputText
                                value={productTechInfoLocalization.techName}
                                onChange={(e) =>
                                  onChangeTechnical(
                                    e.target.value,
                                    productTechInfoLocalization
                                  )
                                }
                              />
                            </>
                          }>
                          {ProductHelper.filterTechnicalValueLocalizationsByInfoLocalization(
                            productTechInfoLocalization,
                            selectedProduct
                          ).map((productTechnicalInfoValueLocalization) => {
                            return (
                              <div className='d-flex justify-content-around align-items-center w-100'>
                                <div className='p-field'>
                                  <label htmlFor='discountedProduct'>
                                    Parametre Adı
                                  </label>
                                  <br />
                                  <InputText
                                    id='discountedProduct'
                                    name='parameterName'
                                    value={
                                      productTechnicalInfoValueLocalization.parameterName
                                    }
                                    onChange={(e) =>
                                      onChangeTechnicalValue(
                                        e.target.name,
                                        e.target.value,
                                        productTechnicalInfoValueLocalization
                                      )
                                    }
                                  />
                                </div>
                                <div className='p-field'>
                                  <label htmlFor='discountedProduct'>
                                    Değeri
                                  </label>
                                  <br />
                                  <InputText
                                    id='discountedProduct'
                                    name='value'
                                    value={
                                      productTechnicalInfoValueLocalization.value
                                    }
                                    onChange={(e) =>
                                      onChangeTechnicalValue(
                                        e.target.name,
                                        e.target.value,
                                        productTechnicalInfoValueLocalization
                                      )
                                    }
                                  />
                                </div>
                                {productTechnicalInfoValueLocalization.randomTechnicalValueId &&
                                  productTechnicalInfoValueLocalization.randomTechnicalValueId !=
                                    "" &&
                                  ProductHelper.filterTechnicalValueLocalizationsByInfoLocalization(
                                    productTechInfoLocalization,
                                    selectedProduct
                                  )?.[0].randomTechnicalValueId !=
                                    productTechnicalInfoValueLocalization.randomTechnicalValueId && (
                                    <Button
                                      label='Parametre Sil '
                                      icon='pi pi-plus'
                                      
                                      onClick={() => {
                                        setComponentLoading(true);
                                        let model = {
                                          valueId:
                                            productTechnicalInfoValueLocalization.randomTechnicalValueId,
                                          techId:
                                            productTechInfoLocalization.productTechnicalInfoId ||
                                            productTechInfoLocalization.randomTechnicalInfoId,
                                        };
                                        dispatch(
                                          deleteNewTechnicalValue(model)
                                        );
                                        setComponentLoading(false);
                                      }}></Button>
                                  )}
                              </div>
                            );
                          })}
                          <Button
                            label='Parametre Ekle'
                            icon='pi pi-plus'
                            onClick={() => {
                              setComponentLoading(true);
                              let model = {
                                productTechInfoLocalization,
                                localizations,
                              };
                              dispatch(createNewTechnicalValue(model));
                              setComponentLoading(false);
                            }}></Button>

                          {productTechInfoLocalization.randomTechnicalInfoId &&
                            productTechInfoLocalization.randomTechnicalInfoId !=
                              "" &&
                            selectedProduct?.productTechnicalInfos?.[0]
                              .randomTechnicalInfoId !=
                              productTechInfoLocalization.randomTechnicalInfoId && (
                              <Button
                                label={"Teknik Bilgisi Sil"}
                                className='ml-2'
                                icon='pi pi-plus'
                                onClick={() => {
                                  setComponentLoading(true);
                                  dispatch(
                                    deleteNewTechnical(
                                      productTechInfoLocalization.randomTechnicalInfoId
                                    )
                                  );
                                  setComponentLoading(false);
                                }}
                              />
                            )}
                        </AccordionTab>
                      )
                    )}
                  </Accordion>
                  <Button
                    label={"Teknik Bilgisi Ekle"}
                    icon='pi pi-plus'
                    onClick={() => {
                      setComponentLoading(true);
                      dispatch(createNewTechnicalInfo(localizations));
                      setComponentLoading(false);
                    }}
                  />
                </TabPanel>
              );
            })}
        </TabView>
      )}
    </Dialog>
  );
}
export default CreateOrUpdateProductTechnical;
