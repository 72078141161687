import { createSlice } from "@reduxjs/toolkit";

export const customerSlice = createSlice({
  name: "customer",
  initialState: {
    customers: [],
    loading: false,
  },
  reducers: {
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
    setSelectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload;
    },
    setLoading:(state,action) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCustomers, setSelectedCustomer,setLoading } = customerSlice.actions;

export default customerSlice.reducer;
