import { InputText } from "primereact/inputtext";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductHelper from "../../helper/ProductHelper";
import {
  onCargoChange,
  onCargoPriceChange,
} from "../../storage/order/orderSlice";
import { InputNumber } from "primereact/inputnumber";

function OrderDetails({ orderDetails }) {
  const products = useSelector((state) => state.product.products);
  const cargos = useSelector((state) => state.cargo.cargos);
  const suppliers = useSelector((state) => state.supplier.suppliers);
  const selectedOrder = useSelector((state) => state.order.selectedOrder);
  const dispatch = useDispatch();
  return (
    <div className='p-field'>
      <p
        className='text-muted'
        style={{
          fontSize: "16px",
          borderBottom: "1px solid #aaa",
        }}>
        Sipariş Detayları
      </p>
      <div className='p-grid'>
        {orderDetails.length &&
          orderDetails.map((orderDetail, i) => {
            const product = products.find((x) => x.id == orderDetail.productId);
            const supplier = suppliers.find((x) => x.id == product?.supplierId);

            return (
              <div className='row' style={{ margin: 0 }}>
                <div className='row main align-items-center'>
                  <div className='col-12 d-flex justify-content-start'>
                    <h5
                      style={{
                        borderLeft: "1px solid red",
                        fontSize: "14px",
                        padding: "8px",
                        paddingLeft:"15px"
                        
                      }}>
                      {i + 1} ) {product?.productLocalizations[0].productName}
                    </h5>
                  </div>
                  <div className='col-2 d-flex  justify-content-center'>
                    {ProductHelper.imageBodyTemplate(product)}
                  </div>
                  <div className='col-10'>
                    <div className='row'>
                      <div className='col'>
                        <h5
                          className='text-start text-muted'
                          style={{
                            borderBottom: "1px solid #aaa",
                            fontSize: "16px",
                          }}>
                          Marka
                        </h5>
                        <p className=''>{supplier?.name}</p>
                      </div>
                      <div className='col'>
                        <h5
                          className='text-start text-muted'
                          style={{
                            borderBottom: "1px solid #aaa",
                            fontSize: "16px",
                          }}>
                          Adet
                        </h5>
                        <input
                          className={`form-control`}
                          value={`${orderDetail.quantity}`}
                          disabled={true}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            
                          }}
                        />
                      </div>
                      <div className='col'>
                        <h5
                          className='text-start text-muted'
                          style={{
                            borderBottom: "1px solid #aaa",
                            fontSize: "16px",
                          }}>
                          Fiyat (Ürün fiyatı x Adet)
                        </h5>
                        <h3>
                          <span className='badge badge-warning'>
                            {orderDetail.totalPrice
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            €
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                  {selectedOrder.cargoOption == 2 && (
                    <div className='col-12 d-flex justify-content-end align-items-center my-2'>
                      <div className='w-25 mr-2'>
                        <select
                          className='form-control'
                          value={orderDetail?.cargoId || 0}
                          onChange={(e) => {
                            let request = {
                              cargoId: e.target.value,
                              productId: orderDetail.productId,
                            };
                            dispatch(onCargoChange(request));
                            return e;
                          }}
                          disabled={selectedOrder.status != 1}
                          >
                          <option value={0}>Kargo seçiniz...</option>
                          {cargos?.map((cargo) => (
                            <option value={cargo.cargoId}>{cargo.name}</option>
                          ))}

                        </select>
                      </div>
                      <div className='w-25 mr-2'>
                        <InputNumber
                          inputId='locale-german'
                          disabled={selectedOrder.status != 1}
                          value={orderDetail.cargoPrice || 0}
                          onValueChange={(e) => {
                            let request = {
                              cargoPrice: e.value,
                              productId: orderDetail.productId,
                            };
                            dispatch(onCargoPriceChange(request));
                            return e;
                          }}
                          mode='decimal'
                          locale='de-DE'
                          currency='EUR'
                          minFractionDigits={2}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default OrderDetails;
