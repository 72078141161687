import { Button } from "primereact/button";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationHelper from "../../helper/NotificationHelper";
import { DeleteCategories } from "../../service/categoryService";
import { deleteCategories as deleteCategoriesOnClient } from "../../storage/category/categorySlice";
import { deleteProducts } from "../../storage/product/productSlice";
import Confirm from "../Core/Confirm";

function DeleteCategory({
  selectedCategories,
  setSelectedCategories,
  setComponentLoading,
}) {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [confirmDialogMessage, setConfirmDialogMessage] = useState(
    `Seçili kategorileri silmek istediğinize emin misiniz?`
  );

  const dispatch = useDispatch();
  const products = useSelector(state => state.product.products);

  const deleteCategories = () => {
    setComponentLoading(true);
    const categoryIds = selectedCategories.map((p) => p.id);
    DeleteCategories(categoryIds)
      .then((response) => {
        if (response["is-success"]) {
          const productIds = products.filter(x => categoryIds.includes(x.categoryId)).map(i => i.id);
          dispatch(deleteCategoriesOnClient(categoryIds));
          dispatch(deleteProducts(productIds))
          NotificationHelper.Success(
            "Silme işlemi başarılı bir şekilde sonuçlandı"
          );
          setSelectedCategories(undefined);
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi."
          );
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir hata meydana geldi.");
      })
      .finally(() => {
        setComponentLoading(false);
        setOpenConfirmDialog(false);
      });
  };

  return (
    <>
      <Confirm
        visible={openConfirmDialog}
        setVisible={(p) => setOpenConfirmDialog(p)}
        callBack={deleteCategories}
        confirmMessage={confirmDialogMessage}
      />
      <Button
        label='Kategori Sil'
        icon='pi pi-trash'
        className='p-button-danger mx-3'
        onClick={() => setOpenConfirmDialog(true)}
        disabled={!selectedCategories || !selectedCategories.length}
      />
    </>
  );
}

export default DeleteCategory;
