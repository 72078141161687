import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationHelper from "../../helper/NotificationHelper";
import { TransferCategoryProduct } from "../../service/productService";
import { setProductsCategoryId } from "../../storage/product/productSlice";

function ProductCategoryTransfer({selectedProducts,setSelectedProducts, setComponentLoading}) {
    const [selectedTransferCategory,setSelectedTransferCategory] = useState();
    const categories = useSelector(state => state.category.categories);
    const dispatch = useDispatch();
    const transfer = () => {
      setComponentLoading(true);
        const productIds = selectedProducts.map((product) => product.id);
    
        const request = {
            productIds: productIds,
            categoryId: selectedTransferCategory,
        };

        TransferCategoryProduct(request).then((response)=>{
          if(response["is-success"]){
            dispatch(setProductsCategoryId(request));
            NotificationHelper.Success("Ürünler başarılı bir şekilde aktarıldı.");
            setSelectedTransferCategory(null);
            setSelectedProducts(null);
          }else{
            NotificationHelper.Error(response.userMessage || "Bir hata meydana geldi.")
          }
        }).catch((err)=>{
          NotificationHelper.Error("Bir hata meydana geldi.");
        }).finally(()=>setComponentLoading(false));
      };
  return (
    <div className='row'>
      <div className='col-6'>
        <Dropdown
          value={categories.find(x => x.id == selectedTransferCategory)?.categoryLocalizations?.[0]}
          options={categories.map((c) => c.categoryLocalizations?.[0])}
          optionLabel='categoryName'
          filter
          showClear
          filterBy='categoryName'
          onChange={(e) =>
            setSelectedTransferCategory(e.target.value?.categoryId)
          }
          placeholder={
          !selectedTransferCategory &&
              "Bir kategori seçin"
            
          }
          className='w-100'

          style={{ width: "100%" }}
        />
      </div>
      <div className='col-6 p-0'>
        <Button
          label='Kategoriye aktar'
          className='p-button-info'
          onClick={transfer}
          disabled={
            !selectedProducts || !selectedProducts.length
          }
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
}

export default ProductCategoryTransfer;
