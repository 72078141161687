import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DeleteBank, GetBanks } from "../service/inventoryService";
import NotificationHelper from "../helper/NotificationHelper";
import CreateOrUpdateBank from '../components/Bank/CreateOrUpdateBank';
function Bank() {
  const [editModel, setEditModel] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);
  const [banks, setBanks] = useState([]);
  const [createOrUpdateDialog, setCreateOrUpdateDialog] = useState(false);
  const [gridLoading, setGridLoading] = useState(false);
  const [deleteBankDialog, setDeleteBankDialog] = useState(false);
  useEffect(() => {
    getBanks();
  }, []);
  const getBanks = async () => {
    setGridLoading(true);
    GetBanks()
      .then((response) => {
        if (response["is-success"] && response.data) {
          setBanks(response.data);
          setGridLoading(false);
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi"
          );
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir hata meydana geldi");
        setGridLoading(false);
      });
  };
  const editBank = (rowData) => {
    setSelectedBank(rowData);
    setEditModel(rowData);
    setCreateOrUpdateDialog(true);
  };
  const confirmDeleteBank = (rowData) => {
    setSelectedBank(rowData);
    setDeleteBankDialog(true);
  };
  const actionBody = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon='pi pi-pencil'
          className='p-button-rounded p-button-info'
          onClick={() => editBank(rowData)}
        />
        <Button
          icon='pi pi-trash'
          className='p-button-rounded p-button-danger'
          onClick={() => confirmDeleteBank(rowData)}
        />
      </React.Fragment>
    );
  };
  const deleteBanks = async () => {
    setDeleteBankDialog(false);
    setGridLoading(true);

    DeleteBank(selectedBank?.id)
      .then((response) => {
        if (response["is-success"] && response.data) {
          NotificationHelper.Success("Banka başarıyla silindi.");
          getBanks();
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi"
          );
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir hata meydana geldi");
      });
  };
  const deleteFooter = () => {
    return (
      <React.Fragment>
        <Button
          label='No'
          icon='pi pi-times'
          className='p-button-text'
          onClick={() => setDeleteBankDialog(false)}
        />
        <Button
          label='Yes'
          icon='pi pi-check'
          className='p-button-text'
          onClick={deleteBanks}
        />
      </React.Fragment>
    );
  };
  return (
    <>
      {" "}
      <DataTable
        // ref={dt}
        value={banks}
        selection={selectedBank}
        onSelectionChange={(e) => setSelectedBank(e.value)}
        dataKey='id'
        paginator
        rows={10}
        header={
          <button
            className='btn btn-success'
            onClick={() => {
              setEditModel({ name: "", reciveName: "", iban: "" });
              setCreateOrUpdateDialog(true);
            }}>
            Ekle
          </button>
        }
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
        currentPageReportTemplate='Showing {first} to {last} of {totalRecords} categories'
        loading={gridLoading}>
        <Column selectionMode='single' headerStyle={{ width: "3rem" }}></Column>
        {/* <Column field="id" header="Banka No" sortable></Column> */}
        <Column field='name' header='Banka Adı' sortable></Column>
        <Column
          field='reciverName'
          header='Hesap Sahibinin Adı Soyadı'
          sortable></Column>
        <Column field='iban' header='Iban'></Column>
        <Column body={actionBody}></Column>
      </DataTable>
      <CreateOrUpdateBank
        reload={() => getBanks()}
        bank={editModel}
        setBank={(e) => setEditModel(e)}
        createOrUpdateDialog={createOrUpdateDialog}
        setCreateOrUpdateDialog={(e) => setCreateOrUpdateDialog(e)}
      />
      <Dialog
        header='Uyarı'
        visible={deleteBankDialog}
        style={{ width: "500px" }}
        footer={deleteFooter}
        className='p-fluid'
        onHide={() => setDeleteBankDialog(false)}>
        Silmek istediğinize emin misiniz ?
      </Dialog>
    </>
  );
}

export default Bank;
