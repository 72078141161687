import { createSlice } from "@reduxjs/toolkit";

export const categoryLocalizationSlice = createSlice({
  name: "categoryLocalization",
  initialState: {
    categoryLocalizations: [],
  },
  reducers: {
    setCategoryLocalizations: (state, action) => {
      state.categoryLocalization = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCategoryLocalizations } = categoryLocalizationSlice.actions;

export default categoryLocalizationSlice.reducer;
