import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import GemGastroSpinner from "../Core/GemGastroSpinner";
import OrderHelper from "../../helper/OrderHelper";
import Customer from "../Customer";
import { useDispatch, useSelector } from "react-redux";
import { CreateOffer, UpdateOrderStatus } from "../../service/orderService";
import NotificationHelper from "../../helper/NotificationHelper";
import { AdminGetAllOrder } from "../../service/inventoryService";
import { setOrders } from "../../storage/order/orderSlice";
import ProductHelper from "../../helper/ProductHelper";
import OrderDetails from "./OrderDetails";

function UpdateOrder({ visible, setVisible, statusOptions }) {
  const [componentLoading, setComponentLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const editModel = useSelector((state) => state.order.selectedOrder);
  const products = useSelector((state) => state.product.products);
  const dispatch = useDispatch();
  useEffect(() => {
    const status = editModel.status;
    setSelectedStatus(status);
  }, [editModel]);
  const saveOrder = () => {
    let requestModel = {};
    if (selectedStatus == null) {
      NotificationHelper.Error("Lütfen bir durum seçiniz.");
      return false;
    }
    requestModel.orderStatus = selectedStatus;
    requestModel.id = editModel.id;
    setComponentLoading(true);
    UpdateOrderStatus(requestModel)
      .then((response) => {
        if (response["is-success"]) {
          NotificationHelper.Success("Sipariş Güncellendi.");
          AdminGetAllOrder().then((response) => {
            if (response["is-success"]) {
              dispatch(setOrders(response.data));
            }
          });
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata ile karşılaşıldı."
          );
        }
      })
      .catch(() => {
        NotificationHelper.Error("Bir hata ile karşılaşıldı.");
      });
    setComponentLoading(false);
  };
  const createOffer = () => {
    const requestOrder = editModel;
    setComponentLoading(true);
    if (requestOrder?.status != 1) {
      NotificationHelper.Error("Sipariş teklif verilmeye uygun değil.");
      return false;
    }
    let orderDetails = requestOrder?.orderDetails;
    const allOrderDetailOkay =
      orderDetails.filter((x) => x.cargoId == 0 || x.cargoPrice < 1)?.length <
      1;

    if (allOrderDetailOkay) {
      CreateOffer(requestOrder).then((response) => {
        if (response["is-success"]) {
          NotificationHelper.Success("Teklif başarıyla verildi.");
          AdminGetAllOrder().then((response) => {
            if (response["is-success"]) {
              dispatch(setOrders(response.data));
            }
          });
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi"
          );
        }
      });
      setComponentLoading(false);
      return false;
    } else {
      NotificationHelper.Error(
        "Siparişin tüm ürünlerinin gerekli alanları doldurulmamış lütfen tekrar deneyiniz.."
      );
      setComponentLoading(false);
      return false;
    }
  };
  const orderDialogFooter = () => (
    <React.Fragment>
      <div className='p-3'>
        <Button
          label='İptal'
          icon='pi pi-times'
          className='p-button-text'
          onClick={() => setVisible(false)}
        />
        {editModel.status == 1 && (
          <Button
            label='Teklif Ver'
            icon='pi pi-check'
            className='p-button-text'
            onClick={() => createOffer()}
          />
        )}
        {editModel.status != 1 && (
          <Button
            label='Sipariş Durumunu Güncelle'
            icon='pi pi-check'
            className='p-button-text'
            onClick={() => saveOrder()}
          />
        )}
      </div>
    </React.Fragment>
  );
  return (
    <Dialog
      visible={visible}
      style={{ width: "1100px" }}
      header={`Siparişi Düzenle`}
      modal
      className='p-fluid'
      footer={orderDialogFooter}
      onHide={() => setVisible(false)}>
      <>
        {componentLoading ? (
          <GemGastroSpinner isLogin={false} />
        ) : (
          <>
            <OrderDetails orderDetails={editModel.orderDetails} />
            <div className='p-field'>
              <h5
                style={{
                  borderBottom: "1px solid black",
                  fontSize: "16px",
                  padding: "8px",
                }}>
                Sipariş Bilgileri
              </h5>
              <div className='p-grid' style={{ marginLeft: "5px" }}>
                <div className='p-col-4'>
                  <label htmlFor='orderCode'> Sipariş Kodu </label>
                  <InputText
                    id='orderCode'
                    value={editModel.orderCode}
                    required
                    autoFocus
                    disabled='true'
                    placeholder={editModel.orderCode}
                  />
                </div>
                <div className='p-col-4'>
                  <label htmlFor='createdDate'> Sipariş Tarihi </label>
                  <InputText
                    id='createdDate'
                    value={OrderHelper.formatDateTime(editModel)}
                    required
                    autoFocus
                    disabled='true'
                    placeholder={OrderHelper.formatDateTime(editModel)}
                  />
                </div>
                <div className='p-col-4'>
                  <label htmlFor='totalPrice'> Sipariş Toplam Tutarı</label>
                  <InputText
                    id='totalPrice'
                    value={editModel.totalPrice}
                    required
                    autoFocus
                    disabled='true'
                    placeholder={editModel.totalPrice}
                  />
                </div>
                <div className='p-col-4'>
                  <label htmlFor='status'>Sipariş Durumu</label>
                  <Dropdown
                    style={{borderColor: "blue"}}
                    value={selectedStatus}
                    options={statusOptions?.map((i) => {
                      const foundedStatusOption =
                        OrderHelper.allStatusOptions.find(
                          (x) => x.value == i.value
                        );
                      if (foundedStatusOption) {
                        const newKey = foundedStatusOption.text;
                        i.text = newKey;
                      }
                      return i;
                    })}
                    optionLabel='text'
                    optionValue='value'
                    filter
                    showClear
                    filterBy='text'
                    onChange={(e) => setSelectedStatus(e.value)}
                    placeholder={
                      (selectedStatus &&
                        OrderHelper.allStatusOptions.find(
                          (x) => x.value == selectedStatus.toString()
                        ).text) ||
                      "Lütfen bir durum seçiniz."
                    }
                  />
                </div>
              </div>
            </div>
            <Customer customerId={editModel.customerId} />
          </>
        )}
      </>
    </Dialog>
  );
}

export default UpdateOrder;
