import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import ProductHelper from "../../helper/ProductHelper";

function ProductSelectGrid({
  selectedProducts,
  setSelectedProducts,
  products,
  maxRowNumber,
  disable
}) {
  const dt = useRef();
  const suppliers = useSelector((state) => state.supplier.suppliers);
  const categories = useSelector((state) => state.category.categories);
  const [globalFilter, setGlobalFilter] = useState(null);
  const gridHeader = (
    <div className='table-header'>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onChange={(e) => {
            setGlobalFilter(e.target.value);
          }}
          placeholder='Ürün kodu giriniz...'
        />
      </span>
    </div>
  );
  return (
    <DataTable
      ref={dt}
      value={products}
      selection={selectedProducts}
      onSelectionChange={disable ? ()=>{} : (e) => setSelectedProducts(e.value)}
      dataKey='id'
      paginator
      rows={maxRowNumber ? maxRowNumber : 10}
      rowsPerPageOptions={maxRowNumber ? [maxRowNumber] : [5, 10, 20, 25, 50]}
      globalFilter={globalFilter}
      header={gridHeader}
      emptyMessage="Seçili ürün bulunmamakta."
      >
      {
        !disable && <Column selectionMode={"multiple"} headerStyle={{ width: "3rem" }}></Column>
      }
      
      <Column field='productCode' header='Ürün Kodu' sortable></Column>
      <Column
        body={ProductHelper.productNameTemplate}
        header='Ürün Adı'
        sortable></Column>
      <Column
        field='brandName'
        body={(p) => ProductHelper.productBrancdNameTemplate(p, suppliers)}
        header='Marka'
        sortable></Column>
      <Column
        field='unitPriceGe'
        header='Ürün Fiyatı'
        body={ProductHelper.priceBodyTemplate}
        sortable></Column>
      <Column field='unitStock' header='Ürün Stok Adeti' sortable></Column>

      {/* <Column field="rating" header="Reviews" body={ratingBodyTemplate} sortable></Column> */}
      <Column
        field='productImages'
        header='Ürün Resmi'
        body={ProductHelper.imageBodyTemplate}></Column>
      <Column
        field='unitStock'
        header='Durum'
        body={ProductHelper.statusBodyTemplate}
        sortable></Column>
    </DataTable>
  );
}

export default ProductSelectGrid;
