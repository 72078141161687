import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React from "react";
import ProductHelper from "./ProductHelper";

export default class CategoryHelper {
  static findById(categoryId, categories) {
    return categories.find((x) => x.id == categoryId);
  }
  static getCategoryImageUrlById(categoryImage) {
    return process.env.REACT_APP_API_IMG + "/" + categoryImage.url;
  }
  static categoryNameTemplate = (category) => {
    return (
      <span>
        {(category.categoryLocalizations?.length &&
          category.categoryLocalizations[0]?.categoryName) ||
          ""}
      </span>
    );
  };
  static categoryDescriptionNameTemplate = (category) => {
    return <span>
    {(category.categoryLocalizations?.length &&
      category.categoryLocalizations[0]?.description) ||
      ""}
  </span>;
  };
  static imageBodyTemplate = (category) => {
    if (category.categoryImage?.id)
      return (
        <img
          src={CategoryHelper.getCategoryImageUrlById(category.categoryImage)}
          className="category-image d-flex justify-content-center"
          style={{ width: "100px" }}
        />
      );
    return (
      <img
        src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
        className="category-image"
      />
    );
  };
  static actionBodyTemplate = (category, editFunc) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2 mr-4"
          onClick={() => editFunc(category)}
        />
        {/* <Button
					icon="pi pi-trash"
					className="p-button-rounded p-button-warning"
					onClick={() => confirmDeleteProduct(category)}
				/> */}
      </React.Fragment>
    );
  };
}
