import cookie from "js-cookie";
import jwt_decode from "jwt-decode";

export default class CookieHelper {
  static get userCookieName() {
    return "gemgastroUser";
  }

  static get currentUser() {
    const token = cookie.getJSON(this.userCookieName);
    let user = null;

    if (token && token?.trim().length) user = jwt_decode(token);

    return user;
  }

  static getCookie = (name) => {
    return cookie.getJSON(name);
  };
  static setCookie = (name, value, expiredTime) => {
    cookie.set(name, value, { expires: expiredTime });
  };

  static deleteCookie = (name) => {
    cookie.remove(name);
  };
}
