import moment from "moment";
export default class OrderHelper {
  static get PaymentTypes() {
    return [
      { key: "CreditCard", value: 1 },
      { key: "OnlineBank", value: 2 },
      { key: "Albis", value: 3 },
      { key: "Paypal", value: 4 },
      { key: "Paydirekt", value: 5 },
    ];
  }
  static get ProccessTypes() {
    return [
      { key: "Check", value: 1 },
      { key: "PurchasePending", value: 2 },
      { key: "PurchaseResponse", value: 3 },
      { key: "CompletePurchase", value: 4 },
      { key: "CreateApplication", value: 5 },
    ];
  }
  static get ProviderTypes() {
    return [
      { key: "Albis", value: 1 },
      { key: "Payone", value: 2 },
    ];
  }

 
  static get PaymentStatus() {
    return [
      { key: "Processing", value: 1 },
      { key: "Complete", value: 2 },
      { key: "Error", value: 3 },
    ];
  }
  static get allStatusOptions() {
    return [
      { text: "Sipariş Fiyat Talebi", value: "1" },
      { text: "Fiyat Talebi Verildi", value: "2" },
      { text: "Talep Cevap Bekliyor", value: "3" },
      { text: "Talep Kabul Edildi", value: "4" },
      { text: "Talep Reddedildi", value: "5" },
      { text: "Sipariş Onaylanmadı", value: "6" },
      { text: "Ödeme Tamamlandı", value: "7" },
      { text: "Sipariş Alındı", value: "8" },
      { text: "Sipariş Hazırlanıyor", value: "9" },
      { text: "Sipariş İptal İsteği", value: "10" },
      { text: "Sipariş İptal Edildi", value: "11" },
      { text: "Sipariş Kargoda", value: "12" },
      { text: "Sipariş Teslim Edildi", value: "13" },
      { text: "Sipariş İade İsteği", value: "14" },
      { text: "Sipariş İade Edildi", value: "15" },
      { text: "Albis Cevap Bekleniyor", value: "16" },

    ];
  }
  static formatType(type){
    let orderHistoryLogTypes = [
      {value:6,key:"Sipariş Oluşturulması"},
      {value:18,key:"Sipariş Güncellenmesi"},
      {value:19,key:"Ödeme işlemi tamamlanması"},
      {value:24,key:"Email gönderimi başarısız"},
      {value:25,key:"Email gönderimi başarılı"}
    ] 
    return orderHistoryLogTypes.find(x => x.value ==type)?.key
  }
  static findOrderCustomer(customerId, customers) {
    const customer = customers.find((x) => x.id == customerId);
    return customer;
  }
  static filterStatus(values) {
    return this.allStatusOptions.filter((x) => values.includes(+x.value));
  }
  static get orderDetailFieldOptions() {
    return ["productName", "quantity", "totalPrice", "cargoPrice"];
  }
  static get orderDetailLabelOptions() {
    return [
      { field: "productName", header: "Ürün Adı" },
      { field: "quantity", header: "Miktarı" },
      { field: "totalPrice", header: "Ara Toplam" },
      { field: "cargoPrice", header: "Kargo Fiyatı" },
    ];
  }
  static filterDate = (value, filter) => {
    if (
      filter === undefined ||
      filter === null ||
      (typeof filter === "string" && filter.trim() === "")
    ) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    return value === this.formatDate(filter);
  };

  static formatDate = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    return date.getFullYear() + "-" + month + "-" + day;
  };
  static formatDateTime = (date) => {
    return moment(date.createdDate).format("YYYY-MM-DD HH:mm:ss");
  };
}
