import React, { Component } from "react";
import { connect } from "react-redux";
import GemGastroSpinner from "../Core/GemGastroSpinner";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import MainCategoryHelper from "../../helper/MainCategoryHelper";
import classNames from "classnames";
import {
  createMainCategory,
  onChangeSelectedMainCategory,
  setMainCategoryDetail,
  setSelectedMainCategory,
  updateMainCategory,
} from "../../storage/mainCategory/mainCategorySlice";
import Confirm from "../Core/Confirm";
import CreateOrUpdateMainCategoryLocalization from "./CreateOrUpdateMainCategoryLocalization";
import Image from "../Image";
import {
  CreateMainCategory,
  UpdateMainCategory,
} from "../../service/mainCategoryService";
import NotificationHelper from "../../helper/NotificationHelper";
import CategoryList from "../Category/CategoryList";

//props
//editModel=
//setEditMod
//visible={t
//setVisible
//
export class CreateOrUpdateMainCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      componentLoading: false,
      openMainCategoryTechnicalDialog: false,
      openImageDialog: false,
      openDiscountDialog: false,
      openMainCategoryLocalizationDialog: false,
      openMainCategoryCategories: false,
    };
  }
  saveMainCategory = () => {
    const updateMainCategoryOnClient = this.props.updateMainCategory;
    const createMainCategoryOnClient = this.props.createMainCategory;
    const mainCategory = this.props.selectedMainCategory;
    this.setState({ componentLoading: true }, () => {
      //update
      if (
        this.props.selectedMainCategory &&
        this.props.selectedMainCategory?.id
      ) {
        UpdateMainCategory(this.props.selectedMainCategory)
          .then((response) => {
            if (response?.["is-success"] && response.data) {
              updateMainCategoryOnClient(mainCategory);
              NotificationHelper.Success("Güncelleme işlemi başarılı");
            } else {
              NotificationHelper.Error(response?.userMessage);
            }
          })
          .catch((err) => {
            NotificationHelper.Error("Beklenmedik bir hata oluştu.");
          })
          .finally(() => this.setState({ componentLoading: false }));
      } else if (
        this.props.selectedMainCategory &&
        !this.props.selectedMainCategory?.id
      ) {
        CreateMainCategory(this.props.selectedMainCategory)
          .then((response) => {
            if (response?.["is-success"] && response.data) {
              createMainCategoryOnClient(response.data);
              NotificationHelper.Success(
                "Ana Kategori oluşturma işlemi başarılı"
              );
            } else {
              NotificationHelper.Error(response?.userMessage);
            }
          })
          .catch((err) => {
            NotificationHelper.Error("Beklenmedik bir hata oluştu.");
          })
          .finally(() => this.setState({ componentLoading: false }));
      }
    });
  };
  mainCategoryDialogFooter = (
    <React.Fragment>
      <Button
        label='İptal'
        icon='pi pi-times'
        className='p-button-text'
        onClick={() => this.props.setVisible(false)}
      />
      <Button
        label='Kaydet'
        icon='pi pi-check'
        className='p-button-text'
        onClick={this.saveMainCategory}
      />
    </React.Fragment>
  );

  onInputChange = (name, value) => {
    let model = {
      name,
      value,
    };
    this.props.onChangeSelectedMainCategory(model);
  };
  render() {
    const { componentLoading } = this.state;
    const { setVisible, visible } = this.props;
    return (
      <>
        <Dialog
          visible={visible}
          style={{ width: "450px" }}
          header={`${
            this.props.selectedMainCategory?.id
              ? `Ana Kategori Güncelleme`
              : `Ana Kategori Oluşturma`
          }`}
          modal
          className='p-fluid'
          footer={this.mainCategoryDialogFooter}
          onHide={() => setVisible(false)}>
          {!componentLoading ? (
            <>
              {this.props.selectedMainCategory &&
              this.props.selectedMainCategory.mainCategoryImage?.length ? (
                <div className='p-field d-flex justify-content-center'>
                  <img
                    src={MainCategoryHelper.getMainCategoryImageUrlById(
                      this.props.selectedMainCategory.mainCategoryImage || []
                    )}
                    className='mainCategory-image d-flex justify-content-center'
                    style={{ width: "100px" }}
                  />
                </div>
              ) : (
                ""
              )}
              {/* <div className='p-field'>
                <label htmlFor='id'>Ana Kategori Kodu</label>
                <InputText
                  name='id'
                  value={this.props.selectedMainCategory?.id}
                  readOnly
                  disabled
                  required
                  autoFocus
                />
              </div> */}
              <div className='p-field'>
                <Button
                  label={`${
                    this.props.selectedMainCategory?.mainCategoryLocalization &&
                    this.props.selectedMainCategory.mainCategoryLocalization
                      .length
                      ? "Ana Kategori Bilgilerini Güncelleme"
                      : "Ana Kategori Bilgilerini Gir"
                  }`}
                  icon='pi pi-external-link'
                  onClick={() =>
                    this.setState({ openMainCategoryLocalizationDialog: true })
                  }
                />
              </div>
              <div className='p-formgrid'>
                {this.props.selectedMainCategory?.id && (
                  <div className='p-field p-col my-2'>
                    <Button
                      label='Ana Kategori Fotoğrafı Ekle'
                      icon='pi pi-upload'
                      onClick={() => this.setState({ openImageDialog: true })}
                    />
                  </div>
                )}
                {this.props.selectedMainCategory?.id && (
                  <div className='p-field p-col my-2'>
                    <Button
                      label='Ana Kategori Alt Kategorileri'
                      icon='pi pi-upload'
                      onClick={() =>
                        this.setState({ openMainCategoryCategories: true })
                      }
                    />
                  </div>
                )}
              </div>

              {this.state.openMainCategoryLocalizationDialog && (
                <CreateOrUpdateMainCategoryLocalization
                  visible={this.state.openMainCategoryLocalizationDialog}
                  setVisible={(p) =>
                    this.setState({ openMainCategoryLocalizationDialog: p })
                  }
                />
              )}
              {this.state.openImageDialog &&
                this.props.selectedMainCategory?.id && (
                  <Image
                    visible={this.state.openImageDialog}
                    setVisible={(p) => this.setState({ openImageDialog: p })}
                    entityId={this.props.selectedMainCategory.id}
                    fieldName={"MainCategoryId"}
                  />
                )}
              {this.state.openMainCategoryCategories &&
                this.props.selectedMainCategory?.id && (
                  <CategoryList
                    categories={this.props.categories.filter(
                      (x) =>
                        x.mainCategoryId == this.props.selectedMainCategory.id
                    )}
                    visible={this.state.openMainCategoryCategories}
                    setVisible={(p) =>
                      this.setState({ openMainCategoryCategories: p })
                    }
                  />
                )}
            </>
          ) : (
            <GemGastroSpinner />
          )}
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.category.categories,
  mainCategories: state.mainCategory.mainCategories,
  selectedMainCategory: state.mainCategory.selectedMainCategory,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedMainCategory: (mainCategory) =>
      dispatch(setSelectedMainCategory(mainCategory)),
    setMainCategoryDetail: (lookup) => dispatch(setMainCategoryDetail(lookup)),
    onChangeSelectedMainCategory: (changedValue) =>
      dispatch(onChangeSelectedMainCategory(changedValue)),
    updateMainCategory: (mainCategory) =>
      dispatch(updateMainCategory(mainCategory)),
    createMainCategory: (mainCategory) =>
      dispatch(createMainCategory(mainCategory)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOrUpdateMainCategory);
