import { Button } from "primereact/button";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationHelper from "../../helper/NotificationHelper";
import { DeleteMainCategories } from "../../service/mainCategoryService";
import { deleteCategories } from "../../storage/category/categorySlice";
import {deleteMainCategories as deleteMainCategoryOnClient} from "../../storage/mainCategory/mainCategorySlice";
import { deleteProducts } from "../../storage/product/productSlice";
import Confirm from "../Core/Confirm";

function DeleteCategory({
  selectedMainCategories,
  setSelectedMainCategories,
  setComponentLoading,
}) {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [confirmDialogMessage, setConfirmDialogMessage] = useState(
    `Seçili ana kategorileri silmek istediğinize emin misiniz?`
  );
  const products = useSelector(state => state.product.products);
  const categories = useSelector(state => state.category.categories);
  const dispatch = useDispatch();
 
  const deleteMainCategories = () => {
    setComponentLoading(true);
    console.log(selectedMainCategories.length)
    if(selectedMainCategories.length != 1){
      NotificationHelper.Error("Lütfen bir tane ana kategori seçiniz");
      setComponentLoading(false);
      return false;
    }
    const mainCategoryId = selectedMainCategories[0].id;
    DeleteMainCategories(mainCategoryId)
      .then((response) => {
        if (response["is-success"]) {
          const categoryIds = categories.filter(x => x.mainCategoryId == mainCategoryId).map(i => i.id);
          const productIds = products.filter(x => categoryIds.includes(x.categoryId)).map(i => i.id);
          dispatch(deleteMainCategoryOnClient([mainCategoryId]));
          dispatch(deleteCategories(categoryIds));
          dispatch(deleteProducts(productIds))
          NotificationHelper.Success(
            "Silme işlemi başarılı bir şekilde sonuçlandı"
          );
          setSelectedMainCategories(undefined);
          setOpenConfirmDialog(false);
        } else {
          NotificationHelper.Error(
            response.userMessage || "Bir hata meydana geldi."
          );
        }
      })
      .catch((e) => {
        console.log(e);
        NotificationHelper.Error("Bir hata meydana geldi.");
      })
      .finally(() => setComponentLoading(false));
  };

  return (
    <>
      <Confirm
        visible={openConfirmDialog}
        setVisible={(p) => setOpenConfirmDialog(p)}
        callBack={deleteMainCategories}
        confirmMessage={confirmDialogMessage}
      />
      <Button
        label='Ana Kategori Sil'
        icon='pi pi-trash'
        className='p-button-danger mx-3'
        onClick={() => setOpenConfirmDialog(true)}
        disabled={!selectedMainCategories || !selectedMainCategories.length}
      />
    </>
  );
}

export default DeleteCategory;
