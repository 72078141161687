import { InputText } from 'primereact/inputtext';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDiffClientLocalizations } from '../storage/localization/localizationSlice';

function LocalizationSettings() {
    const localizations = useSelector(x => x.localization.localizations);

    return (
        <>{localizations.map((localization) => {
            const val = localization.language;
            const name = localization.id + '_' + localization.language;
            return (
                <div className='p-field'>
                    <label>Dil</label>
                    <InputText
                        name={name}
                        value={val}
                        disabled
                    />
                </div>
            )
        })}
        </>

    )
}

export default LocalizationSettings